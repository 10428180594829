import { FC, useEffect, useState } from 'react';
import css from './settings-pki-ca.module.scss';
import {
  createCertificateFromCSR,
  downloadCaBackup,
  downloadCaCertificate,
  downloadProfileWithCaCertificate,
  getBuiltInCaCertificate
} from '../../../../api/pki';
import { PkiCertificateFullDto } from '../../../../types/api';
import { Button } from '../../../components/button/button.component';
import { downloadFile } from '../../../../utils/file.utils';
import { Modal } from '../../../components/modal/modal.component';
import { TextInput, TextInputType } from '../../../components/text-input/text-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CaBackupForm,
  caBackupFormSchema,
  CsrRequestForm,
  csrRequestFormSchema
} from './settings-pki-ca.schema';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

export const SettingsPkiCa: FC = () => {
  const { t } = useTranslation();
  const [caCertificate, setCaCertificate] = useState<PkiCertificateFullDto>();
  const [isBackupModalOpen, setIsBackupModalOpen] = useState(false);
  const [isCsrModalOpen, setIsCsrModalOpen] = useState(false);
  const caBackupRequest = useRequest<ArrayBuffer>();
  const csrCreateRequest = useRequest<AxiosResponse<string>>();
  const caBackupForm = useForm<CaBackupForm>({
    mode: 'onChange',
    resolver: yupResolver(caBackupFormSchema),
    defaultValues: caBackupFormSchema.getDefault()
  });
  const csrRequestForm = useForm<CsrRequestForm>({
    mode: 'onChange',
    resolver: yupResolver(csrRequestFormSchema),
    defaultValues: csrRequestFormSchema.getDefault()
  });

  const init = async () => {
    const certificate = await getBuiltInCaCertificate();
    setCaCertificate(certificate);
  };

  const handleCreateBackupClick = () => setIsBackupModalOpen(true);
  const handleBackupModalClose = async () => {
    if (caBackupRequest.loading) return;
    caBackupForm.reset({ backup_password: '', backup_password_verify: '' });
    setIsBackupModalOpen(false);
  };
  const handleBackupModalSubmit = async () => {
    if (!caCertificate) return;
    const isValid = await caBackupForm.trigger();
    if (!isValid) return;
    const values = caBackupForm.getValues();
    try {
      const response = await caBackupRequest.send(
        downloadCaBackup(caCertificate.id, { password: values.backup_password })
      );
      downloadFile('CABackup.p12', [response]);
      caBackupForm.reset({ backup_password: '', backup_password_verify: '' });
      setIsBackupModalOpen(false);
    } catch (error) {
      toast.error(String(error));
    }
  };

  const handleCsrCreateClick = () => setIsCsrModalOpen(true);
  const handleCsrModalClose = () => {
    csrRequestForm.reset({ csr: '' });
    setIsCsrModalOpen(false);
  };
  const handleCsrModalSubmit = async () => {
    if (!caCertificate) return;
    const isValid = await csrRequestForm.trigger();
    if (!isValid) return;
    const values = csrRequestForm.getValues();
    try {
      const response = await csrCreateRequest.send(
        createCertificateFromCSR(caCertificate.id, { csr: values.csr })
      );
      const match = response.headers['content-disposition'].match(/filename="([^"]+)"/);
      const filename = match?.[1] || 'cert.pem';
      downloadFile(filename, [response.data]);
      csrRequestForm.reset({ csr: '' });
      setIsCsrModalOpen(false);
    } catch (error) {
      toast.error(String(error));
    }
  };

  const handleClickCertificateDownload = async () => {
    if (!caCertificate) return;
    try {
      const pem = await downloadCaCertificate(caCertificate.id);
      downloadFile(`${caCertificate.subject}.pem`, [pem]);
    } catch (error) {
      toast.error(String(error));
    }
  };

  const handleClickProfileDownload = async () => {
    if (!caCertificate) return;
    try {
      const profile = await downloadProfileWithCaCertificate(caCertificate.id);
      downloadFile(`certificateCA.mobileconfig`, [profile]);
    } catch (error) {
      toast.error(String(error));
    }
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      {caCertificate && (
        <div className={css.BuiltInCaInfo}>
          <span>{t('settings.tiles.pki.page.ca_subject')}: </span>
          <span className={css.BuiltInCaInfoSubject}>{caCertificate.subject}</span>
        </div>
      )}
      <div>
        <Button className={css.ActionButton} onClick={handleClickCertificateDownload}>
          {t('settings.tiles.pki.page.download_certificate')}
        </Button>
        <Button className={css.ActionButton} onClick={handleClickProfileDownload}>
          {t('settings.tiles.pki.page.download_profile_with_certificate')}
        </Button>
        <Button className={css.ActionButton} onClick={handleCsrCreateClick}>
          {t('settings.tiles.pki.page.create_certificate_from_csr')}
        </Button>
        <Button className={css.ActionButton} onClick={handleCreateBackupClick}>
          {t('settings.tiles.pki.page.create_ca_backup')}
        </Button>
      </div>
      <Modal
        title={t('settings.tiles.pki.page.create_certificate_from_csr')}
        submitButtonName={t('settings.tiles.pki.page.create_btn')}
        isOpen={isCsrModalOpen}
        isLoading={csrCreateRequest.loading}
        onRequestClose={handleCsrModalClose}
        onSubmit={handleCsrModalSubmit}
      >
        <TextInput
          label={t('settings.tiles.pki.page.csr_label')}
          textarea
          register={csrRequestForm.register('csr')}
          errorText={csrRequestForm.formState.errors.csr?.message}
        />
      </Modal>
      <Modal
        title={t('settings.tiles.pki.page.create_ca_backup')}
        submitButtonName={t('settings.tiles.pki.page.create_btn')}
        isOpen={isBackupModalOpen}
        isLoading={caBackupRequest.loading}
        onRequestClose={handleBackupModalClose}
        onSubmit={handleBackupModalSubmit}
      >
        <div>
          <TextInput
            type={TextInputType.PASSWORD}
            label={t('settings.tiles.pki.page.password_label')}
            register={caBackupForm.register('backup_password')}
            errorText={caBackupForm.formState.errors.backup_password?.message}
          />
          <TextInput
            type={TextInputType.PASSWORD}
            label={t('settings.tiles.pki.page.password_verify_label')}
            register={caBackupForm.register('backup_password_verify')}
            errorText={caBackupForm.formState.errors.backup_password_verify?.message}
          />
        </div>
      </Modal>
    </div>
  );
};
