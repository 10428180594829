import { FC } from 'react';
import { PolicyTrigger } from '../../../../../types/api';
import css from './policy-options-general.module.scss';
import { TextInput, TextInputType } from '../../../../components/text-input/text-input.component';
import { Checkbox } from '../../../../components/checkbox/checkbox.component';
import { Dropdown } from '../../../../components/dropdown/dropdown.component';
import { UseFormReturn } from 'react-hook-form';
import { frequencyOptions, PolicyOptionsGeneralForm } from './policy-options-general.schema';
import { policyTriggerTitles } from '../../../../../const/policies.const';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface IProps {
  form: UseFormReturn<PolicyOptionsGeneralForm>;
  disabled?: boolean;
}

export const PolicyOptionsGeneral: FC<IProps> = (props) => {
  const { form, disabled = false } = props;
  const { watch, register, formState } = form;
  const errors = formState.errors;
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <h5 className={css.GroupTitle}>{t('policies.options_general.general')}</h5>
      <TextInput
        register={register('display_name')}
        label={t('policies.options_general.display_name')}
        errorText={errors.display_name?.message}
        disabled={disabled}
        required
      />
      <Checkbox
        register={register('enabled')}
        label={t('policies.options_general.enabled')}
        disabled={disabled}
      />
      <h5 className={css.GroupTitle}>{t('policies.options_general.triggers')}</h5>
      <Checkbox
        key={PolicyTrigger.Immediate}
        register={register(`triggers.${PolicyTrigger.Immediate}`)}
        label={policyTriggerTitles[PolicyTrigger.Immediate]}
        disabled={disabled}
      />
      <Checkbox
        key={PolicyTrigger.Interval}
        register={register(`triggers.${PolicyTrigger.Interval}`)}
        label={policyTriggerTitles[PolicyTrigger.Interval]}
        disabled={disabled}
      />
      <TextInput
        className={cn(css.ExtraFormInput, !watch('triggers.Interval') && css.Hidden)}
        register={register('interval_amount')}
        label={t('policies.options_general.interval_amount')}
        errorText={errors.interval_amount?.message}
        type={TextInputType.INTEGER}
        required
        disabled={disabled}
      />
      {/*<Checkbox*/}
      {/*  key={PolicyTrigger.CustomEvent}*/}
      {/*  register={register(`triggers.${PolicyTrigger.CustomEvent}`)}*/}
      {/*  label={policyTriggerTitles[PolicyTrigger.CustomEvent]}*/}
      {/*  disabled={disabled}*/}
      {/*/>*/}
      {/*<TextInput*/}
      {/*  className={cn(css.ExtraFormInput, !watch('triggers.CustomEvent') && css.Hidden)}*/}
      {/*  register={register('custom_event_trigger')}*/}
      {/*  label={t('policies.options_general.custom_event_trigger_name')}*/}
      {/*  required*/}
      {/*  errorText={errors.custom_event_trigger?.message}*/}
      {/*  disabled={disabled}*/}
      {/*/>*/}
      <Dropdown
        register={register('frequency')}
        label={t('policies.options_general.frequency')}
        options={frequencyOptions}
        disabled={disabled}
      />
      {/*<TextInput*/}
      {/*  register={register('custom_event_emit')}*/}
      {/*  label={t('policies.options_general.custom_event_to_emit')}*/}
      {/*  errorText={errors.custom_event_emit?.message}*/}
      {/*  disabled={disabled}*/}
      {/*/>*/}
    </div>
  );
};
