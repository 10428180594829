import { FC, useState } from 'react';
import cn from 'classnames';
import css from './sign-in.module.scss';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useAuthContext } from '../../contexts/auth.context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { Paths } from '../../constants';

interface ISignInFormData {
  username: string;
  password: string;
}

export const SignIn: FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authService } = useAuthContext();
  const schema = object({
    username: string().required(t('sign_in.username_required')),
    password: string().required(t('sign_in.password_required'))
  }).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<ISignInFormData & { serverError: string }>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: ISignInFormData) => {
    try {
      await authService.signIn(data.username, data.password);
      await authService.setLicenseStatus();
      navigate(Paths.HOME);
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      setError('serverError', { message: localizedErrorString });
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div className={css.Root}>
      <form onSubmit={handleSubmit(onSubmit)} className={css.Form}>
        <div className="card-body">
          <div className={cn('form-group', css.FormGroup)}>
            <label htmlFor="username">{t('sign_in.username')}</label>
            <input
              className="form-control"
              id="username"
              placeholder={t('sign_in.username')}
              {...register('username')}
            />
            <p className={css.Error}>{errors.username?.message}</p>
          </div>
          <div className={cn('form-group', css.FormGroup)}>
            <label htmlFor="password">{t('sign_in.password')}</label>
            <div className={css.passwordContainer}>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="password"
                placeholder={t('sign_in.password')}
                {...register('password')}
              />
              <div className={css.passwordEye} onClick={togglePasswordVisibility}>
                {showPassword ? (
                  <FontAwesomeIcon width={22} icon={faEye} />
                ) : (
                  <FontAwesomeIcon width={22} icon={faEyeSlash} />
                )}
              </div>
            </div>
            <p className={css.Error}>{errors.password?.message}</p>
          </div>
          {errors.serverError && <p className={css.Error}>{errors.serverError.message}</p>}
        </div>

        <div className={cn(css.FormFooter, 'card-footer')}>
          <button type="submit" className="btn btn-primary">
            {t('sign_in.sign_in_button')}
          </button>
        </div>
      </form>
    </div>
  );
};
