import { FC, useEffect, useState } from 'react';
import { HistoryTab } from './components/history-tab/history-tab.component';
import { InventoryTab } from './components/inventory-tab/inventory-tab.component';
import { ManagementTab } from './components/management-tab/management-tab.component';
import { DeviceFullDto, Permission } from '../../../../../types/api';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteDevice, getDeviceInfo } from '../../../../../api/device';
import { TerminalTab } from './components/terminal-tab.component';
import { TabItem, TabNavbar } from '../../../../components/tab/tab-navbar/tab-navbar.component';
import css from './inventory-device-page.module.scss';
import { Modal } from '../../../../components/modal/modal.component';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../../utils/localize-error';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Restricted } from '../../../../components/common/restricted';
import { usePermission } from '../../../../contexts/permission.context';
import useDeviceSection, { DeviceType } from '../../../../contexts/device-section.context';

export enum InventoryDevicePageTab {
  INVENTORY = 'Inventory',
  MANAGEMENT = 'Management',
  HISTORY = 'History',
  TERMINAL = 'Terminal'
}

export const InventoryDevicePage: FC = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [openedTab, setOpenedTab] = useState<string>(InventoryDevicePageTab.INVENTORY);
  const [device, setDevice] = useState<DeviceFullDto>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteModalLoading, setIsDeleteModalLoading] = useState(false);
  const [deleteModalError, setDeleteModalError] = useState('');
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const { deviceType } = useDeviceSection();
  const handleChangeTab = (tabName: string) => setOpenedTab(tabName);

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDeleteDevice = async () => {
    if (!device || isDeleteModalLoading) return;
    setIsDeleteModalLoading(true);
    try {
      await deleteDevice(device.id);
      setIsDeleteModalOpen(false);
      if (deviceType === DeviceType.MOBILE) {
        navigate('/mobile/inventory');
      } else {
        navigate('/computers/inventory');
      }
    } catch (error) {
      const errorString = getLocalizedErrorString(error as Error);
      setDeleteModalError(errorString);
    }
    setIsDeleteModalLoading(false);
  };

  useEffect(() => {
    if (deviceId) {
      getDeviceInfo(deviceId).then((req: DeviceFullDto) => {
        setDevice(req);
      });
    }
  }, []);

  const tabs: TabItem[] =
    deviceType === DeviceType.COMPUTERS
      ? [
          {
            key: InventoryDevicePageTab.INVENTORY,
            title: t('inventory.device_page.inventory_tab.name')
          },
          {
            key: InventoryDevicePageTab.MANAGEMENT,
            title: t('inventory.device_page.management_tab.name')
          },
          {
            key: InventoryDevicePageTab.HISTORY,
            title: t('inventory.device_page.history_tab.name')
          },
          {
            key: InventoryDevicePageTab.TERMINAL,
            title: t('inventory.device_page.terminal_tab.name'),
            disabled: !isAllowedTo(Permission.EditCommands)
          }
        ]
      : [
          {
            key: InventoryDevicePageTab.INVENTORY,
            title: t('inventory.device_page.inventory_tab.name')
          },
          {
            key: InventoryDevicePageTab.MANAGEMENT,
            title: t('inventory.device_page.management_tab.name')
          },
          {
            key: InventoryDevicePageTab.HISTORY,
            title: t('inventory.device_page.history_tab.name')
          }
        ];
  return (
    <>
      <Breadcrumbs
        title={
          device?.device_information?.device_name
            ? device?.device_information?.device_name
            : device?.model_name
            ? device?.model_name
            : ''
        }
      />
      <TabNavbar tabs={tabs} activeTab={openedTab} onChangeTab={handleChangeTab}>
        <span className={css.DeviceName}>
          {device && device.device_information
            ? device.device_information.device_name
              ? device.device_information.device_name
              : device.model_name
              ? device.model_name
              : ''
            : ''}
        </span>
        <div className={cn(css.RightMenu, 'nav-item', 'dropdown')}>
          <a
            className={cn(css.NavLink, 'nav-link')}
            href="#"
            id="tabDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </a>
          <div className="dropdown-menu" aria-labelledby="tabDropdown">
            <button
              className={cn(css.LinkDelete, 'dropdown-item')}
              disabled={!isAllowedTo(Permission.EditDevices)}
              onClick={handleOpenDeleteModal}
            >
              {t('inventory.device_page.delete_btn')}
              <FontAwesomeIcon className={css.DeleteIcon} icon={faTrash} />
            </button>
          </div>
        </div>
      </TabNavbar>
      {openedTab === InventoryDevicePageTab.INVENTORY && <InventoryTab device={device} />}
      {openedTab === InventoryDevicePageTab.MANAGEMENT && <ManagementTab device={device} />}
      {openedTab === InventoryDevicePageTab.HISTORY && <HistoryTab device={device} />}
      <Restricted to={[Permission.EditCommands]}>
        {openedTab === InventoryDevicePageTab.TERMINAL && <TerminalTab device={device} />}
      </Restricted>
      <Modal
        title={t('inventory.device_page.delete_modal.title')}
        submitButtonName={t('inventory.device_page.delete_modal.btn')}
        isOpen={isDeleteModalOpen}
        error={deleteModalError}
        onRequestClose={handleCloseDeleteModal}
        onSubmit={handleDeleteDevice}
      >
        <p>
          {t('inventory.device_page.delete_modal.message', {
            model_name: device?.model_name,
            serial_number: device?.serial_number
          })}
        </p>
      </Modal>
    </>
  );
};
