import css from './tab-navbar-item.module.scss';
import { FC } from 'react';
import cn from 'classnames';
import { WithClassname } from '../../../../../types/common';
import { Link } from 'react-router-dom';

interface IProps {
  title: string;
  active: boolean;
  onClick: () => void;
  link?: string;
  disabled?: boolean;
  isLink?: boolean;
}

export const TabNavItem: FC<IProps & WithClassname> = (props) => {
  const { className, title, active, onClick, link, disabled = false, isLink = false } = props;

  const handleOnClick = () => {
    if (disabled) {
      return;
    }
    onClick?.();
  };

  return (
    <li
      className={cn(css.Root, className, active && css.Active, disabled && css.disabled)}
      onClick={handleOnClick}
    >
      {isLink ? (
        <Link className={css.Value} to={link || ''}>
          {title}
        </Link>
      ) : (
        <div className={css.Value}>{title}</div>
      )}
    </li>
  );
};
