import { FC, FormEvent, useState } from 'react';
import css from './user-ldap-form.module.scss';
import {
  Permission,
  SearchLdapUsersEntryDto,
  SearchLdapUsersResponseDto,
  UserOriginType
} from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../../../components/text-input/text-input.component';
import { Dropdown, DropdownOption } from '../../../../../components/dropdown/dropdown.component';
import {
  searchLdapFormSchema,
  SearchLdapFormValues,
  userLdapFormSchema,
  UserLdapFormValues
} from './user-ldap-form.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { IRole } from '../../user.component';
import { Button } from '../../../../../components/button/button.component';
import { searchUsers } from '../../../../../../api/ldap';
import useRequest from '../../../../../../hooks/useRequest';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { userOriginTypeTitles } from '../../../../../../const/users.const';
import { Modal } from '../../../../../components/modal/modal.component';
import { useNavigate } from 'react-router-dom';
import { PaginationRowsPerPageOptionsDeviceUsers } from '../../../../../../const/pagination.const';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: UserLdapFormValues;
  roles?: IRole[];
  onCreate?: (values: UserLdapFormValues) => void;
  onEdit?: () => void;
  onSave?: (values: Partial<UserLdapFormValues>) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

export const UserLdapFormComponent: FC<IProps> = (props) => {
  const { mode, values, roles = [], onCreate, onEdit, onSave, onCancel, onDelete } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ldapUsers, setLdapUsers] = useState<SearchLdapUsersEntryDto[]>();
  const [isLdapUserSelected, setIsLdapUserSelected] = useState(!!values);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const searchRequest = useRequest<SearchLdapUsersResponseDto>();

  const rolesOptions: DropdownOption[] = roles.map((role) => ({
    text: role.title,
    value: role.id
  }));

  const userForm = useForm<UserLdapFormValues>({
    mode: 'onChange',
    resolver: yupResolver(userLdapFormSchema),
    defaultValues: values || userLdapFormSchema.getDefault()
  });

  const searchForm = useForm<SearchLdapFormValues>({
    mode: 'onChange',
    resolver: yupResolver(searchLdapFormSchema),
    defaultValues: searchLdapFormSchema.getDefault()
  });

  const handleSelectLdapUser = (user: SearchLdapUsersEntryDto) => () => {
    userForm.setValue('username', user.username[0]);
    userForm.setValue('display_name', user.full_name[0]);
    setIsLdapUserSelected(true);
  };

  const handleSearchCancel = () => navigate('/settings/users');
  const handleSearchFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (searchRequest.loading) return;
    const searchValues = searchForm.getValues();
    try {
      setLdapUsers(undefined);
      const result = await searchRequest.send(searchUsers({ search: searchValues.search }));
      setLdapUsers(result.entries);
    } catch (error) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  const handleUserFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const isValid = await userForm.trigger();
    if (!isValid) return;

    onCreate?.(userForm.getValues());
  };

  const handleUserFormCancel = async () => {
    setIsLdapUserSelected(false);
    userForm.reset();
  };

  const handleEdit = () => onEdit?.();
  const handleSave = async () => {
    const isValid = await userForm.trigger();
    if (!isValid) return;

    onSave?.(userForm.getValues());
  };

  const handleCancel = async () => {
    await userForm.reset();
    onCancel?.();
  };

  const handleChangePage = (page: number) => setCurrentPage(page);
  const handleChangeRowsPerPage = (rows: number) => setRowsPerPage(rows);

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const columns: TableColumn<SearchLdapUsersEntryDto>[] = [
    {
      name: t('settings.tiles.users.page.user_page.ldap_search_label_username'),
      selector: (entry) => entry.username[0] || '---'
    },
    {
      name: t('settings.tiles.users.page.user_page.ldap_search_label_full_name'),
      selector: (entry) => entry.full_name[0] || '---'
    },
    {
      name: t('settings.tiles.users.page.user_page.ldap_search_label_email'),
      selector: (entry) => entry.email[0] || '---'
    },
    {
      cell: (entry) => (
        <Button onClick={handleSelectLdapUser(entry)} theme="success">
          {t('settings.tiles.users.page.user_page.ldap_search_select_user')}
        </Button>
      )
    }
  ];

  return (
    <div className={css.Root}>
      {!isLdapUserSelected && (
        <form className={css.SearchForm} onSubmit={handleSearchFormSubmit}>
          <h5>{t('settings.tiles.users.page.user_page.ldap_search_title')}</h5>
          <p>{t('settings.tiles.users.page.user_page.ldap_search_description')}</p>
          <div className={css.Controls}>
            <TextInput
              label={t('settings.tiles.users.page.user_page.ldap_search_label')}
              register={searchForm.register('search')}
            />
            <div>
              <Button className={css.SearchBtn} theme="danger" onClick={handleSearchCancel}>
                {t('settings.tiles.users.page.user_page.cancel_btn')}
              </Button>
              <Button className={css.SearchBtn} isLoading={searchRequest.loading} type="submit">
                {t('settings.tiles.users.page.user_page.ldap_search_submit')}
              </Button>
            </div>
          </div>
          {ldapUsers && (
            <DataTable
              className={css.SearchUserTable}
              columns={columns}
              data={ldapUsers}
              pagination
              paginationDefaultPage={currentPage}
              paginationPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              paginationRowsPerPageOptions={PaginationRowsPerPageOptionsDeviceUsers}
              paginationComponentOptions={{
                rowsPerPageText: t('common.table.rows_per_page'),
                rangeSeparatorText: t('common.table.of')
              }}
              noDataComponent={t('common.no_records_in_table')}
            />
          )}
        </form>
      )}
      {isLdapUserSelected && (
        <form onSubmit={handleUserFormSubmit}>
          <h5 className={css.Title}>
            {mode === 'create'
              ? t('settings.tiles.users.page.user_page.new_title')
              : values?.display_name || values?.username || ''}
          </h5>
          <fieldset className={css.Content} disabled={!isAllowedTo(Permission.EditAdministration)}>
            <TextInput
              disabled
              register={userForm.register('username')}
              label={t('settings.tiles.users.page.user_page.username')}
            />
            <TextInput
              label={t('settings.tiles.users.page.user_page.origin_type')}
              value={userOriginTypeTitles[UserOriginType.Ldap]}
              disabled
            />
            <TextInput
              disabled
              register={userForm.register('display_name')}
              label={t('settings.tiles.users.page.user_page.display_name')}
            />
            <Dropdown
              label={t('settings.tiles.users.page.user_page.role')}
              options={rolesOptions}
              register={userForm.register('role_id')}
              disabled={mode === 'view'}
            />
          </fieldset>
          <div className={css.ActionButtons}>
            {mode === 'create' && (
              <>
                <Button theme="danger" type="button" onClick={handleUserFormCancel}>
                  {t('settings.tiles.users.page.user_page.cancel_btn')}
                </Button>
                <Button theme="primary" type="submit">
                  {t('settings.tiles.users.page.user_page.create_btn')}
                </Button>
              </>
            )}
            {mode === 'view' && (
              <>
                <Button
                  theme="primary"
                  onClick={handleEdit}
                  isDisabled={!isAllowedTo(Permission.EditAdministration)}
                >
                  {t('settings.tiles.users.page.user_page.edit_btn')}
                </Button>
                <Button
                  theme="danger"
                  onClick={handleDeleteModalOpen}
                  isDisabled={!isAllowedTo(Permission.EditAdministration)}
                >
                  {t('settings.tiles.users.page.user_page.delete_btn')}
                </Button>
              </>
            )}
            {mode === 'edit' && (
              <>
                <Button theme="primary" onClick={handleSave}>
                  {t('settings.tiles.users.page.user_page.save_btn')}
                </Button>
                <Button theme="danger" onClick={handleCancel}>
                  {t('settings.tiles.users.page.user_page.cancel_btn')}
                </Button>
              </>
            )}
          </div>
        </form>
      )}
      <Modal
        title={t('settings.tiles.users.page.user_page.modal_delete.title')}
        submitButtonName={t('settings.tiles.users.page.user_page.modal_delete.delete_btn')}
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
      >
        <p>
          {t('settings.tiles.users.page.user_page.modal_delete.message', {
            title: values?.display_name || values?.username
          })}
        </p>
      </Modal>
    </div>
  );
};
