import { FC } from 'react';
import css from './security-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const SecurityTableMobile: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <table className={css.Root}>
      <tbody>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.device_locator_service'
            )}
          </td>
          <td>
            {isBoolean(di?.is_device_locator_service_enabled)
              ? di?.is_device_locator_service_enabled
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.enabled')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.disabled')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.data_protection'
            )}
          </td>
          <td>
            {isBoolean(di?.gatekeeper_enabled)
              ? di?.gatekeeper_enabled
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.enabled')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.disabled')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.hardware_encryption'
            )}
          </td>
          <td>
            {' '}
            {di?.hardware_encryption_caps
              ? di?.hardware_encryption_caps === 1 ||
                di?.hardware_encryption_caps === 2 ||
                di?.hardware_encryption_caps === 3
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_status'
            )}
          </td>
          <td>
            {isBoolean(di?.autologin_enabled)
              ? di?.autologin_enabled
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.enabled')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.disabled')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.block_encryption_capability'
            )}
          </td>
          <td>
            {di?.hardware_encryption_caps
              ? di?.hardware_encryption_caps === 1 || di?.hardware_encryption_caps === 3
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.file_encryption_capability'
            )}
          </td>
          <td>
            {di?.hardware_encryption_caps
              ? di?.hardware_encryption_caps === 2 || di?.hardware_encryption_caps === 3
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_compliance'
            )}
          </td>
          <td>
            {' '}
            {isBoolean(di?.passcode_compliant)
              ? di?.passcode_compliant
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
              : '---'}
          </td>
        </tr>

        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_compliance_with_config_profile'
            )}
          </td>
          <td>
            {isBoolean(di?.passcode_compliant_with_profiles)
              ? di?.passcode_compliant_with_profiles
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_lock_grace_period_enforced'
            )}
          </td>
          <td>
            {di?.passcode_lock_grace_period_enforced !== null &&
            di?.passcode_lock_grace_period_enforced !== undefined
              ? di?.passcode_lock_grace_period_enforced
              : '---'}
          </td>
        </tr>

        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.activation_lock_enabled'
            )}
          </td>
          <td>
            {isBoolean(di?.firmware_password_enabled)
              ? di?.firmware_password_enabled
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
              : '---'}
          </td>
        </tr>

        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.jailbreak_detected'
            )}
          </td>
          <td>{di?.firmware_password ? di?.firmware_password : '---'}</td>
        </tr>

        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.is_mdm_lost_mode_enabled'
            )}
          </td>
          <td>
            {isBoolean(di?.is_mdm_lost_mode_enabled)
              ? di?.is_mdm_lost_mode_enabled
                ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
              : '---'}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
