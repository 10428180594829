import { FC, useEffect, useState } from 'react';
import css from './static-group-devices.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { StaticGroupForm } from './components/static-group-form/static-group-form.component';
import {
  getStaticGroup,
  createStaticGroup,
  deleteStaticGroup,
  updateStaticGroup
} from '../../../api/static-groups-devices';
import { StaticGroupGeneralForm } from './components/static-group-form/static-group-form.schema';
import {
  CreateStaticGroupDevicesRequestDto,
  DeviceOsType,
  StaticGroupDevicesFullDto,
  UpdateStaticGroupDevicesRequestDto
} from '../../../types/api';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { toast } from 'react-toastify';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export enum StaticGroupFormModes {
  NEW = 'new',
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view'
}

export const StaticGroupDevices: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { deviceType } = useDeviceSection();

  const [isLoading, setIsLoading] = useState(false);
  const [staticGroup, setStaticGroup] = useState<StaticGroupDevicesFullDto>();
  const [isStaticGroupFormShown, setIsStaticGroupFormShown] = useState(false);
  const [staticGroupFormMode, setStaticGroupFormMode] = useState<StaticGroupFormModes>(
    StaticGroupFormModes.NEW
  );

  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const handleStaticGroupCreate = async (values: StaticGroupGeneralForm, devices: string[]) => {
    if (isLoading || !deviceType) return;
    setIsLoading(true);
    try {
      const request: CreateStaticGroupDevicesRequestDto = {
        title: values.display_name,
        devices: devices,
        os_type: osType
      };
      const response = await createStaticGroup(request);
      setStaticGroup(response);
      setStaticGroupFormMode(StaticGroupFormModes.VIEW);
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleSetUpdateMode = (state: boolean) => {
    setStaticGroupFormMode(state ? StaticGroupFormModes.UPDATE : StaticGroupFormModes.VIEW);
  };

  const handleStaticGroupUpdate = async (values: StaticGroupGeneralForm, devices: string[]) => {
    if (isLoading || !staticGroup) return;
    setIsLoading(true);
    try {
      const request: UpdateStaticGroupDevicesRequestDto = {
        title: values.display_name,
        devices: devices
      };
      const response = await updateStaticGroup(request, staticGroup.id);
      setStaticGroup(response);
      handleSetUpdateMode(false);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleStaticGroupDelete = async () => {
    if (isLoading || !staticGroup) return;
    setIsLoading(true);
    try {
      await deleteStaticGroup(staticGroup.id);
      navigate('./..');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleSmartGroupCancel = () => {
    setStaticGroupFormMode(StaticGroupFormModes.VIEW);
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const groupResponse = await getStaticGroup(id);
      setStaticGroup(groupResponse);
      setStaticGroupFormMode(StaticGroupFormModes.VIEW);
    }

    setIsStaticGroupFormShown(true);
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs title={staticGroup?.title} />
      {isStaticGroupFormShown && (
        <StaticGroupForm
          mode={staticGroupFormMode}
          onCreate={handleStaticGroupCreate}
          setUpdateMode={handleSetUpdateMode}
          onUpdate={handleStaticGroupUpdate}
          group={staticGroup}
          onDelete={handleStaticGroupDelete}
          onCancel={handleSmartGroupCancel}
        />
      )}
    </div>
  );
};
