import { ChangeEvent, FC, useEffect, useState } from 'react';
import cn from 'classnames';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './device-application-list.module.scss';
import { Button } from '../../components/button/button.component';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../contexts/permission.context';
import {
  DeviceApplicationDto,
  DeviceApplicationSortField,
  PaginationSortOrder,
  Permission
} from '../../../types/api';
import { TextInput, TextInputType } from '../../components/text-input/text-input.component';
import { getDeviceApplicationList } from '../../../api/device-applications';
import { useDebounce } from 'use-debounce';

export const DeviceApplicationList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [deviceApps, setDeviceApps] = useState<DeviceApplicationDto[]>([]);
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [sortField, setSortField] = useState<DeviceApplicationSortField | undefined>(undefined);
  const [filterText, setFilterText] = useState<string>('');
  const [debouncedFilterText] = useDebounce(filterText, 1000);

  const updateData = async () => {
    const response = await getDeviceApplicationList({
      ...(filterText && { search: filterText }),
      sort_order: sortOrder,
      sort_field: sortField
    });
    setDeviceApps(response.apps);
  };

  const handleRowClicked = (row: DeviceApplicationDto) => {
    navigate(row.id);
  };

  const handleSort = async (
    selectedColumn: TableColumn<DeviceApplicationDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as DeviceApplicationSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  const handleClearFilter = () => {
    if (filterText) setFilterText('');
  };

  const handleUpdateFilter = async (e: ChangeEvent) => {
    return e.target ? setFilterText((e.target as HTMLInputElement).value) : null;
  };

  const deviceAppsColumns: TableColumn<DeviceApplicationDto>[] = [
    {
      name: t('device_apps.app_list.column_name'),
      selector: (row: DeviceApplicationDto) => row.display_name,
      sortable: true,
      sortField: DeviceApplicationSortField.DisplayName,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_apps.app_list.column_version'),
      selector: (row: DeviceApplicationDto) => row.version,
      sortable: true,
      sortField: DeviceApplicationSortField.Version,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_apps.app_list.column_source'),
      selector: (row: DeviceApplicationDto) => row.source,
      sortable: true,
      sortField: DeviceApplicationSortField.Source,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_apps.app_list.column_scoped_devices_count'),
      selector: (row: DeviceApplicationDto) => row.scoped_devices_count,
      maxWidth: '200px',
      style: { cursor: 'pointer' }
    }
  ];

  useEffect(() => {
    void updateData().then(() => {});
  }, [debouncedFilterText]);

  useEffect(() => {
    void updateData();
  }, [sortField, sortOrder]);

  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      <Breadcrumbs />
      <>
        <div className={css.Actions}>
          <Link to="new">
            <Button
              isDisabled={!isAllowedTo(Permission.EditDeviceApplications)}
              className={css.ActionBtn}
              theme="primary"
            >
              {t('device_apps.app_list.new_btn')}
            </Button>
          </Link>
          <div className={css.Filter}>
            <TextInput
              className={css.FilterInput}
              type={TextInputType.TEXT}
              value={filterText}
              onChange={handleUpdateFilter}
              placeholder={t('device_apps.app_list.filter_placeholder')}
            />
            <Button className={css.FilterClearButton} onClick={handleClearFilter}>
              {t('device_apps.app_list.filter_clear_btn')}
            </Button>
          </div>
        </div>
      </>
      {deviceApps && (
        <DataTable
          onRowClicked={handleRowClicked}
          noDataComponent={t('common.no_records_in_table')}
          columns={deviceAppsColumns}
          data={deviceApps}
          sortServer
          onSort={handleSort}
        />
      )}
    </div>
  );
};
