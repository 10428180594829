import { FC, useEffect, useState } from 'react';
import css from './criteria-tab.module.scss';
import { TextInput, TextInputType } from '../../../../components/text-input/text-input.component';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import {
  ConditionConjunctive,
  DeviceCriteriaDto,
  DeviceCriteriaType,
  DeviceOsType,
  SmartGroupDevicesConditionStrippedDto
} from '../../../../../types/api';
import { Dropdown, DropdownOption } from '../../../../components/dropdown/dropdown.component';
import { Button } from '../../../../components/button/button.component';
import { SmartGroupFormValues } from '../smart-group-devices-form/smart-group-devices-form.schema';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCriteria, getCriterionValues } from '../../../../../api/device';
import useDeviceSection, { DeviceType } from '../../../../contexts/device-section.context';
import { deviceCriteriaTitles } from '../../../../../const/criteria-titles.const';

interface IProps {
  form: UseFormReturn<SmartGroupFormValues>;
  disabled?: boolean;
}

export const CriteriaTab: FC<IProps> = (props: IProps) => {
  const { form, disabled = false } = props;
  const { control, register, setFocus, setValue, watch } = form;
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const [addCriteriaShown, setAddCriteriaShown] = useState(false);
  const [chooseCriteriaValueShown, setChooseCriteriaValueShown] = useState(false);
  const [chosenCriterionIndex, setChosenCriterionIndex] = useState<number | undefined>(undefined);
  const [criteria, setCriteria] = useState<DeviceCriteriaDto[]>();
  const [criteriaValues, setCriteriaValues] = useState<string[]>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria'
  });
  const andOrOptions: DropdownOption[] = [
    { text: t('smart_groups.page.criteria_tab.new_criteria.and_or.and'), value: 'and' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.and_or.or'), value: 'or' }
  ];
  const startBracketOptions: DropdownOption[] = [
    { text: '', value: 'false' },
    { text: '(', value: 'true' }
  ];
  const endBracketOptions: DropdownOption[] = [
    { text: '', value: 'false' },
    { text: ')', value: 'true' }
  ];
  const operatorOptions: DropdownOption[] = [
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.like'), value: 'like' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.not_like'), value: 'not like' },
    { text: '>', value: '>' },
    { text: '>=', value: '>=' },
    { text: '<', value: '<' },
    { text: '<=', value: '<=' }
  ];
  const stringOperatorOptions: DropdownOption[] = [
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.like'), value: 'like' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.not_like'), value: 'not like' }
  ];
  const numberDateOperatorOptions: DropdownOption[] = [
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { text: '>', value: '>' },
    { text: '>=', value: '>=' },
    { text: '<', value: '<' },
    { text: '<=', value: '<=' }
  ];

  const trueFalseOptions: DropdownOption[] = [
    { text: 'true', value: 'true' },
    { text: 'false', value: 'false' }
  ];

  const handleSelectAddedCriteria = () => {
    setAddCriteriaShown(true);
  };
  const handleAddCriteria = (criterion: string) => () => {
    const newCriteria: SmartGroupDevicesConditionStrippedDto = {
      conjunctive: fields?.length ? ConditionConjunctive.And : null,
      criteria: criterion,
      operator: '=',
      value: '',
      start_bracket: false,
      end_bracket: false
    };
    append(newCriteria);
    setAddCriteriaShown(false);
  };

  const handleDeleteCriteria = (index: number) => () => {
    if (index === 0 && fields?.length > 1) {
      setValue('criteria.1.conjunctive', null);
      fields[1].conjunctive = null;
    }
    remove(index);
  };

  const handleChooseCriteriaValue =
    (criterion: SmartGroupDevicesConditionStrippedDto, index: number) => async () => {
      const values = await getCriterionValues({
        criterion: criterion.criteria,
        os_type: deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS
      });
      setCriteriaValues(Object.values(values)[0]);
      setChosenCriterionIndex(index);
      setChooseCriteriaValueShown(true);
    };

  const handleAddCriteriaValue = (value: string) => () => {
    if (chosenCriterionIndex == null) return;
    setValue(`criteria.${chosenCriterionIndex}.value`, String(value));
    setChooseCriteriaValueShown(false);
  };

  const handleCancelAddingCriteriaValue = () => {
    setChooseCriteriaValueShown(false);
  };

  const columns: TableColumn<SmartGroupDevicesConditionStrippedDto>[] = [
    {
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.and_or'),
      cell: (row: SmartGroupDevicesConditionStrippedDto, index) => {
        if (!index) return <></>;
        return (
          <Dropdown
            className={css.CellDropdown}
            options={andOrOptions}
            register={register(`criteria.${index}.conjunctive`)}
            disabled={disabled}
          />
        );
      }
    },
    {
      name: '',
      cell: (row, index) => (
        <Dropdown
          className={css.CellDropdown}
          options={startBracketOptions}
          register={register(`criteria.${index}.start_bracket`)}
          disabled={disabled}
        />
      )
    },
    {
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.criteria'),
      selector: (row: SmartGroupDevicesConditionStrippedDto) =>
        deviceCriteriaTitles[row.criteria] || row.criteria
    },
    {
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.operator'),
      cell: (row, index) => {
        const rowCriteriaType = criteria
          ? criteria.filter((a) => a.name === row.criteria)[0].type
          : DeviceCriteriaType.String;
        return rowCriteriaType === DeviceCriteriaType.Boolean ? (
          <TextInput
            className={css.CellTextInput}
            value="="
            disabled
            register={register(`criteria.${index}.operator`)}
          />
        ) : (
          <Dropdown
            className={css.CellDropdown}
            options={
              rowCriteriaType === DeviceCriteriaType.String
                ? stringOperatorOptions
                : rowCriteriaType === DeviceCriteriaType.Number ||
                  rowCriteriaType === DeviceCriteriaType.Date
                ? numberDateOperatorOptions
                : operatorOptions
            }
            value={watch(`criteria.${index}.operator`)}
            register={register(`criteria.${index}.operator`)}
            disabled={disabled}
          />
        );
      }
    },
    {
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.value'),
      cell: (row: SmartGroupDevicesConditionStrippedDto, index) => {
        const rowCriteriaType = criteria
          ? criteria.filter((a) => a.name === row.criteria)[0].type
          : DeviceCriteriaType.String;

        switch (rowCriteriaType) {
          default:
          case DeviceCriteriaType.String:
            return (
              <TextInput
                className={css.CellTextInput}
                register={register(`criteria.${index}.value`)}
                disabled={disabled}
                type={TextInputType.TEXT}
                required
              />
            );
          case DeviceCriteriaType.Boolean:
            return (
              <Dropdown
                className={css.CellDropdown}
                options={trueFalseOptions}
                register={register(`criteria.${index}.value`)}
                disabled={disabled}
              />
            );
          case DeviceCriteriaType.Number:
            return (
              <TextInput
                className={css.CellTextInput}
                register={register(`criteria.${index}.value`)}
                disabled={disabled}
                type={TextInputType.NUMBER_STRING}
                required
              />
            );
          case DeviceCriteriaType.Date:
            return (
              <TextInput
                className={css.CellTextInput}
                register={register(`criteria.${index}.value`)}
                disabled={disabled}
                type={TextInputType.DATE_TIME}
                required
              />
            );
        }
      }
    },
    {
      name: '',
      cell: (row: SmartGroupDevicesConditionStrippedDto, index) => {
        const rowCriteriaType = criteria
          ? criteria.filter((a) => a.name === row.criteria)[0].type
          : DeviceCriteriaType.String;

        return rowCriteriaType === DeviceCriteriaType.Boolean ||
          row.criteria === 'DaysSinceLastSeen' ? (
          <></>
        ) : (
          <Button
            className={css.ChooseCriteriaValueButton}
            isDisabled={disabled}
            onClick={handleChooseCriteriaValue(row, index)}
          >
            ...
          </Button>
        );
      }
    },
    {
      name: '',
      cell: (row, index) => (
        <Dropdown
          className={css.CellDropdown}
          options={endBracketOptions}
          register={register(`criteria.${index}.end_bracket`)}
          disabled={disabled}
        />
      )
    },
    {
      cell: (row, index) =>
        !disabled && (
          <Button
            className={css.AddCriteriaCellButton}
            type="button"
            theme="danger"
            onClick={handleDeleteCriteria(index)}
          >
            {t('smart_groups.page.criteria_tab.new_criteria.delete_btn')}
          </Button>
        ),
      style: { justifyContent: 'end' }
    }
  ];

  const criteriaColumns: TableColumn<string>[] = [
    {
      name: t('smart_groups.page.criteria_tab.table.header.new_criteria'),
      selector: (row) => deviceCriteriaTitles[row] || row
    },
    {
      cell: (row) => (
        <Button type="button" theme="success" onClick={handleAddCriteria(row)}>
          {t('smart_groups.page.criteria_tab.choose_btn')}
        </Button>
      ),
      style: { justifyContent: 'end' }
    }
  ];

  const criteriaValuesColumn: TableColumn<string>[] = [
    {
      name: t('smart_groups.page.criteria_tab.table.header.choose_value'),
      selector: (value) => value
    },
    {
      cell: (value) => (
        <Button type="button" theme="success" onClick={handleAddCriteriaValue(value)}>
          {t('smart_groups.page.criteria_tab.choose_btn')}
        </Button>
      ),
      style: { justifyContent: 'end' }
    }
  ];

  const fetchCriteria = async () => {
    const result = await getCriteria(osType, { forSearch: false });
    setCriteria(result.criteria);
  };

  useEffect(() => {
    void fetchCriteria();
  }, []);

  useEffect(() => {
    setFocus(`criteria.${fields.length - 1}.value`);
  }, [fields, setFocus]);

  return (
    <div className={css.Root}>
      {addCriteriaShown && criteria ? (
        <DataTable
          columns={criteriaColumns}
          data={criteria.map((crit) => crit.name)}
          noDataComponent={t('common.no_records_in_table')}
        />
      ) : (
        <>
          {chooseCriteriaValueShown && criteriaValues ? (
            <div>
              <Button onClick={handleCancelAddingCriteriaValue} theme="danger">
                {t('smart_groups.page.criteria_tab.back_btn')}
              </Button>
              <DataTable columns={criteriaValuesColumn} data={criteriaValues} />
            </div>
          ) : (
            <>
              {!disabled && (
                <Button theme="primary" onClick={handleSelectAddedCriteria}>
                  {t('smart_groups.page.criteria_tab.add_btn')}
                </Button>
              )}
              {fields && (
                <DataTable
                  columns={columns}
                  data={fields}
                  noDataComponent={t('common.no_records_in_table')}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
