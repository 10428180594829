import { FC, useEffect, useState } from 'react';
import css from './smart-group-devices.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartGroupDevicesForm } from './components/smart-group-devices-form/smart-group-devices-form.component';
import {
  createSmartGroup,
  deleteSmartGroup,
  getSmartGroup,
  updateSmartGroup
} from '../../../api/smart-group-devices';
import { toast } from 'react-toastify';
import { SmartGroupFormValues } from './components/smart-group-devices-form/smart-group-devices-form.schema';
import {
  CreateSmartGroupDevicesRequestDto,
  DeviceOsType,
  SmartGroupDevicesFullDto,
  UpdateSmartGroupDevicesRequestDto
} from '../../../types/api';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export const SmartGroupDevices: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const [isLoading, setIsLoading] = useState(false);
  const [smartGroup, setSmartGroup] = useState<SmartGroupDevicesFullDto>();
  const [smartGroupFormValues, setSmartGroupFormValues] = useState<SmartGroupFormValues>();
  const [isSmartGroupFormShown, setIsSmartGroupFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'update' | 'edit'>('view');

  const smartGroupToForm = (input: SmartGroupDevicesFullDto): SmartGroupFormValues => {
    return {
      group: {
        id: input.id,
        display_name: input.title
      },
      criteria: input.conditions,
      reports: input.devices
    };
  };

  const handleSmartGroupCreate = async (values: SmartGroupFormValues) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const request: CreateSmartGroupDevicesRequestDto = {
        title: values.group.display_name,
        os_type: osType,
        conditions: values.criteria?.map((criteria) => ({
          criteria: criteria.criteria,
          operator: criteria.operator,
          value: criteria.value,
          conjunctive: criteria.conjunctive,
          start_bracket: JSON.parse(criteria.start_bracket.toString()),
          end_bracket: JSON.parse(criteria.end_bracket.toString())
        }))
      };
      const response = await createSmartGroup(request);
      setSmartGroup(response);
      setSmartGroupFormValues(smartGroupToForm(response));
      setFormMode('view');
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleSmartGroupDelete = async () => {
    if (isLoading || !smartGroup) return;
    setIsLoading(true);
    try {
      await deleteSmartGroup(smartGroup.id);
      navigate('./../');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleSmartGroupEdit = () => {
    setFormMode('edit');
  };

  const handleSmartGroupCancel = () => {
    setFormMode('view');
  };

  const handleSmartGroupSave = async (values: SmartGroupFormValues) => {
    if (isLoading || !smartGroup) return;
    setIsLoading(true);
    try {
      const request: UpdateSmartGroupDevicesRequestDto = {
        title: values.group.display_name,
        conditions: values.criteria?.map((criteria) => ({
          criteria: criteria.criteria,
          operator: criteria.operator,
          value: criteria.value,
          conjunctive: criteria.conjunctive,
          start_bracket: JSON.parse(criteria.start_bracket.toString()),
          end_bracket: JSON.parse(criteria.end_bracket.toString())
        }))
      };
      const response = await updateSmartGroup(smartGroup.id, request);
      setSmartGroup(response);
      setSmartGroupFormValues(smartGroupToForm(response));
      setFormMode('view');
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const response = await getSmartGroup(id);
      setSmartGroup(response);
      setSmartGroupFormValues(smartGroupToForm(response));
    }
    setIsSmartGroupFormShown(true);
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs title={smartGroup?.title} />
      {isSmartGroupFormShown && (
        <SmartGroupDevicesForm
          mode={id === 'new' ? 'create' : formMode}
          values={smartGroupFormValues}
          onCreate={handleSmartGroupCreate}
          onDelete={handleSmartGroupDelete}
          onEdit={handleSmartGroupEdit}
          onSave={handleSmartGroupSave}
          onCancel={handleSmartGroupCancel}
        />
      )}
    </div>
  );
};
