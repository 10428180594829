import { FC, useEffect, useState } from 'react';
import css from './footer.module.scss';
import { useTranslation } from 'react-i18next';
import { getVersion } from '../../../api/version';

export const Footer: FC = () => {
  const { t } = useTranslation();
  const [appVersion, setAppVersion] = useState<string>('');

  const init = async () => {
    const { version } = await getVersion();
    setAppVersion(version);
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div>
      <footer className={`main-footer ${css.Root}`}>
        <strong>
          {t('common.copyright')} {new Date(Date.now()).getFullYear()}
        </strong>
        <strong>
          {t('common.app_version')} {appVersion}
        </strong>
      </footer>
    </div>
  );
};
