import { FC, useEffect, useState } from 'react';
import css from './settings-pki-certificate.module.scss';
import { Link, useParams } from 'react-router-dom';
import { DeviceOsType, PkiCertificateFullDto } from '../../../../types/api';
import { useTranslation } from 'react-i18next';
import { getPkiCertificate } from '../../../../api/pki';
import { formatDate } from '../../../../utils/time.utils';

interface IProps {
  onTitleChange?: (title: string) => unknown;
}

export const SettingsPkiCertificate: FC<IProps> = (props) => {
  const { onTitleChange } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const [certificate, setCertificate] = useState<PkiCertificateFullDto>();

  useEffect(() => {
    if (!id) return;
    const fetch = async () => {
      const result = await getPkiCertificate(id);
      setCertificate(result);
      onTitleChange?.(result.subject);
    };
    void fetch();
  }, []);

  const linkToDevice = (deviceId: string, osType: DeviceOsType): string => {
    const section = osType === DeviceOsType.MacOS ? 'computers' : 'mobile';
    return `/${section}/inventory/${deviceId}`;
  };
  return (
    <div>
      {certificate && (
        <div className={css.CertificateInfo}>
          <div>
            <p className={css.CertificateFieldTitle}>{t('settings.tiles.pki.page.subject')}</p>
            <p>{certificate.subject}</p>
          </div>
          <div>
            <p className={css.CertificateFieldTitle}>
              {t('settings.tiles.pki.page.serial_number')}
            </p>
            <p>{certificate.serial_number}</p>
          </div>
          <div>
            <p className={css.CertificateFieldTitle}>{t('settings.tiles.pki.page.issue_date')}</p>
            <p>{formatDate(certificate.issue_date)}</p>
          </div>
          <div>
            <p className={css.CertificateFieldTitle}>
              {t('settings.tiles.pki.page.expiration_date')}
            </p>
            <p>{formatDate(certificate.expiration_date)}</p>
          </div>
          <div>
            <p className={css.CertificateFieldTitle}>
              {t('settings.tiles.pki.page.linked_devices')}
            </p>
            <div className={css.CertificateDeviceLinks}>
              {certificate.devices.length ? (
                certificate.devices.map((device) => (
                  <Link
                    key={device.id}
                    to={linkToDevice(device.id, device.os_type)}
                    target="_blank"
                  >
                    {device.device_name || device.serial_number}
                  </Link>
                ))
              ) : (
                <p className={css.CertificateFieldValueEmpty}>
                  {t('settings.tiles.pki.page.linked_devices_empty')}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
