import { FC, useEffect, useState } from 'react';
import css from './settings-pki-certificate-list.module.scss';
import { useNavigate } from 'react-router-dom';
import { GetPkiCertificatesRequestDto, PkiCertificateSimpleDto } from '../../../../types/api';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { useTranslation } from 'react-i18next';
import { getPkiCertificates } from '../../../../api/pki';
import { PaginationRowsPerPageOptions } from '../../../../const/pagination.const';

export const SettingsPkiCertificateList: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [certificates, setCertificates] = useState<PkiCertificateSimpleDto[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [certificatesCount, setCertificatesCount] = useState(0);

  const fetchCertificates = async (params?: Partial<GetPkiCertificatesRequestDto>) => {
    const result = await getPkiCertificates({
      page: currentPage,
      limit: rowsPerPage,
      ...params
    });
    setCertificates(result.certificates);
    setCertificatesCount(result.count);
  };

  useEffect(() => {
    void fetchCertificates();
  }, []);

  const handleRowClicked = (row: PkiCertificateSimpleDto) => {
    navigate(row.id);
  };

  const handleSort = () => {};
  const handleChangePage = async (page: number) => {
    await fetchCertificates({ page });
    setCurrentPage(page);
  };
  const handleChangeRowsPerPage = async (limit: number) => {
    const newPage = 1;
    await fetchCertificates({ limit, page: newPage });
    setRowsPerPage(limit);
    setCurrentPage(newPage);
  };

  const columns: TableColumn<PkiCertificateSimpleDto>[] = [
    {
      name: t('settings.tiles.pki.page.subject'),
      selector: (row) => row.subject,
      style: { cursor: 'pointer' }
    }
  ];
  return (
    <div>
      <div className={css.SettingsHeader}>
        <h5 className={css.SettingsTitle}>{t('settings.tiles.pki.page.pki_certificates')}</h5>
      </div>

      {certificates && (
        <DataTable
          columns={columns}
          data={certificates}
          noDataComponent={t('common.no_records_in_table')}
          onRowClicked={handleRowClicked}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={certificatesCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
    </div>
  );
};
