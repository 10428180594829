import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import cn from 'classnames';
import css from './text-input.module.scss';
import { WithClassname, WithInputProps } from '../../../types/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export enum TextInputType {
  TEXT = 'text',
  INTEGER = 'integer',
  DATE = 'date',
  DATE_TIME = 'datetime-local',
  PASSWORD = 'password',
  NUMBER_STRING = 'number-string',
  TEL = 'tel'
}

interface IProps {
  label?: string;
  errorText?: string;
  type?: TextInputType;
  autoComplete?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  textarea?: boolean;
  insideModal?: boolean;
  pattern?: string;
  showPasswordBtn?: boolean;
}

export const TextInput: FC<IProps & WithInputProps & WithClassname> = (props) => {
  const {
    className,
    register,
    onChange,
    value,
    label,
    errorText,
    type = TextInputType.TEXT,
    placeholder = '',
    autoComplete = 'off',
    disabled = false,
    required = false,
    textarea = false,
    insideModal = false,
    pattern,
    showPasswordBtn = false
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const parseInput = (input: string, textType: TextInputType): string => {
    if (textType === TextInputType.INTEGER) {
      const firstSymbol = input.substring(0, 1);
      if (firstSymbol === '0' && input.length > 1) return '0';
      const replaced = input.replace(/(\D)/g, '');
      const matched = replaced.match(/^(0|\d+)/)?.[0];
      return matched || replaced;
    }
    if (textType === TextInputType.NUMBER_STRING) {
      return input.replace(/(\D)/g, '');
    }
    return input;
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (
    event
  ) => {
    event.target.value = parseInput(event.target.value, type);
    const patched = register?.onChange || onChange;
    if (patched) {
      await patched(event);
    }
  };

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (!showPasswordBtn) setShowPassword(false);
  }, [showPasswordBtn]);

  const inputType = () => {
    if (type === 'integer') return 'text';
    if (type === 'password' && showPassword) return 'text';
    return type;
  };

  const inputTypeValue = inputType();
  return (
    <label className={cn(className, 'form-group', css.Root, insideModal ? css.InsideModal : '')}>
      {label && (
        <p className={cn(css.Label)}>
          {label} {required && !disabled && <span className={cn(css.Required)}>*</span>}
        </p>
      )}
      {textarea ? (
        <textarea
          {...register}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          className={cn(css.Input, 'form-control')}
          autoComplete={autoComplete}
        />
      ) : (
        <input
          {...register}
          type={inputTypeValue}
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(css.Input, 'form-control')}
          autoComplete={autoComplete}
          pattern={pattern}
        />
      )}
      {showPasswordBtn && type === 'password' && (
        <div className={css.PasswordEye} onClick={handleToggleShowPassword}>
          {showPassword ? (
            <FontAwesomeIcon width={22} icon={faEye} />
          ) : (
            <FontAwesomeIcon width={22} icon={faEyeSlash} />
          )}
        </div>
      )}
      <div className={cn(css.Error, insideModal ? css.InsideModal : '')}>
        {errorText && errorText}
      </div>
    </label>
  );
};
