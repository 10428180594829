import { FC } from 'react';
import css from './user-accounts-table.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const UserAccountsTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  return (
    <table className={css.Root}>
      <thead>
        <tr>
          <th>
            {t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.uid')}
          </th>
          <th>
            {t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.gid')}
          </th>
          <th>
            {t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.name')}
          </th>
          <th>
            {t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.dir')}
          </th>
          <th>
            {t(
              'inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.is_admin'
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {device?.user_accounts?.map((i) => (
          <tr key={i.id}>
            <td>{i.uid}</td>
            <td>{i.gid}</td>
            <td>{i.name}</td>
            <td>{i.dir}</td>
            <td>
              {isBoolean(i.is_admin)
                ? i.is_admin
                  ? t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.yes')
                  : t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.no')
                : t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.unknown')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
