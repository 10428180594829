import { FC } from 'react';
import css from './policy-options-maintenance.module.scss';
import { Checkbox } from '../../../../components/checkbox/checkbox.component';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PolicyOptionsMaintenanceForm } from './policy-options-maintenance.schema';

interface IProps {
  form: UseFormReturn<PolicyOptionsMaintenanceForm>;
  disabled?: boolean;
}

export const PolicyOptionsMaintenance: FC<IProps> = (props) => {
  const { form, disabled = false } = props;
  const { register } = form;
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <h5 className={css.GroupTitle}>{t('policies.options_maintenance.title_maintenance')}</h5>
      <Checkbox
        register={register('update_inventory')}
        label={t('policies.options_maintenance.update_inventory')}
        disabled={disabled}
      />
    </div>
  );
};
