import { object, string } from 'yup';
import i18next from '../../../../../../../../../../../i18n/i18n';

export interface LockDeviceGeneralForm {
  message: string;
  pin: string;
  phone_number?: string;
}

export const lockDeviceFormSchema = object({
  message: string().required(
    i18next.t(
      'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.errors.message_required'
    )
  ),
  pin: string()
    .min(
      6,
      i18next.t(
        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.errors.wrong_pin'
      )
    )
    .max(
      6,
      i18next.t(
        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.errors.wrong_pin'
      )
    )
    .required(
      i18next.t(
        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.errors.pin_required'
      )
    ),
  phone_number: string()
}).required();

export interface LockMobileDeviceGeneralForm {
  message?: string;
  phone_number?: string;
}

export const lockMobileDeviceFormSchema = object({
  message: string(),
  phone_number: string()
}).required();

export interface WipeDeviceGeneralForm {
  pin: string;
}

export const wipeDeviceFormSchema = object({
  pin: string()
    .min(
      6,
      i18next.t(
        'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe.errors.wrong_pin'
      )
    )
    .max(
      6,
      i18next.t(
        'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe.errors.wrong_pin'
      )
    )
    .required(
      i18next.t(
        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.errors.pin_required'
      )
    )
}).required();

export interface SetFirmwarePasswordGeneralForm {
  current_password?: string;
  new_password?: string;
}

export const setFirmwarePasswordFormSchema = object({
  current_password: string(),
  new_password: string()
}).required();

export interface SetRecoveryLockGeneralForm {
  current_password?: string;
  new_password?: string;
}

export const setRecoveryLockFormSchema = object({
  current_password: string(),
  new_password: string()
}).required();

export interface EnableLostModeGeneralForm {
  footnote?: string;
  message?: string;
  phone_number?: string;
}

export const enableLostModeFormSchema = object({
  footnote: string(),
  message: string(),
  phone_number: string()
});

export type PendingCommand =
  | 'LockDevice'
  | 'RenewMDMProfile'
  | 'RemoveMDMProfile'
  | 'EraseDevice'
  | 'EnableBluetooth'
  | 'DisableBluetooth'
  | 'BlankPush'
  | 'DownloadUpdates'
  | 'EnableRemoteDesktop'
  | 'DisableRemoteDesktop'
  | 'InstallUpdates'
  | 'SetFirmwarePassword'
  | 'SetRecoveryLock'
  | 'UpdateInventory'
  | 'ClearPasscode'
  | 'EnableLostMode'
  | 'DisableLostMode';

export const pendingCommandTitles: Record<PendingCommand, string> = {
  LockDevice: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.title'
  ),
  RemoveMDMProfile: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.remove_mdm_profile.title'
  ),
  RenewMDMProfile: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.renew_mdm_profile.title'
  ),
  EraseDevice: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe.title'
  ),
  EnableBluetooth: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.enable_bluetooth.title'
  ),
  DisableBluetooth: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.disable_bluetooth.title'
  ),
  BlankPush: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.send_blank_push.title'
  ),
  DownloadUpdates: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.download_updates.title'
  ),
  InstallUpdates: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.install_updates.title'
  ),
  EnableRemoteDesktop: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.enable_ard.title'
  ),
  DisableRemoteDesktop: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.disable_ard.title'
  ),
  SetFirmwarePassword: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_firmware_password.title'
  ),
  SetRecoveryLock: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.title'
  ),
  UpdateInventory: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.update_inventory.title'
  ),
  ClearPasscode: i18next.t(
    'inventory.device_page.management_tab.commands_tab.commands_section.modals.clear_passcode.title'
  ),
  EnableLostMode: i18next.t(
    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.title'
  ),
  DisableLostMode: i18next.t(
    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.disable_lost_mode.title'
  )
};
