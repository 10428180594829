import { FC, FormEvent, useEffect } from 'react';
import css from './settings-scep.module.scss';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { Button } from '../../../components/button/button.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { getSettings, updateSettings } from '../../../../api/settings';
import { toast } from 'react-toastify';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Checkbox } from '../../../components/checkbox/checkbox.component';
import { usePermission } from '../../../contexts/permission.context';
import { ScepForm, scepFormSchema } from './settings-scep.schema';
import { TextInput } from '../../../components/text-input/text-input.component';

export const SettingsScep: FC = () => {
  const initRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const updateRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, setValue, trigger, getValues, formState } = useForm<ScepForm>({
    mode: 'onChange',
    resolver: yupResolver(scepFormSchema),
    defaultValues: scepFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (initRequest.loading || updateRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        use_server_as_scep_proxy: Boolean(values.use_server_as_scep_proxy),
        scep_certificate_url: String(values.scep_certificate_url)
      };
      await updateRequest.send(updateSettings(requestBody));
      toast.success(t('settings.tiles.scep_config.page.settings_successfully_saved'), {
        autoClose: 5000
      });
    } catch (error: unknown) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  useEffect(() => {
    const init = async () => {
      const settings = await initRequest.send(
        getSettings(['use_server_as_scep_proxy', 'scep_certificate_url'])
      );
      if (settings.use_server_as_scep_proxy) {
        setValue('use_server_as_scep_proxy', settings.use_server_as_scep_proxy);
      }
      if (settings.scep_certificate_url) {
        setValue('scep_certificate_url', settings.scep_certificate_url);
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs />
      <form className={css.Form} onSubmit={handleFormSubmit}>
        <h5 className={css.SettingsTitle}>{t('settings.tiles.scep_config.name')}</h5>
        <Checkbox
          className={css.Input}
          label={t('settings.tiles.scep_config.page.use_server_as_scep_proxy')}
          register={register('use_server_as_scep_proxy')}
          disabled={
            initRequest.loading ||
            !isAllowedTo(Permission.EditPolicies) ||
            !isAllowedTo(Permission.EditAdministration)
          }
        />
        <TextInput
          register={register('scep_certificate_url')}
          label={t('settings.tiles.scep_config.page.scep_certificate_url')}
          errorText={formState.errors.scep_certificate_url?.message}
        />
        <div className={css.ActionBtnGroup}>
          <Button
            className={css.ActionBtn}
            type="submit"
            theme="primary"
            isLoading={updateRequest.loading}
            isDisabled={
              !isAllowedTo(Permission.EditPolicies) || !isAllowedTo(Permission.EditAdministration)
            }
          >
            {t('settings.tiles.scep_config.page.update_btn')}
          </Button>
        </div>
      </form>
    </div>
  );
};
