import { mixed, object } from 'yup';
import i18next from '../../../../../i18n/i18n';

export interface ConfigurationProfileUploadForm {
  config_profile: FileList;
}

export const configurationProfileUploadSchema = object({
  config_profile: mixed()
    .test(
      'required',
      i18next.t('profiles.profile_form.upload_modal.errors.file_needed'),
      (value) => {
        return value && value.length;
      }
    )
    .test(
      'fileSize',
      i18next.t('profiles.profile_form.upload_modal.errors.file_too_large'),
      (value) => {
        return value && value[0] && value[0].size <= 1000000;
      }
    )
    .test(
      'type',
      i18next.t('profiles.profile_form.upload_modal.errors.must_be_a_profile'),
      function (value) {
        const fileName = value && value[0] && value[0].name;
        const fileExtension = fileName.slice(
          (Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1
        );
        return fileExtension === 'mobileconfig';
      }
    )
});
