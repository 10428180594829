import { FC } from 'react';
import css from './applications-table.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../../../contexts/device-section.context';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const ApplicationsTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();

  if (!isOpened) {
    return <div />;
  }

  return deviceType === DeviceType.COMPUTERS ? (
    <div className={css.Root}>
      <table className={css.ApplicationsTable}>
        <thead>
          <tr>
            <th>{t('inventory.device_page.inventory_tab.applications_tab.table.heading.name')}</th>
            <th>
              {t('inventory.device_page.inventory_tab.applications_tab.table.heading.version')}
            </th>
            <th>{t('inventory.device_page.inventory_tab.applications_tab.table.heading.path')}</th>
          </tr>
        </thead>
        <tbody>
          {device?.applications?.map((i) => (
            <tr key={i.id}>
              <td>{i.name}</td>
              <td className={css.CellVersion}>{i.version}</td>
              <td>{i.path}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <DeviceExtensionAttributes
        device={device}
        inventorySection={ExtensionAttributeInventorySection.Applications}
      ></DeviceExtensionAttributes>
    </div>
  ) : (
    <div className={css.Root}>
      <table className={css.ApplicationsTable}>
        <thead>
          <tr>
            <th>{t('inventory.device_page.inventory_tab.applications_tab.table.heading.name')}</th>
            <th>
              {t('inventory.device_page.inventory_tab.applications_tab.table.heading.version')}
            </th>
          </tr>
        </thead>
        <tbody>
          {device?.applications?.map((i) => (
            <tr key={i.id}>
              <td>{i.name}</td>
              <td className={css.CellVersion}>{i.version}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
