import { boolean, mixed, number, object, string } from 'yup';
import { PolicyFrequency, PolicyTrigger } from '../../../../../types/api';
import { DropdownOption } from '../../../../components/dropdown/dropdown.component';
import { policyFrequencyTitles } from '../../../../../const/policies.const';
import i18next from '../../../../../i18n/i18n';
import { notEmpty } from '../../../../../utils/yup.validations';

export const frequencyOptions: DropdownOption[] = Object.values(PolicyFrequency).map((i) => ({
  text: policyFrequencyTitles[i],
  value: i
}));

export const triggerOptions = [
  PolicyTrigger.Immediate,
  PolicyTrigger.Interval,
  PolicyTrigger.CustomEvent
];

export interface PolicyOptionsGeneralForm {
  display_name: string;
  enabled: boolean;
  triggers: Record<PolicyTrigger, boolean>;
  frequency: PolicyFrequency;
  // custom_event_trigger?: string;
  // custom_event_emit?: string;
  interval_amount?: number;
}

export const generalFormSchema = object({
  display_name: string()
    .required(i18next.t('policies.options_general.form.display_name_required'))
    .test(notEmpty(i18next.t('policies.options_general.form.display_name_required'))),
  enabled: boolean().default(true),
  triggers: object(),
  frequency: mixed<PolicyFrequency>()
    .required(i18next.t('policies.options_general.form.frequency_required'))
    .oneOf(Object.values(PolicyFrequency)),
  // custom_event_trigger: string().when(`triggers.${PolicyTrigger.CustomEvent}`, {
  //   is: (value?: boolean) => !!value,
  //   then: (schema) =>
  //     schema
  //       .required(i18next.t('policies.options_general.form.name_is_required'))
  //       .notOneOf(
  //         [ref('custom_event_emit')],
  //         i18next.t('policies.options_general.form.event_trigger_emit_same')
  //       )
  // }),
  // custom_event_emit: string().when(
  //   [`triggers.${PolicyTrigger.CustomEvent}`, 'custom_event_trigger'],
  //   {
  //     is: (value?: string, trigger?: string) => !!value && !!trigger,
  //     then: (schema) =>
  //       schema.notOneOf(
  //         [ref('custom_event_trigger')],
  //         i18next.t('policies.options_general.form.event_trigger_emit_same')
  //       )
  //   }
  // ),
  interval_amount: number()
    .transform((value) => (value ? Number(value) : 0))
    .default(30)
    .when(`triggers.${PolicyTrigger.Interval}`, {
      is: (value?: boolean) => !!value,
      then: (schema) =>
        schema
          .required()
          .min(5, i18next.t('policies.options_general.form.interval_should_be_more'))
          .max(43_200, i18next.t('policies.options_general.form.interval_should_be_less'))
    })
}).required();
