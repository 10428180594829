import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import {
  PackageSimpleDto,
  PolicyPayloadPackageModifyType,
  PolicyPayloadPackageDistributionType
} from '../../../../../types/api';
import css from './policy-options-packages.module.scss';
import cn from 'classnames';
import { WithClassname } from '../../../../../types/common';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { Button } from '../../../../components/button/button.component';
import { getPackages } from '../../../../../api/packages';
import { Dropdown, DropdownOption } from '../../../../components/dropdown/dropdown.component';
import { useTranslation } from 'react-i18next';

export type PolicyOptionsPackagesForm = {
  distribution_type: PolicyPayloadPackageDistributionType;
  items: PackageValue[];
};

interface PackageValue {
  id: string;
  display_name: string;
  modify_type: PolicyPayloadPackageModifyType;
}

interface IProps {
  values?: PolicyOptionsPackagesForm;
  disabled?: boolean;
  onChange?: (form: PolicyOptionsPackagesForm) => void;
}

export const PolicyOptionsPackages: FC<IProps & WithClassname> = (props) => {
  const { className, values, onChange, disabled = false } = props;
  const [fetchedPackages, setFetchedPackages] = useState<PackageSimpleDto[]>();
  const [areFetchedPackagesShown, setAreFetchedPackagesShown] = useState(!values?.items);
  const { t } = useTranslation();

  const fetchPackages = async () => {
    const response = await getPackages();
    setFetchedPackages(response.packages);
  };

  const handleSelectAddedPackage = (pkg: PackageSimpleDto) => () => {
    const updatedAddedPackages: PackageValue[] = [
      ...(values?.items || []),
      {
        id: pkg.id,
        display_name: pkg.display_name,
        modify_type: PolicyPayloadPackageModifyType.Install
      }
    ];
    setAreFetchedPackagesShown(false);
    onChange?.({
      distribution_type: values?.distribution_type || PolicyPayloadPackageDistributionType.Default,
      items: updatedAddedPackages
    });
  };

  const handleAddPackage = () => {
    setAreFetchedPackagesShown(true);
  };

  const handleDeletePackage = (index: number) => () => {
    const updatedAddedPackages = values?.items.filter((_, i) => i !== index);
    onChange?.({
      distribution_type: values?.distribution_type || PolicyPayloadPackageDistributionType.Default,
      items: updatedAddedPackages || []
    });
  };

  const handleChangePackageAction = (index: number): ChangeEventHandler<HTMLSelectElement> => {
    return (event) => {
      const updatedPackages = values?.items.map((pkg, i) => {
        if (i !== index) return pkg;
        return { ...pkg, modify_type: event.target.value as PolicyPayloadPackageModifyType };
      });
      onChange?.({
        distribution_type:
          values?.distribution_type || PolicyPayloadPackageDistributionType.Default,
        items: updatedPackages || []
      });
    };
  };

  useEffect(() => {
    if (disabled) return;
    void fetchPackages();
  }, []);

  const columns: TableColumn<PackageSimpleDto>[] = [
    {
      name: t('policies.options_packages.column_package_name'),
      cell: (pkg) => <span>{pkg.display_name}</span>,
      style: { maxWidth: '400px' }
    },
    {
      cell: (pkg) => (
        <Button
          className={css.AddScriptCellButton}
          type="button"
          theme="success"
          onClick={handleSelectAddedPackage(pkg)}
        >
          {t('policies.options_packages.add_script_button')}
        </Button>
      ),
      style: { justifyContent: 'end', maxWidth: '140px' }
    }
  ];
  const packageActionDropdownOptions: DropdownOption[] = [
    {
      value: PolicyPayloadPackageModifyType.Install,
      text: t('policies.options_packages.package_action_dropdown_install')
    },
    {
      value: PolicyPayloadPackageModifyType.Cache,
      text: t('policies.options_packages.package_action_dropdown_cache')
    },
    {
      value: PolicyPayloadPackageModifyType.InstallCached,
      text: t('policies.options_packages.package_action_dropdown_install_cached')
    }
  ];
  const distributionTypeDropdownOptions: DropdownOption[] = [
    {
      value: PolicyPayloadPackageDistributionType.Default,
      text: t('policies.options_packages.distribution_type_each_computer_default')
    }
  ];
  return (
    <div className={cn(css.Root, className)}>
      {areFetchedPackagesShown && fetchedPackages && (
        <DataTable
          columns={columns}
          data={fetchedPackages}
          noDataComponent={t('common.no_records_in_table')}
        />
      )}
      {!areFetchedPackagesShown && (
        <div className={css.AddedPackageList}>
          <div className={css.AddedPackageListTop}>
            <h5 className={css.AddedPackageListTitle}>
              {t('policies.options_packages.added_packages')}
            </h5>
            {!disabled && (
              <Button theme="primary" className={css.AddScriptButton} onClick={handleAddPackage}>
                {t('policies.options_packages.add_package_button')}
              </Button>
            )}
          </div>
          <Dropdown
            options={distributionTypeDropdownOptions}
            value={PolicyPayloadPackageDistributionType.Default}
          />

          {values?.items.map((pkg, index) => (
            <div className={cn(css.AddedPackage, 'card')} key={pkg.id + index}>
              <div className={cn(css.AddedPackageHeader)}>
                <span className={css.AddedPackageTitle}>{pkg.display_name}</span>
                <Dropdown
                  options={packageActionDropdownOptions}
                  value={pkg.modify_type}
                  onChange={handleChangePackageAction(index)}
                />
                {!disabled && (
                  <Button theme="danger" onClick={handleDeletePackage(index)}>
                    {t('policies.options_packages.delete_package_button')}
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
