import { mixed, object, string } from 'yup';
import { fileExtension, fileNotEmpty } from '../../../../utils/yup.validations';
import i18next from '../../../../i18n/i18n';

export interface UploadAPNSCertificateForm {
  certificate: FileList;
  private_key: FileList;
  password: string;
}

export const uploadAPNSCertificateFormSchema = object({
  certificate: mixed()
    .required()
    .test(
      fileNotEmpty(i18next.t('settings.tiles.upload_certificate.page.errors.certificate_required'))
    )
    .test(fileExtension(['pem'])),
  private_key: mixed()
    .required()
    .test(
      fileNotEmpty(i18next.t('settings.tiles.upload_certificate.page.errors.private_key_required'))
    )
    .test(fileExtension(['key'])),
  password: string()
}).required();
