import { FC } from 'react';
import css from './group-tab.module.scss';
import { TextInput } from '../../../../components/text-input/text-input.component';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SmartGroupDeviceUsersFormValues } from '../smart-group-devices-form/smart-group-device-users-form.schema';

interface IProps {
  // onDelete?: () => void;
  form: UseFormReturn<SmartGroupDeviceUsersFormValues>;
  disabled?: boolean;
}

export const GroupTab: FC<IProps> = (props: IProps) => {
  const { form, disabled = false } = props;
  const { register, formState } = form;
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <TextInput
        className={css.Input}
        label={t('smart_groups.page.group_information_tab.display_name')}
        register={register('group.display_name')}
        errorText={formState.errors.group?.display_name?.message}
        disabled={disabled}
        required
      />
    </div>
  );
};
