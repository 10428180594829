import { FC } from 'react';
import css from './device-application-type.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export const DeviceApplicationType: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <div className={css.LabelHighlight}>
        {t('device_apps.installation.type_selection_step.title')}
      </div>
      <div className={cn(css.Input, 'form-check')}>
        <input
          checked={true}
          className={cn(css.Checkbox, 'form-check-input')}
          disabled
          type="checkbox"
        />
        <span className={css.Label}>
          {t('device_apps.installation.type_selection_step.app_store_app')}
        </span>
      </div>
    </div>
  );
};
