import { ChangeEvent, FC, useEffect, useState } from 'react';
import { ScriptBasicDto } from '../../../../../types/api';
import css from './policy-options-scripts.module.scss';
import { getScripts } from '../../../../../api/scripts';
import cn from 'classnames';
import { WithClassname } from '../../../../../types/common';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { Button } from '../../../../components/button/button.component';
import { TextInput } from '../../../../components/text-input/text-input.component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../../../constants';
import { SettingsPaths } from '../../../settings/settings.const';

export type PolicyOptionsScriptsForm = ScriptValue[];

interface ScriptValue {
  id: string;
  display_name: string;
  arguments: string[];
}

interface IProps {
  values?: PolicyOptionsScriptsForm;
  disabled?: boolean;
  onChange?: (scripts: PolicyOptionsScriptsForm) => void;
}

export const PolicyOptionsScripts: FC<IProps & WithClassname> = (props) => {
  const { className, values, onChange, disabled = false } = props;
  const [fetchedScripts, setFetchedScripts] = useState<ScriptBasicDto[]>();
  const [addedScripts, setAddedScripts] = useState<ScriptValue[]>(values || []);
  const [areFetchedScriptsShown, setAreFetchedScriptsShown] = useState(!values);

  const { t } = useTranslation();

  const fetchScripts = async () => {
    const response = await getScripts();
    setFetchedScripts(response.scripts);
  };

  const handleScriptAdd = (script: ScriptBasicDto) => () => {
    const updatedAddedScripts: ScriptValue[] = [
      ...addedScripts,
      {
        id: script.id,
        display_name: script.display_name,
        arguments: Array(7).fill('')
      }
    ];
    setAddedScripts(updatedAddedScripts);
    setAreFetchedScriptsShown(false);
    onChange?.(updatedAddedScripts);
  };

  const handleSelectAddedScript = () => {
    setAreFetchedScriptsShown(true);
  };

  const handleDeleteScript = (index: number) => () => {
    const updatedAddedScripts = addedScripts.filter((_, i) => i !== index);
    setAddedScripts(updatedAddedScripts);
    onChange?.(updatedAddedScripts);
  };

  const handleParameterChange =
    (scriptIndex: number, paramIndex: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const updatedAddedScripts = addedScripts.map((item, index) => {
        if (index !== scriptIndex) return item;
        const newArguments = [...item.arguments];
        newArguments[paramIndex] = event.target.value;
        return { ...item, arguments: newArguments };
      });
      setAddedScripts(updatedAddedScripts);
      onChange?.(updatedAddedScripts);
    };

  useEffect(() => {
    if (disabled) return;
    void fetchScripts();
  }, []);

  const columns: TableColumn<ScriptBasicDto>[] = [
    {
      name: t('policies.policy_options_scripts.column_script_name'),
      cell: (script) => (
        <Link
          to={`${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', script.id)}`}
          target="_blank"
        >
          {script.display_name}
        </Link>
      ),
      style: { maxWidth: '400px' }
    },
    {
      cell: (script) => (
        <Button
          className={css.AddScriptCellButton}
          type="button"
          theme="success"
          onClick={handleScriptAdd(script)}
        >
          {t('policies.policy_options_scripts.button_add')}
        </Button>
      ),
      style: { justifyContent: 'end', maxWidth: '140px' }
    }
  ];
  return (
    <div className={cn(css.Root, className)}>
      {areFetchedScriptsShown && fetchedScripts && (
        <DataTable
          columns={columns}
          data={fetchedScripts}
          noDataComponent={t('common.no_records_in_table')}
        />
      )}
      {!areFetchedScriptsShown && (
        <div className={css.AddedScriptList}>
          <div className={css.AddedScriptListTop}>
            <h5 className={css.AddedScriptListTitle}>
              {t('policies.policy_options_scripts.added_scripts')}
            </h5>
            {!disabled && (
              <Button
                theme="primary"
                className={css.AddScriptButton}
                onClick={handleSelectAddedScript}
              >
                {t('policies.policy_options_scripts.button_add_script')}
              </Button>
            )}
          </div>

          {addedScripts.map((script, index) => (
            <div className={cn(css.AddedScript, 'card')} key={script.id + index}>
              <div className={cn(css.AddedScriptHeader)}>
                <Link
                  to={`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', script.id)}`}
                  target="_blank"
                  className={css.AddedScriptTitle}
                >
                  {script.display_name}
                </Link>
                {!disabled && (
                  <Button theme="danger" onClick={handleDeleteScript(index)}>
                    {t('policies.policy_options_scripts.button_delete')}
                  </Button>
                )}
              </div>
              <div className={css.AddedScriptParametersTitle}>
                {t('policies.policy_options_scripts.title_parameters')}
              </div>
              <div className={css.AddedScriptParametersDisclaimer}>
                {t('policies.policy_options_scripts.parameters_disclaimer')}
              </div>
              <div>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((num) => (
                  <TextInput
                    key={num}
                    onChange={handleParameterChange(index, num)}
                    value={script.arguments[num] || ''}
                    label={t('policies.policy_options_scripts.label_parameter_n', { n: num + 4 })}
                    disabled={disabled}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
