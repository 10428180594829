import React, { FC, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthContext } from '../../contexts/auth.context';
import { useTranslation } from 'react-i18next';
import { LicenseStatus } from '../../../types/api';
import css from './toast.module.scss';

const Toast: FC = () => {
  const { authState } = useAuthContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (authState.license.iOS && authState.license.iOS === LicenseStatus.Expired) {
      toast.error(
        <div>
          {t('settings.tiles.license.page.ios_license_expire_popup')}{' '}
          <a className={css.Email} href="mailto:support@ringomdm.ru?subject=LicenseExpired">
            support@ringomdm.ru
          </a>
        </div>,
        { autoClose: false }
      );
    }
  }, [authState.license.iOS]);

  useEffect(() => {
    if (authState.license.macOS && authState.license.macOS === LicenseStatus.Expired) {
      toast.error(
        <div>
          {t('settings.tiles.license.page.macos_license_expire_popup')}{' '}
          <a className={css.Email} href="mailto:support@ringomdm.ru?subject=LicenseExpired">
            support@ringomdm.ru
          </a>
        </div>,
        { autoClose: false }
      );
    }
  }, [authState.license.macOS]);

  return (
    <ToastContainer
      autoClose={5000}
      newestOnTop
      closeOnClick={false}
      draggable={false}
      theme="colored"
    />
  );
};

export default React.memo(Toast);
