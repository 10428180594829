import css from './configuration-profile.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createConfigProfile,
  deleteConfigProfile,
  downloadConfigProfile,
  getConfigProfile,
  importConfigProfile,
  updateConfigProfile
} from '../../../api/config-profiles';
import {
  ConfigProfileFullDto,
  CreateConfigProfileDto,
  DeviceType as ApiDeviceType,
  ImportConfigProfileDto,
  ScopeOptionType,
  UpdateConfigProfileDto,
  ConfigProfileSimpleDto
} from '../../../types/api';
import { ConfigurationProfileForm } from './components/configuration-profile-form.component';
import { ConfigurationProfileUploadModal } from '../configuration-profile-list/components/configuration-profile-upload-modal/configuration-profile-upload-modal.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  ConfigurationProfileUploadForm,
  configurationProfileUploadSchema
} from '../configuration-profile-list/components/configuration-profile-upload-modal/configuration-profile-upload.schema';
import {
  ConfigurationProfileFormValues,
  ConfigurationProfileUploadFormValues
} from './components/configuration-profile-form.schema';
import { formScopeEntries } from './configuration-profile.utils';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { toast } from 'react-toastify';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export const ConfigurationProfile: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const configProfileRef = useRef<ConfigProfileFullDto>();
  const deviceSection = useDeviceSection();
  const deviceType =
    deviceSection.deviceType === DeviceType.COMPUTERS
      ? ApiDeviceType.Computer
      : ApiDeviceType.Mobile;

  const [modalIsOpen, setIsOpen] = useState(id === 'import');
  const [isConfigurationProfileFormShown, setIsConfigurationProfileFormShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [configurationProfileFormValues, setConfigurationProfileFormValues] = useState<
    ConfigurationProfileUploadFormValues | ConfigurationProfileFormValues
  >();
  const [isEditMode, setIsEditMode] = useState(false);

  const configProfileUploadForm = useForm<ConfigurationProfileUploadForm>({
    mode: 'onChange',
    resolver: yupResolver(configurationProfileUploadSchema)
  });

  const onCancel = () => {
    setIsOpen(false);
    navigate('./..');
  };

  const configurationProfileToForm = (
    input: ConfigProfileFullDto
  ): ConfigurationProfileUploadFormValues => {
    return {
      display_name: input.display_name,
      ...(input?.description && { description: input?.description }),
      ...(input?.organization_name && { organization_name: input?.organization_name }),
      ...(input?.payload && { payload: input?.payload }),
      ...(input?.imported_payload && { imported_payload: atob(input?.imported_payload) }),
      scope: {
        scoped: input.scoped,
        scopeEntriesExclude:
          input.scope_entries
            ?.filter((i) => i.operation_type === 'Exclude')
            .map((i) => ({
              id: i.target_id,
              display_name: i.target_title || '',
              type: i.target_type as ScopeOptionType
            })) || [],
        scopeEntriesInclude:
          input.scope_entries
            ?.filter((i) => i.operation_type === 'Include')
            .map((i) => ({
              id: i.target_id,
              display_name: i.target_title || '',
              type: i.target_type as ScopeOptionType
            })) || []
      }
    };
  };

  const configurationProfileSimpleToForm = (
    input: ConfigProfileSimpleDto
  ): ConfigurationProfileUploadFormValues => {
    return {
      display_name: input.display_name,
      ...(input?.description && { description: input?.description }),
      ...(input?.organization_name && { organization_name: input?.organization_name }),
      ...(configProfileRef?.current?.payload && { payload: configProfileRef?.current.payload }),
      ...(configProfileRef?.current?.imported_payload && {
        imported_payload: atob(configProfileRef?.current.imported_payload)
      }),
      scope: {
        scoped: input.scoped,
        scopeEntriesExclude:
          input.scope_entries
            ?.filter((i) => i.operation_type === 'Exclude')
            .map((i) => ({
              id: i.target_id,
              display_name: i.target_title || '',
              type: i.target_type as ScopeOptionType
            })) || [],
        scopeEntriesInclude:
          input.scope_entries
            ?.filter((i) => i.operation_type === 'Include')
            .map((i) => ({
              id: i.target_id,
              display_name: i.target_title || '',
              type: i.target_type as ScopeOptionType
            })) || []
      }
    };
  };

  const handleConfigurationProfileUpload = async (values: ConfigurationProfileUploadFormValues) => {
    if (isLoading) return;
    await configProfileUploadForm.trigger();
    const configFile = configProfileUploadForm.getValues().config_profile[0];
    if (!configFile) return;
    const request: ImportConfigProfileDto = {
      config: configFile,
      scoped: values.scope?.scoped,
      device_type: deviceType,
      ...formScopeEntries(values.scope)
    };
    setIsLoading(true);
    try {
      const response = await importConfigProfile(request);
      configProfileRef.current = response;
      setConfigurationProfileFormValues(configurationProfileToForm(response));
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(localizedError);
      toast.error(localizedError, { autoClose: 5000 });
    }

    setIsLoading(false);
  };

  const handleConfigurationProfileCreate = async (values: ConfigurationProfileFormValues) => {
    if (isLoading) return;
    const request: CreateConfigProfileDto = {
      display_name: values.display_name,
      description: values.description,
      organization_name: values.organization_name,
      payload: values.payload,
      scoped: values.scope?.scoped,
      device_type: deviceType,
      ...formScopeEntries(values.scope)
    };
    setIsLoading(true);
    try {
      const response = await createConfigProfile(request);
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(localizedError);
      toast.error(localizedError, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleConfigurationProfileDelete = async () => {
    if (!configProfileRef.current) return;
    setIsLoading(true);
    try {
      await deleteConfigProfile(configProfileRef.current.id);
      navigate('./..');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
  };

  const handleConfigurationProfileDownload = async () => {
    if (!configProfileRef.current) return;
    const data = await downloadConfigProfile(configProfileRef.current.id);
    const blob = new Blob([data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${configProfileRef.current?.display_name}.mobileconfig`);
    link.click();
  };

  const handleCreateCancel = () => navigate('./..');
  const handleEditStart = () => setIsEditMode(true);
  const handleEditCancel = () => {
    if (configProfileRef.current) {
      setConfigurationProfileFormValues(configurationProfileToForm(configProfileRef.current));
    }
    setIsEditMode(false);
  };
  const handleEditRequest = async (values: ConfigurationProfileFormValues) => {
    if (!id) return;
    let request: UpdateConfigProfileDto;
    if (values.imported_payload) {
      request = {
        scoped: values.scope?.scoped,
        ...formScopeEntries(values.scope)
      };
    } else {
      request = {
        display_name: values?.display_name,
        description: values?.description,
        organization_name: values?.organization_name,
        payload: values?.payload || null,
        scoped: values.scope?.scoped,
        ...formScopeEntries(values.scope)
      };
    }
    try {
      const response = await updateConfigProfile(id, request);
      setConfigurationProfileFormValues(configurationProfileSimpleToForm(response));
      setIsEditMode(false);
    } catch (error) {
      const localizedError = String(error);
      console.log(localizedError);
      toast.error(localizedError, { autoClose: 5000 });
    }
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'import' && id !== 'new') {
      const response: ConfigProfileFullDto = await getConfigProfile(id);
      configProfileRef.current = response;
      const form = configurationProfileToForm(response);
      setConfigurationProfileFormValues(form);
    }
    setIsConfigurationProfileFormShown(true);
  };

  useEffect(() => {
    void init();
  }, [id]);

  const formMode = (routeId?: string) => {
    if (isEditMode) return 'edit';
    switch (routeId) {
      case 'import':
        return 'upload';
      case 'new':
        return 'create';
      default:
        return 'view';
    }
  };

  return (
    <div className={css.Root}>
      <Breadcrumbs title={configurationProfileFormValues?.display_name} />
      {isConfigurationProfileFormShown && (
        <ConfigurationProfileForm
          mode={formMode(id)}
          values={configurationProfileFormValues}
          onUpload={handleConfigurationProfileUpload}
          onCreate={handleConfigurationProfileCreate}
          onCreateCancel={handleCreateCancel}
          onDelete={handleConfigurationProfileDelete}
          onDownload={handleConfigurationProfileDownload}
          onEditStart={handleEditStart}
          onEditCancel={handleEditCancel}
          onEditRequest={handleEditRequest}
        />
      )}
      <ConfigurationProfileUploadModal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        form={configProfileUploadForm}
        onCancel={onCancel}
      />
    </div>
  );
};
