import { FC } from 'react';
import css from './storage-table.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const StorageTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <div>
      <table className={css.Root}>
        <tbody>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.storage_tab.table.heading.primary_drive_model'
              )}
            </td>
            <td>{di?.primary_drive_model || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.storage_tab.table.heading.revision')}</td>
            <td>{di?.primary_drive_revision || '---'}</td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.storage_tab.table.heading.serial_number')}
            </td>
            <td>{di?.primary_drive_serial || '---'}</td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.storage_tab.table.heading.total_capacity')}
            </td>
            <td>
              {di?.primary_drive_capacity_mb
                ? `${(di?.primary_drive_capacity_mb / 1024).toFixed(2)} GB`
                : '---'}
            </td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.storage_tab.table.heading.smart_status')}
            </td>
            <td>{di?.primary_drive_smart_status || '---'}</td>
          </tr>
        </tbody>
      </table>
      <DeviceExtensionAttributes
        device={device}
        inventorySection={ExtensionAttributeInventorySection.Storage}
      ></DeviceExtensionAttributes>
    </div>
  );
};
