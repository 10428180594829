import { FC, FormEvent, useState } from 'react';
import { TextInput, TextInputType } from '../../../components/text-input/text-input.component';
import css from './settings-upload-certificate.module.scss';
import { useTranslation } from 'react-i18next';
import { FileInput } from '../../../components/file-input/file-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Permission, UploadPushCertificateRequestDto } from '../../../../types/api';
import {
  UploadAPNSCertificateForm,
  uploadAPNSCertificateFormSchema
} from './settings-upload-certificate.schema';
import { uploadCertificate } from '../../../../api/mdm';
import { Button } from '../../../components/button/button.component';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';

export const SettingsUploadCertificate: FC = () => {
  const [serverError, setServerError] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { register, formState, trigger, getValues } = useForm<UploadAPNSCertificateForm>({
    mode: 'onChange',
    resolver: yupResolver(uploadAPNSCertificateFormSchema),
    defaultValues: uploadAPNSCertificateFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = async (event?: FormEvent) => {
    event?.preventDefault();
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    const certificateFile = values.certificate[0];
    const privateKeyFile = values.private_key[0];
    try {
      const request: UploadPushCertificateRequestDto = {
        certificate: certificateFile,
        private_key: privateKeyFile,
        password: values.password
      };
      await uploadCertificate(request);
      setServerError('');
      setMessage(t('settings.tiles.upload_certificate.page.upload_success'));
    } catch (error: unknown) {
      setMessage('');
      const localizedErrorString = getLocalizedErrorString(error as Error);
      setServerError(localizedErrorString);
    }
  };

  return (
    <div>
      <Breadcrumbs />
      <div className={css.Header}>
        <h5>{t('settings.tiles.upload_certificate.page.title')}</h5>
      </div>
      <div className={css.Content}>
        <form onSubmit={handleFormSubmit}>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
            <FileInput
              label={t('settings.tiles.upload_certificate.page.certificate_file')}
              accept=".pem"
              errorText={formState.errors.certificate?.message}
              register={register('certificate')}
            />
            <FileInput
              label={t('settings.tiles.upload_certificate.page.private_key_file')}
              accept=".key"
              errorText={formState.errors.private_key?.message}
              register={register('private_key')}
            />
            <TextInput
              label={t('settings.tiles.upload_certificate.page.password')}
              type={TextInputType.PASSWORD}
              register={register('password')}
              errorText={formState.errors.password?.message}
            />
            <div className={css.ActionBtnGroup}>
              {message && <span className={css.Success}>{message}</span>}
              {serverError && <span className={css.ServerError}>{serverError}</span>}
              <Button className={css.ActionBtn} type="submit" theme="primary">
                {t('settings.tiles.upload_certificate.page.upload_btn')}
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
