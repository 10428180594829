import { mixed, object } from 'yup';
import i18next from '../../../../i18n/i18n';
import { fileExtension, fileNotEmpty } from '../../../../utils/yup.validations';
import { DeviceOsType } from '../../../../types/api';

export interface UploadLicenseForm {
  license: FileList;
  os_type: DeviceOsType;
}

export const UploadLicenseFormSchema = object({
  license: mixed()
    .required()
    .test(fileNotEmpty(i18next.t('settings.tiles.license.page.license_file_missing')))
    .test(fileExtension(['pem'])),
  os_type: mixed()
}).required();
