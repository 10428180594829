import { FC } from 'react';
import { WithClassname } from '../../../types/common';
import css from './blank-page.module.scss';
import cn from 'classnames';

interface IProps {
  text: string;
}

export const BlankPage: FC<IProps & WithClassname> = (props) => {
  const { text, className } = props;

  return <div className={cn(className, css.Text)}>{text}</div>;
};
