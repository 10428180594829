import css from './script-list.module.scss';
import { FC, useEffect, useState } from 'react';
import { Permission, ScriptBasicDto } from '../../../../../../types/api';
import { Link } from 'react-router-dom';
import { Button } from '../../../../../components/button/button.component';
import cn from 'classnames';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { getScripts } from '../../../../../../api/scripts';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../../../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../../../../contexts/permission.context';

export const ScriptList: FC = () => {
  const [scripts, setScripts] = useState<ScriptBasicDto[]>();
  const init = async () => {
    const response = await getScripts();
    setScripts(response.scripts);
  };
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  useEffect(() => {
    void init();
  }, []);

  const scriptsColumns: TableColumn<ScriptBasicDto>[] = [
    {
      name: t('settings.tiles.scripts.page.table.heading.name'),
      cell: (script) => <Link to={`${script.id}`}>{script.display_name}</Link>
    }
  ];
  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditScripts)} theme="primary">
            {t('settings.tiles.scripts.page.new_btn')}
          </Button>
        </Link>
      </div>
      {scripts && (
        <DataTable
          columns={scriptsColumns}
          data={scripts}
          noDataComponent={t('common.no_records_in_table')}
        />
      )}
    </div>
  );
};
