import { FC } from 'react';
import css from './security-table.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const SecurityTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <div>
      <table className={css.Root}>
        <tbody>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.security_tab.table.heading.system_integrity_protection'
              )}
            </td>
            <td>
              {isBoolean(di?.sip_enabled)
                ? di?.sip_enabled
                  ? t('inventory.device_page.inventory_tab.security_tab.table.enabled')
                  : t('inventory.device_page.inventory_tab.security_tab.table.disabled')
                : '---'}
            </td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.security_tab.table.heading.gatekeeper_status'
              )}
            </td>
            <td>
              {isBoolean(di?.gatekeeper_enabled)
                ? di?.gatekeeper_enabled
                  ? t('inventory.device_page.inventory_tab.security_tab.table.enabled')
                  : t('inventory.device_page.inventory_tab.security_tab.table.disabled')
                : '---'}
            </td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.security_tab.table.heading.xprotect_definitions_version'
              )}
            </td>
            <td>{di?.x_protect_definitions_version || '---'}</td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.security_tab.table.heading.automatic_login')}
            </td>
            <td>
              {isBoolean(di?.autologin_enabled)
                ? di?.autologin_enabled
                  ? t('inventory.device_page.inventory_tab.security_tab.table.enabled')
                  : t('inventory.device_page.inventory_tab.security_tab.table.disabled')
                : '---'}
            </td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.security_tab.table.heading.remote_desktop_enabled'
              )}
            </td>
            <td>
              {isBoolean(di?.ard_enabled)
                ? di?.ard_enabled
                  ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
                  : t('inventory.device_page.inventory_tab.security_tab.table.no')
                : '---'}
            </td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.security_tab.table.heading.activation_lock_enabled'
              )}
            </td>
            <td>
              {isBoolean(di?.activation_lock_enabled)
                ? di?.activation_lock_enabled
                  ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
                  : t('inventory.device_page.inventory_tab.security_tab.table.no')
                : '---'}
            </td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.security_tab.table.heading.activation_lock_bypass'
              )}
            </td>
            <td>{di?.activation_lock ? di?.activation_lock : '---'}</td>
          </tr>
          {di?.is_apple_silicon ? (
            <>
              <tr>
                <td>
                  {t(
                    'inventory.device_page.inventory_tab.security_tab.table.heading.recovery_lock_enabled'
                  )}
                </td>
                <td>
                  {isBoolean(di?.recovery_lock_enabled)
                    ? di?.recovery_lock_enabled
                      ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
                      : t('inventory.device_page.inventory_tab.security_tab.table.no')
                    : '---'}
                </td>
              </tr>
              <tr>
                <td>
                  {t(
                    'inventory.device_page.inventory_tab.security_tab.table.heading.recovery_lock'
                  )}
                </td>
                <td>{di?.recovery_lock ? di?.recovery_lock : '---'}</td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  {t(
                    'inventory.device_page.inventory_tab.security_tab.table.heading.firmware_password_enabled'
                  )}
                </td>
                <td>
                  {isBoolean(di?.firmware_password_enabled)
                    ? di?.firmware_password_enabled
                      ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
                      : t('inventory.device_page.inventory_tab.security_tab.table.no')
                    : '---'}
                </td>
              </tr>

              <tr>
                <td>
                  {t(
                    'inventory.device_page.inventory_tab.security_tab.table.heading.firmware_password'
                  )}
                </td>
                <td>{di?.firmware_password ? di?.firmware_password : '---'}</td>
              </tr>
            </>
          )}

          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.security_tab.table.heading.firewall_enabled')}
            </td>
            <td>
              {isBoolean(di?.firewall_enabled)
                ? di?.firewall_enabled
                  ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
                  : t('inventory.device_page.inventory_tab.security_tab.table.no')
                : '---'}
            </td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.security_tab.table.heading.lock_pin')}</td>
            <td>{device?.device_information?.lock_pin || '---'}</td>
          </tr>
        </tbody>
      </table>
      <DeviceExtensionAttributes
        device={device}
        inventorySection={ExtensionAttributeInventorySection.Security}
      ></DeviceExtensionAttributes>
    </div>
  );
};
