import { FC, useEffect, useState } from 'react';
import DataTable, { SortOrder } from 'react-data-table-component';
import css from './static-group-device-users-list.module.scss';

import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import {
  PaginationSortOrder,
  Permission,
  StaticGroupDeviceUsersSimpleDto,
  StaticGroupDeviceUsersSortField
} from '../../../types/api';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/button/button.component';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../contexts/permission.context';
import { getStaticGroups } from '../../../api/static-groups-device-users';

export const StaticGroupDeviceUsersList: FC = () => {
  const navigate = useNavigate();

  const [staticGroupsCount, setStaticGroupsCount] = useState(0);
  const [staticGroupsData, setStaticGroupsData] = useState<StaticGroupDeviceUsersSimpleDto[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<StaticGroupDeviceUsersSortField | undefined>(
    StaticGroupDeviceUsersSortField.Title
  );
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const columns: TableColumn<StaticGroupDeviceUsersSimpleDto>[] = [
    {
      name: t('static_groups.table.heading.name'),
      sortField: StaticGroupDeviceUsersSortField.Title,
      selector: (row) => (row.title ? row.title : ''),
      sortable: true,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('static_groups.table.heading.count'),
      sortField: StaticGroupDeviceUsersSortField.DeviceUsersCount,
      selector: (row) => row.device_users_count.toString(),
      sortable: true,
      style: { cursor: 'pointer' }
    }
  ];

  const handleRowClicked = (row: StaticGroupDeviceUsersSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<StaticGroupDeviceUsersSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as StaticGroupDeviceUsersSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    const fetchStaticGroupsData = async () => {
      const result = await getStaticGroups({
        limit: rowsPerPage,
        page: currentPage,
        sort_field: sortField,
        sort_order: sortOrder
      });
      setStaticGroupsData(result.groups);
      setStaticGroupsCount(result.count);
    };

    void fetchStaticGroupsData();
  }, [currentPage, rowsPerPage, sortOrder, sortField]);

  return (
    <div className={css.Root}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditGroups)} theme="primary">
            {t('static_groups.new_btn')}
          </Button>
        </Link>
      </div>
      {staticGroupsData && (
        <DataTable
          columns={columns}
          data={staticGroupsData}
          onRowClicked={handleRowClicked}
          noDataComponent={t('common.no_records_in_table')}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={staticGroupsCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
    </div>
  );
};
