import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './inventory-tab-navbar.module.scss';
import {
  faCircleInfo,
  faMicrochip,
  faShield,
  faHardDrive,
  faVault,
  faCubes,
  faFileLines,
  faGears,
  faUser,
  faGear,
  faNoteSticky,
  faSquarePlus
} from '@fortawesome/free-solid-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { InventoryTabMobileTabs, InventoryTabTabs } from '../../../../../../constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../contexts/device-section.context';

interface IProps {
  openedTab: string;
  onChangeTab: (tabName: string) => void;
}

export const InventoryTabNavbar: FC<IProps> = (props: IProps) => {
  const { openedTab, onChangeTab } = props;
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();

  const computerTabs = [
    {
      tab: InventoryTabTabs.GENERAL,
      icon: faCircleInfo,
      name: t('inventory.device_page.inventory_tab.general_tab.name')
    },
    {
      tab: InventoryTabTabs.HARDWARE,
      icon: faMicrochip,
      name: t('inventory.device_page.inventory_tab.hardware_tab.name')
    },
    {
      tab: InventoryTabTabs.OS_INFO,
      icon: faApple,
      name: t('inventory.device_page.inventory_tab.os_info_tab.name')
    },
    {
      tab: InventoryTabTabs.SECURITY,
      icon: faShield,
      name: t('inventory.device_page.inventory_tab.security_tab.name')
    },
    {
      tab: InventoryTabTabs.STORAGE,
      icon: faHardDrive,
      name: t('inventory.device_page.inventory_tab.storage_tab.name')
    },
    {
      tab: InventoryTabTabs.ENCRYPTION,
      icon: faVault,
      name: t('inventory.device_page.inventory_tab.disk_encryption_tab.name')
    },
    {
      tab: InventoryTabTabs.APPLICATIONS,
      icon: faCubes,
      name: t('inventory.device_page.inventory_tab.applications_tab.name')
    },
    {
      tab: InventoryTabTabs.PROFILES,
      icon: faFileLines,
      name: t('inventory.device_page.inventory_tab.profiles_tab.name')
    },
    {
      tab: InventoryTabTabs.USER_ACCOUNTS,
      icon: faUser,
      name: t('inventory.device_page.inventory_tab.local_user_accounts_tab.name')
    },
    {
      tab: InventoryTabTabs.SERVICES,
      icon: faGears,
      name: t('inventory.device_page.inventory_tab.services_tab.name')
    },
    {
      tab: InventoryTabTabs.EXTENSION_ATTRIBUTES,
      icon: faSquarePlus,
      name: t('inventory.device_page.inventory_tab.extension_attributes_tab.name')
    }
  ];

  const mobileTabs = [
    {
      tab: InventoryTabMobileTabs.GENERAL,
      icon: faCircleInfo,
      name: t('inventory_mobile.device_page.inventory_tab.general_tab.name')
    },
    {
      tab: InventoryTabMobileTabs.OS_INFO,
      icon: faApple,
      name: t('inventory_mobile.device_page.inventory_tab.os_info_tab.name')
    },
    {
      tab: InventoryTabMobileTabs.HARDWARE,
      icon: faGear,
      name: t('inventory_mobile.device_page.inventory_tab.hardware_tab.name')
    },
    {
      tab: InventoryTabMobileTabs.SECURITY,
      icon: faShield,
      name: t('inventory_mobile.device_page.inventory_tab.security_tab.name')
    },
    {
      tab: InventoryTabMobileTabs.APPLICATIONS,
      icon: faCubes,
      name: t('inventory_mobile.device_page.inventory_tab.applications_tab.name')
    },
    {
      tab: InventoryTabMobileTabs.PROFILES,
      icon: faFileLines,
      name: t('inventory_mobile.device_page.inventory_tab.profiles_tab.name')
    },
    {
      tab: InventoryTabMobileTabs.PROVISIONING_PROFILES,
      icon: faFileLines,
      name: t('inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.name')
    },
    {
      tab: InventoryTabMobileTabs.CERTIFICATES,
      icon: faNoteSticky,
      name: t('inventory_mobile.device_page.inventory_tab.certificates_tab.name')
    }
  ];

  const actualTabs = deviceType === DeviceType.COMPUTERS ? computerTabs : mobileTabs;

  return (
    <div className={css.InventoryTabNavbar}>
      <ul className={css.Tabs}>
        {actualTabs.map((tab, i) => {
          return (
            <li
              className={`${css.Tab} ${openedTab == tab.tab ? css.Active : ''}`}
              onClick={() => onChangeTab(tab.tab)}
              title={tab.name}
              key={i}
            >
              <FontAwesomeIcon icon={tab.icon} />
              {tab.name}
            </li>
          );
        })}
        <li
          className={`${css.Tab} ${openedTab == InventoryTabTabs.DEVICE_USER ? css.Active : ''}`}
          onClick={() => onChangeTab(InventoryTabTabs.DEVICE_USER)}
          title={t('inventory.device_page.inventory_tab.device_user_tab.name')}
        >
          <FontAwesomeIcon icon={faUser} />
          {t('inventory.device_page.inventory_tab.device_user_tab.name')}
        </li>
      </ul>
    </div>
  );
};
