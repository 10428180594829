import cn from 'classnames';
import { FC, FormEvent, useState } from 'react';
import css from './script-form.module.scss';
import { TextInput } from '../../../../../components/text-input/text-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { scriptFormSchema, ScriptFormValues } from './script-form.schema';
import { Button } from '../../../../../components/button/button.component';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../components/modal/modal.component';
import { usePermission } from '../../../../../contexts/permission.context';
import { Permission } from '../../../../../../types/api';

interface IProps {
  mode: 'view' | 'create' | 'update' | 'edit';
  values?: ScriptFormValues;
  onCreate?: (values: ScriptFormValues) => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onSave?: (values: ScriptFormValues) => void;
  onCancel?: () => void;
}

export const ScriptForm: FC<IProps> = (props) => {
  const { values, onCreate, onDelete, onEdit, onSave, onCancel, mode = 'view' } = props;
  const { register, formState, trigger, getValues } = useForm<ScriptFormValues>({
    mode: 'onChange',
    resolver: yupResolver(scriptFormSchema),
    defaultValues: values || scriptFormSchema.getDefault(),
    values
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleClickCreate = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    onCreate?.(getValues());
  };
  const handleClickEdit = () => onEdit?.();

  const handleClickCancel = () => onCancel?.();

  const handleClickSave = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    onSave?.(getValues());
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditPolicies)}>
        {mode === 'create' && (
          <h5 className={css.FormTitle}>
            {t('settings.tiles.scripts.page.script_page.new_script')}
          </h5>
        )}
        <TextInput
          className={css.Input}
          label={t('settings.tiles.scripts.page.script_page.display_name')}
          register={register('display_name')}
          errorText={formState.errors.display_name?.message}
          disabled={mode === 'view'}
          required
        />
        <TextInput
          className={cn(css.Input, css.Textarea)}
          label={t('settings.tiles.scripts.page.script_page.description')}
          register={register('description')}
          disabled={mode === 'view'}
          textarea
        />
        <TextInput
          className={cn(css.Textarea, css.Code)}
          label={t('settings.tiles.scripts.page.script_page.script_content')}
          register={register('script')}
          errorText={formState.errors.script?.message}
          disabled={mode === 'view'}
          textarea
          required
        />
        <Modal
          title={t('settings.tiles.scripts.page.script_page.modal_delete.title')}
          submitButtonName={t('settings.tiles.scripts.page.script_page.modal_delete.delete_btn')}
          isOpen={isDeleteModalOpen}
          onRequestClose={handleDeleteModalClose}
          onSubmit={handleDeleteModalSubmit}
        >
          <p>
            {t('settings.tiles.scripts.page.script_page.modal_delete.message', {
              title: getValues().display_name
            })}
          </p>
        </Modal>
        <div className={css.ActionButtons}>
          {mode === 'create' && (
            <Button theme="primary" onClick={handleClickCreate}>
              {t('settings.tiles.scripts.page.script_page.create_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <Button theme="primary" onClick={handleClickEdit}>
              {t('settings.tiles.scripts.page.script_page.edit_btn')}
            </Button>
          )}
          {mode === 'edit' && (
            <Button theme="primary" onClick={handleClickSave}>
              {t('settings.tiles.scripts.page.script_page.save_btn')}
            </Button>
          )}
          {mode === 'edit' && (
            <Button theme="danger" onClick={handleClickCancel}>
              {t('settings.tiles.scripts.page.script_page.cancel_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <Button theme="danger" onClick={handleDeleteModalOpen}>
              {t('settings.tiles.scripts.page.script_page.delete_btn')}
            </Button>
          )}
        </div>
      </fieldset>
    </form>
  );
};
