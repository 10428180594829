import { FC, useEffect, useState } from 'react';
import { DeviceFullDto, PolicySimpleDto } from '../../../../../../../../../../../types/api';
import css from './policies-tab.module.scss';
import { getPoliciesForDevice } from '../../../../../../../../../../../api/policies';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  device?: DeviceFullDto;
}

export const PoliciesTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [policies, setPolicies] = useState<PolicySimpleDto[]>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!device) return;
    getPoliciesForDevice(device.id).then((res) => setPolicies(res.policies));
  }, []);

  return (
    <div className={css.Root}>
      <table className={css.PoliciesTable}>
        <thead>
          <tr>
            <th>{t('inventory.device_page.management_tab.policies_tab.table.heading.title')}</th>
          </tr>
        </thead>
        <tbody>
          {policies?.map((i) => (
            <tr key={i.id}>
              <td>
                <Link to={`/computers/policies/${i.id}`} target="_blank">
                  {i.title}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
