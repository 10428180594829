import { FC } from 'react';
import DataTable from 'react-data-table-component';
import css from './settings-license.module.scss';

import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';

import { useTranslation } from 'react-i18next';
import { LicenseDto } from '../../../../types/api';
import { formatDate } from '../../../../utils/time.utils';

interface IProps {
  allLicenses: LicenseDto[];
}
export const LicenseListComponent: FC<IProps> = (props: IProps) => {
  const { allLicenses } = props;
  const { t } = useTranslation();

  const columns: TableColumn<LicenseDto>[] = [
    {
      name: t('settings.tiles.license.table.activation_date'),
      selector: (row) => (row.activated_at ? formatDate(row.activated_at, 'dd-MM-yyyy') : ''),
      maxWidth: '350px'
    },
    {
      name: t('settings.tiles.license.table.expiration_date'),
      selector: (row) => (row.expires_at ? formatDate(row.expires_at, 'dd-MM-yyyy') : ''),
      maxWidth: '400px'
    },
    {
      name: t('settings.tiles.license.table.device_num'),
      selector: (row) => (row.max_device ? row.max_device : 0)
    }
  ];

  return (
    <div id="licenses" className={css.LicensesListContainer}>
      {allLicenses.length !== 0 && (
        <>
          <div className={css.Header}>
            <h5>{t('settings.tiles.license.table.title')}</h5>
          </div>
          <DataTable columns={columns} data={allLicenses} noDataComponent={''} />
        </>
      )}
    </div>
  );
};
