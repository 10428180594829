import { FC, FormEvent, useState } from 'react';
import css from './user-local-form.module.scss';
import { Permission, UserOriginType } from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../../../components/text-input/text-input.component';
import { Dropdown, DropdownOption } from '../../../../../components/dropdown/dropdown.component';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { userLocalFormSchema, UserLocalFormValues } from './user-local-form.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { IRole } from '../../user.component';
import { Button } from '../../../../../components/button/button.component';
import { Modal } from '../../../../../components/modal/modal.component';
import { filterChangedFormFields } from '../../../../../../utils/react-hook-form.utils';
import { userOriginTypeTitles } from '../../../../../../const/users.const';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: UserLocalFormValues;
  roles?: IRole[];
  onCreate?: (values: UserLocalFormValues) => void;
  onEdit?: () => void;
  onSave?: (values: Partial<UserLocalFormValues>) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

export const UserLocalFormComponent: FC<IProps> = (props) => {
  const { mode, values, roles = [], onCreate, onEdit, onSave, onCancel, onDelete } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const rolesOptions: DropdownOption[] = roles.map((role) => ({
    text: role.title,
    value: role.id
  }));

  const userForm = useForm<UserLocalFormValues>({
    mode: 'onChange',
    resolver: yupResolver(userLocalFormSchema),
    defaultValues: values || userLocalFormSchema.getDefault(),
    values
  });

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    const isValid = await userForm.trigger();
    if (!isValid) return;

    onCreate?.(userForm.getValues());
  };

  const handleEdit = () => onEdit?.();

  const handleSave = async () => {
    const isValid = await userForm.trigger();
    if (!isValid) return;

    const changedValues = filterChangedFormFields(
      userForm.getValues(),
      userForm.formState.dirtyFields
    );

    onSave?.(changedValues);
  };

  const handleCancel = async () => {
    await userForm.reset();
    onCancel?.();
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
        <h5 className={css.Title}>
          {mode === 'create'
            ? t('settings.tiles.users.page.user_page.new_title')
            : values?.display_name || values?.username || ''}
        </h5>
        <div className={css.Content}>
          <TextInput
            label={t('settings.tiles.users.page.user_page.username')}
            required
            errorText={userForm.formState.errors.username?.message}
            register={userForm.register('username')}
            disabled={mode === 'view'}
            autoComplete="new-password"
          />
          <TextInput
            label={t('settings.tiles.users.page.user_page.origin_type')}
            value={userOriginTypeTitles[UserOriginType.Local]}
            disabled
          />
          <TextInput
            label={t('settings.tiles.users.page.user_page.display_name')}
            errorText={userForm.formState.errors.display_name?.message}
            register={userForm.register('display_name')}
            disabled={mode === 'view'}
          />
          <Dropdown
            label={t('settings.tiles.users.page.user_page.role')}
            options={rolesOptions}
            disabled={mode === 'view'}
            register={userForm.register('role.id')}
          />
          {mode !== 'view' && (
            <>
              <div className={cn('form-group', css.FormGroup)}>
                <p className={cn(css.Label)}>{t('settings.tiles.users.page.user_page.password')}</p>
                <div className={css.PasswordContainer}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    placeholder={t('sign_in.password')}
                    {...userForm.register('password')}
                    autoComplete="new-password"
                  />
                  <div className={css.PasswordEye} onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <FontAwesomeIcon width={22} icon={faEye} />
                    ) : (
                      <FontAwesomeIcon width={22} icon={faEyeSlash} />
                    )}
                  </div>
                </div>
                <p className={css.Error}>{userForm.formState.errors.password?.message}</p>
              </div>
              <div className={cn('form-group', css.FormGroup)}>
                <p className={cn(css.Label)}>
                  {t('settings.tiles.users.page.user_page.confirm_password')}
                </p>
                <div className={css.PasswordContainer}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    placeholder={t('sign_in.password')}
                    {...userForm.register('confirmPassword')}
                  />
                </div>
                <p className={css.Error}>{userForm.formState.errors.confirmPassword?.message}</p>
              </div>
            </>
          )}
        </div>
        <div className={css.ActionButtons}>
          {mode === 'create' && (
            <Button theme="primary" onClick={handleCreate}>
              {t('settings.tiles.users.page.user_page.create_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <>
              <Button theme="primary" onClick={handleEdit}>
                {t('settings.tiles.users.page.user_page.edit_btn')}
              </Button>
              <Button theme="danger" onClick={handleDeleteModalOpen}>
                {t('settings.tiles.users.page.user_page.delete_btn')}
              </Button>
            </>
          )}

          {mode === 'edit' && (
            <>
              <Button theme="primary" onClick={handleSave}>
                {t('settings.tiles.users.page.user_page.save_btn')}
              </Button>
              <Button theme="danger" onClick={handleCancel}>
                {t('settings.tiles.users.page.user_page.cancel_btn')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
      <Modal
        title={t('settings.tiles.users.page.user_page.modal_delete.title')}
        submitButtonName={t('settings.tiles.users.page.user_page.modal_delete.delete_btn')}
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
      >
        <p>
          {t('settings.tiles.users.page.user_page.modal_delete.message', {
            title: values?.display_name || values?.username
          })}
        </p>
      </Modal>
    </form>
  );
};
