import { FC, useState } from 'react';
import { InventoryTabTabs } from '../../../../constants';
import css from './inventory-tab.module.scss';
import { InventoryTabContent } from './components/inventory-tab-content/inventory-tab-content.component';
import { InventoryTabNavbar } from './components/inventory-tab-navbar/inventory-tab-navbar.component';
import { DeviceFullDto } from '../../../../../../../types/api';

interface IProps {
  device?: DeviceFullDto;
}

export const InventoryTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [openedTab, setOpenedTab] = useState<string>(InventoryTabTabs.GENERAL);

  const handleChangeTab = (tabName: string) => {
    setOpenedTab(tabName);
  };

  return (
    <div className={css.InventoryTab}>
      <InventoryTabNavbar openedTab={openedTab} onChangeTab={handleChangeTab} />
      <InventoryTabContent device={device} openedTab={openedTab} />
    </div>
  );
};
