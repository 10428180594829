import { boolean, mixed, number, object, string } from 'yup';
import {
  fileExtension,
  isDistinguishedName,
  isHostname,
  isPort
} from '../../../../utils/yup.validations';
import i18next from '../../../../i18n/i18n';

export interface LdapForm {
  display_name: string;
  hostname: string;
  ssl: boolean;
  ca_certificate?: FileList;
  port?: string;
  base_dn?: string;
  filter?: string;
  user_dn?: string;
  password?: string;
  sync_enabled?: boolean;
  sync_interval?: number;
  timeout_connect: number;
  timeout_search: number;
  username_mapping_key?: string;
  full_name_mapping_key?: string;
  email_mapping_key?: string;
}

export const ldapFormSchema = object({
  display_name: string().required(
    i18next.t('settings.tiles.ldap.page.form_label_display_name_required')
  ),
  hostname: string()
    .required(i18next.t('settings.tiles.ldap.page.form_label_hostname_required'))
    .test(isHostname()),
  ssl: boolean(),
  ca_certificate: mixed<FileList>().test(fileExtension(['cer', 'pem'])),
  port: string().test(isPort()),
  base_dn: string().test(isDistinguishedName()),
  filter: string(),
  user_dn: string().test(isDistinguishedName()),
  password: string(),
  username_mapping_key: string(),
  full_name_mapping_key: string(),
  email_mapping_key: string(),
  sync_enabled: boolean(),
  sync_interval: number()
    .transform((value) => (value ? Number(value) : 0))
    .min(60, i18next.t('settings.tiles.ldap.page.error_sync_min'))
    .max(86_400, i18next.t('settings.tiles.ldap.page.error_sync_max')),
  timeout_connect: number()
    .transform((value) => (value ? Number(value) : 0))
    .min(5, i18next.t('settings.tiles.ldap.page.error_timeout_min'))
    .max(3600, i18next.t('settings.tiles.ldap.page.error_timeout_max')),
  timeout_search: number()
    .transform((value) => (value ? Number(value) : 0))
    .min(5, i18next.t('settings.tiles.ldap.page.error_timeout_min'))
    .max(3600, i18next.t('settings.tiles.ldap.page.error_timeout_max'))
}).required();
