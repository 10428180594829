import { FC, useEffect, useState } from 'react';
import { ConfigProfileSimpleDto, DeviceFullDto } from '../../../../../../../../../../../types/api';
import css from './profiles-tab.module.scss';
import { Link } from 'react-router-dom';
import { getConfigProfilesForDevice } from '../../../../../../../../../../../api/config-profiles';
import { useTranslation } from 'react-i18next';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../../../contexts/device-section.context';

interface IProps {
  device?: DeviceFullDto;
}

export const ProfilesTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [profiles, setProfiles] = useState<ConfigProfileSimpleDto[]>();
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();

  useEffect(() => {
    if (!device) return;
    getConfigProfilesForDevice(device.id).then((res) => setProfiles(res.config_profiles));
  }, []);

  const createProfileLink = (profile: ConfigProfileSimpleDto): string => {
    const profileDeviceType = deviceType === DeviceType.COMPUTERS ? 'computers' : 'mobile';
    return `/${profileDeviceType}/configuration-profiles/${profile.id}`;
  };
  return (
    <div className={css.Root}>
      <table className={css.ProfilesTable}>
        <thead>
          <tr>
            <th>{t('inventory.device_page.management_tab.policies_tab.table.heading.title')}</th>
          </tr>
        </thead>
        <tbody>
          {profiles?.map((i) => (
            <tr key={i.id}>
              <td>
                <Link to={createProfileLink(i)} target="_blank">
                  {i.display_name}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
