import { FC, useState } from 'react';
import { ManagementTabTabs } from '../../../../constants';
import css from './management-tab.module.scss';
import { ManagementTabContent } from './components/management-tab-content/management-tab-content.component';
import { ManagementTabNavbar } from './components/management-tab-navbar/management-tab-navbar.component';
import { DeviceFullDto } from '../../../../../../../types/api';

interface IProps {
  device?: DeviceFullDto;
}

export const ManagementTab: FC<IProps> = (props: IProps) => {
  const { device } = props;

  const [openedTab, setOpenedTab] = useState(ManagementTabTabs.COMMANDS);

  const handleChangeTab = (tabName: string) => {
    setOpenedTab(tabName);
  };

  return (
    <div className={css.ManagementTab}>
      <ManagementTabNavbar openedTab={openedTab} onChangeTab={handleChangeTab} />
      <ManagementTabContent openedTab={openedTab} device={device} />
    </div>
  );
};
