import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ConfigProfileLogDto,
  ConfigProfileLogField,
  GetConfigProfileLogsResponseDto,
  PaginationSortOrder
} from '../../../../../types/api';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { dateSort, formatDate } from '../../../../../utils/time.utils';
import { getConfigProfileLogs } from '../../../../../api/config-profiles';
import css from './configuration-profile-logs-tab.module.scss';
import { PaginationRowsPerPageOptions } from '../../../../../const/pagination.const';

export const LogsTab: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [logs, setLogs] = useState<ConfigProfileLogDto[]>([]);
  const [logsCount, setLogsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<ConfigProfileLogField | undefined>(
    ConfigProfileLogField.DeviceInformationDeviceName
  );
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);

  const configProfileInstanceStatusNames: Record<string, string> = {
    ['in_progress']: t('profiles.profile_form.logs_tab.table.status_names.in_progress'),
    ['success']: t('profiles.profile_form.logs_tab.table.status_names.success'),
    ['failed']: t('profiles.profile_form.logs_tab.table.status_names.failed')
  };

  const columns: TableColumn<ConfigProfileLogDto>[] = [
    {
      name: t('profiles.profile_form.logs_tab.table.heading.device_name'),
      selector: (row) =>
        row.device?.device_information?.device_name
          ? row.device?.device_information?.device_name
          : '',
      sortable: true,
      sortField: ConfigProfileLogField.DeviceInformationDeviceName
    },
    {
      name: t('profiles.profile_form.logs_tab.table.heading.serial_number'),
      selector: (row) => row.device?.serial_number,
      sortable: true,
      sortField: ConfigProfileLogField.DeviceSerialNumber
    },
    {
      name: t('profiles.profile_form.logs_tab.table.heading.time'),
      selector: (row) => (row.created_at ? formatDate(row.created_at) : ''),
      sortable: true,
      sortField: ConfigProfileLogField.CreatedAt,
      sortFunction: (rowA, rowB) => dateSort(rowA.created_at, rowB.created_at)
    },
    {
      name: t('profiles.profile_form.logs_tab.table.heading.status'),
      selector: (row) => {
        return configProfileInstanceStatusNames[row.status];
      },
      sortable: true,
      sortField: ConfigProfileLogField.Status
    }
  ];

  const reloadData = async () => {
    if (!id) return;
    const response = await getConfigProfileLogs(id, {
      page: currentPage,
      limit: rowsPerPage,
      sort_field: sortField,
      sort_order: sortOrder
    });
    setLogs(response.logs);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<ConfigProfileLogDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as ConfigProfileLogField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    reloadData();
  }, [currentPage, rowsPerPage, sortOrder, sortField]);

  useEffect(() => {
    const fetchLogs = async () => {
      if (!id) return;
      const result: GetConfigProfileLogsResponseDto = await getConfigProfileLogs(id, {
        limit: rowsPerPage,
        page: currentPage,
        sort_field: sortField,
        sort_order: sortOrder
      });
      setLogs(result.logs);

      setLogsCount(result.count);
    };

    void fetchLogs();
  }, []);

  return (
    <div className={css.Root}>
      {logs && logs.length > 0 && (
        <DataTable
          columns={columns}
          className={css.Table}
          data={logs}
          noDataComponent={t('common.no_records_in_table')}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={logsCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
    </div>
  );
};
