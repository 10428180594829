import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import css from './nav-item.module.scss';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import cn from 'classnames';

interface IProps {
  icon: IconDefinition;
  text: string;
  active?: boolean;
  link?: string;
  external?: boolean;
}

export const NavItem: FC<IProps> = (props: IProps) => {
  const { icon, text, link, active = false, external = false } = props;

  return external ? (
    <li className={cn('nav-item')}>
      <a
        className={cn(css.Link, active && css.LinkActive)}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className={css.LinkIcon} icon={icon} />
        <p className={css.LinkText}>{text}</p>
      </a>
    </li>
  ) : (
    <li className={cn('nav-item')}>
      <Link to={link ? link : '#'} className={cn(css.Link, active && css.LinkActive)}>
        <FontAwesomeIcon className={css.LinkIcon} icon={icon} />
        <p className={css.LinkText}>{text}</p>
      </Link>
    </li>
  );
};
