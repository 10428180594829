import { FC, FormEvent, useEffect } from 'react';
import css from './settings-scripts-config.module.scss';
import { TextInput, TextInputType } from '../../../components/text-input/text-input.component';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { Button } from '../../../components/button/button.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { ScriptsConfigForm, scriptsConfigFormSchema } from './settings-scripts-config.schema';
import { getSettings, updateSettings } from '../../../../api/settings';
import { toast } from 'react-toastify';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../../contexts/permission.context';

export const SettingsScriptsConfig: FC = () => {
  const initRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const updateRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, formState, setValue, trigger, getValues } = useForm<ScriptsConfigForm>({
    mode: 'onChange',
    resolver: yupResolver(scriptsConfigFormSchema),
    defaultValues: scriptsConfigFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (initRequest.loading || updateRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        policy_custom_script_timeout: Number(values.policy_custom_script_timeout),
        get_device_information_timeout: Number(values.get_device_information_timeout)
      };
      await updateRequest.send(updateSettings(requestBody));
    } catch (error: unknown) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  useEffect(() => {
    const init = async () => {
      const settings = await initRequest.send(
        getSettings(['policy_custom_script_timeout', 'get_device_information_timeout'])
      );
      if (settings.policy_custom_script_timeout) {
        setValue('policy_custom_script_timeout', settings.policy_custom_script_timeout);
      }
      if (settings.get_device_information_timeout) {
        setValue('get_device_information_timeout', settings.get_device_information_timeout);
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs />
      <form className={css.Form} onSubmit={handleFormSubmit}>
        <h5 className={css.SettingsTitle}>{t('settings.tiles.scripts_config.name')}</h5>
        <TextInput
          className={css.Input}
          type={TextInputType.INTEGER}
          label={t('settings.tiles.scripts_config.page.policy_script_timeout')}
          register={register('policy_custom_script_timeout')}
          errorText={formState.errors.policy_custom_script_timeout?.message}
          disabled={
            initRequest.loading ||
            !isAllowedTo(Permission.EditScripts) ||
            !isAllowedTo(Permission.EditAdministration)
          }
        />
        <TextInput
          className={css.Input}
          type={TextInputType.INTEGER}
          label={t('settings.tiles.scripts_config.page.get_device_information_timeout')}
          register={register('get_device_information_timeout')}
          errorText={formState.errors.get_device_information_timeout?.message}
          disabled={
            initRequest.loading ||
            !isAllowedTo(Permission.EditScripts) ||
            !isAllowedTo(Permission.EditAdministration)
          }
        />
        <div className={css.ActionBtnGroup}>
          <Button
            className={css.ActionBtn}
            type="submit"
            theme="primary"
            isLoading={updateRequest.loading}
            isDisabled={
              !isAllowedTo(Permission.EditScripts) || !isAllowedTo(Permission.EditAdministration)
            }
          >
            {t('settings.tiles.scripts_config.page.update_btn')}
          </Button>
        </div>
      </form>
    </div>
  );
};
