import { FC, useEffect, useState } from 'react';
import DataTable, { SortOrder } from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';

import css from './configuration-profile-list.module.scss';
import { Button } from '../../components/button/button.component';
import {
  ConfigProfileSimpleDto,
  ConfigProfilesSortField,
  DeviceType as ApiDeviceType,
  GetConfigProfilesDto,
  PaginationSortOrder,
  Permission
} from '../../../types/api';

import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { getConfigProfiles } from '../../../api/config-profiles';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../contexts/permission.context';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export const ConfigurationProfileList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const deviceSection = useDeviceSection();
  const deviceType =
    deviceSection.deviceType === DeviceType.COMPUTERS
      ? ApiDeviceType.Computer
      : ApiDeviceType.Mobile;

  const [configProfilesData, setConfigProfilesData] = useState<ConfigProfileSimpleDto[]>([]);
  const [configProfilesCount, setConfigProfilesCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<ConfigProfilesSortField | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);

  const columns: TableColumn<ConfigProfileSimpleDto>[] = [
    {
      name: t('profiles.table.heading.name'),
      selector: (row) => row.display_name,
      cell: (row) => {
        if (row.imported)
          return (
            <>
              {row.display_name}&nbsp;
              <FontAwesomeIcon icon={faFileImport} title={t('profiles.table.label.imported')} />
            </>
          );
        return row.display_name;
      },
      sortable: true,
      sortField: ConfigProfilesSortField.DisplayName,
      style: { cursor: 'pointer', maxWidth: '400px' }
    },
    {
      name: t('profiles.table.heading.completed'),
      selector: (row: ConfigProfileSimpleDto) => row.completed_profile_instances_count,
      sortable: true,
      sortField: ConfigProfilesSortField.CompletedProfileInstancesCount,
      style: { cursor: 'pointer' }
    },
    {
      name: t('profiles.table.heading.pending'),
      selector: (row: ConfigProfileSimpleDto) => row.pending_profile_instances_count,
      sortable: true,
      sortField: ConfigProfilesSortField.PendingProfileInstancesCount,
      style: { cursor: 'pointer' }
    },
    {
      name: t('profiles.table.heading.failed'),
      selector: (row: ConfigProfileSimpleDto) => row.failed_profile_instances_count,
      sortable: true,
      sortField: ConfigProfilesSortField.FailedProfileInstancesCount,
      style: { cursor: 'pointer' }
    },
    {
      name: t('profiles.table.heading.scope'),
      selector: (row: ConfigProfileSimpleDto) =>
        row.scoped
          ? t('policy_list.policy_table_specific_computers')
          : t('policy_list.policy_table_all_computers'),
      style: { cursor: 'pointer' }
    }
  ];

  const handleRowClicked = (row: ConfigProfileSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<ConfigProfileSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) setSortField(selectedColumn.sortField as ConfigProfilesSortField);
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    const fetchConfigProfilesData = async () => {
      const result: GetConfigProfilesDto = await getConfigProfiles({
        page: currentPage,
        limit: rowsPerPage,
        sort_field: sortField,
        sort_order: sortOrder,
        device_type: deviceType
      });

      setConfigProfilesData(result.config_profiles);
      setConfigProfilesCount(result.count);
    };

    void fetchConfigProfilesData();
  }, [currentPage, rowsPerPage, sortField, sortOrder]);

  return (
    <div id="config-profiles" className={css.ConfigProfilesContainer}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditProfiles)} theme="primary">
            {t('profiles.new_btn')}
          </Button>
        </Link>
        <Link to="import">
          <Button
            isDisabled={!isAllowedTo(Permission.EditProfiles)}
            className={css.ButtonUpload}
            theme="primary"
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            {t('profiles.upload_btn')}
          </Button>
        </Link>
      </div>
      {configProfilesData && (
        <DataTable
          columns={columns}
          data={configProfilesData}
          noDataComponent={t('common.no_records_in_table')}
          onRowClicked={handleRowClicked}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={configProfilesCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
    </div>
  );
};
