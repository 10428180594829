import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import cn from 'classnames';

import { Permission } from '../../../types/api';
import { usePermission } from '../../contexts/permission.context';
import { Paths } from '../../constants';
import css from '../../views/inventory/components/inventory-device-page/components/terminal-tab.module.scss';

type IProps = {
  to: Permission[];
};

export const RestrictedRoute: FC<IProps> = (props) => {
  const { to } = props;

  const { loading, isAllowedTo } = usePermission();

  if (loading) {
    return (
      <span
        className={cn(css.Spinner, 'spinner-border', 'spinner-border-sm')}
        role="status"
        aria-hidden="true"
      />
    );
  }

  return isAllowedTo(...to) ? <Outlet /> : <Navigate to={Paths.SIGN_IN} replace />;
};
