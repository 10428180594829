import { FC } from 'react';
import css from './general-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { formatDate } from '../../../../../../../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';
interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const GeneralTableMobile: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  return (
    <table className={css.Root}>
      <tbody>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.device_name')}
          </td>
          <td>{device?.device_information?.device_name || '---'}</td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.last_update')}
          </td>
          <td>
            {device && device.device_information
              ? new Date(device.updated_at) > new Date(device.device_information.updated_at)
                ? formatDate(device.updated_at)
                : formatDate(device.device_information.updated_at)
              : ''}
          </td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.ip_address')}
          </td>
          <td>{device?.device_information?.local_ip || '---'}</td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.is_managed')}
          </td>
          <td>{'---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.is_supervised'
            )}
          </td>
          <td>
            {' '}
            {isBoolean(device?.device_information?.is_supervised)
              ? device?.device_information?.is_supervised
                ? t('inventory_mobile.device_page.inventory_tab.general_tab.table.yes')
                : t('inventory_mobile.device_page.inventory_tab.general_tab.table.no')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.ownership_type'
            )}
          </td>
          <td>{'---'}</td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.timezone')}
          </td>
          <td>{device?.device_information?.time_zone || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.tethered_status'
            )}
          </td>
          <td>
            {isBoolean(device?.device_information?.is_network_tethered)
              ? device?.device_information?.is_network_tethered
                ? t('inventory_mobile.device_page.inventory_tab.general_tab.table.enabled')
                : t('inventory_mobile.device_page.inventory_tab.general_tab.table.disabled')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.declarative_management'
            )}
          </td>
          <td>{'---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.exchange_device_id'
            )}
          </td>
          <td>{device?.device_information?.eas_device_identifier || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.appstore_login'
            )}
          </td>
          <td>{'---'}</td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.created_at')}
          </td>
          <td>{device ? formatDate(device.created_at) : ''}</td>
        </tr>
      </tbody>
    </table>
  );
};
