import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import cn from 'classnames';
import { toast } from 'react-toastify';

import css from './role-list.module.scss';
import { usePermission } from '../../../contexts/permission.context';
import { Permission, RoleSimpleDto } from '../../../../types/api';
import { Button } from '../../../components/button/button.component';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { getRoles } from '../../../../api/roles';
import { dateSort, formatDate } from '../../../../utils/time.utils';

export const RoleListComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [roles, setRoles] = useState<RoleSimpleDto[]>([]);

  const roleColumns: TableColumn<RoleSimpleDto>[] = [
    {
      name: t('settings.tiles.roles.page.table.heading.role'),
      selector: (row: RoleSimpleDto) => row.title
    },
    {
      name: t('settings.tiles.roles.page.table.heading.created'),
      selector: (row: RoleSimpleDto) => formatDate(row.created_at),
      sortable: true,
      sortFunction: (rowA, rowB) => dateSort(rowA.created_at, rowB.created_at)
    },
    {
      name: t('settings.tiles.roles.page.table.heading.updated'),
      selector: (row: RoleSimpleDto) => formatDate(row.updated_at),
      sortable: true,
      sortFunction: (rowA, rowB) => dateSort(rowA.updated_at, rowB.updated_at)
    }
  ];

  const handleRowClicked = (row: RoleSimpleDto) => {
    navigate(row.id);
  };

  const init = async () => {
    try {
      const response = await getRoles();
      setRoles(response.roles);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditAdministration)} theme="primary">
            {t('settings.tiles.roles.page.new_btn')}
          </Button>
        </Link>
      </div>
      {roles && (
        <DataTable
          onRowClicked={handleRowClicked}
          noDataComponent={t('common.no_records_in_table')}
          columns={roleColumns}
          data={roles}
        />
      )}
    </div>
  );
};
