import cn from 'classnames';
import css from './policy-run.module.scss';
import { FC, useEffect, useState } from 'react';
import { PolicyRunFullDto, PolicyRunSimpleDto } from '../../../../../types/api';
import { WithClassname } from '../../../../../types/common';
import {
  acknowledgePolicyRun,
  cancelPolicyRun,
  getPolicyRun,
  rerunPolicyRun
} from '../../../../../api/policies';
import { Button } from '../../../../components/button/button.component';
import { PolicyRunStep } from '../policy-run-step/policy-run-step.component';
import useRequest from '../../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';

interface IProps {
  policyRun: PolicyRunSimpleDto;
  onBack?: () => unknown;
}

export const PolicyRun: FC<IProps & WithClassname> = (props) => {
  const { className, policyRun, onBack } = props;
  const [policyRunFull, setPolicyRunFull] = useState<PolicyRunFullDto>();
  const [isAcknowledged, setIsAcknowledged] = useState(policyRun.acknowledged);
  const acknowledgeRequest = useRequest<PolicyRunSimpleDto>();
  const cancelRequest = useRequest<PolicyRunSimpleDto>();
  const rerunRequest = useRequest<PolicyRunFullDto>();
  const { t } = useTranslation();

  const handleBack = () => onBack?.();
  const handleAcknowledgeClick = async () => {
    if (acknowledgeRequest.loading) return;
    await acknowledgeRequest.send(acknowledgePolicyRun(policyRun.id));
    setIsAcknowledged(true);
  };

  const handleCancelClick = async () => {
    if (cancelRequest.loading) return;
    const response = await cancelRequest.send(cancelPolicyRun(policyRun.id));
    if (policyRunFull) {
      setPolicyRunFull({
        ...policyRunFull,
        canceled: response.canceled,
        cancel_pending: response.cancel_pending
      });
    }
  };

  const handleRerunClick = async () => {
    if (rerunRequest.loading) return;
    const response = await rerunRequest.send(rerunPolicyRun(policyRun.id));
    if (policyRunFull) {
      setPolicyRunFull(response);
    }
  };

  const getDeviceDisplayName = () => {
    return policyRun.device
      ? `${policyRun.device.model_name} (${policyRun.device.serial_number})`
      : t('policies.policy_run.device_deleted');
  };

  useEffect(() => {
    const init = async () => {
      const response = await getPolicyRun(policyRun.id);
      setPolicyRunFull(response);
    };
    void init();
  }, []);

  const canBeCanceled = !policyRun.canceled && policyRun.success === null;
  const canBeRerun = !!policyRun.finished_at && policyRun.device;
  return (
    <div className={cn(css.Root, className)}>
      <div className={css.DeviceName}>{getDeviceDisplayName()}</div>
      <div className={css.TopSection}>
        <a className={css.BackLink} onClick={handleBack}>
          {t('policies.policy_run.button_back')}
        </a>
        <div>
          {!isAcknowledged && policyRun.success === false && (
            <Button
              className={css.ActionBtn}
              theme="primary"
              onClick={handleAcknowledgeClick}
              isLoading={acknowledgeRequest.loading}
            >
              {t('policies.policy_run.button_acknowledge')}
            </Button>
          )}
          {canBeCanceled && (
            <Button
              className={css.ActionBtn}
              theme="primary"
              onClick={handleCancelClick}
              isLoading={cancelRequest.loading}
            >
              {t('policies.policy_run.button_cancel')}
            </Button>
          )}
          {canBeRerun && (
            <Button
              className={css.ActionBtn}
              theme="primary"
              onClick={handleRerunClick}
              isLoading={rerunRequest.loading}
            >
              {t('policies.policy_run.button_rerun')}
            </Button>
          )}
        </div>
      </div>
      {policyRunFull &&
        policyRunFull.steps.map((step) => (
          <PolicyRunStep key={step.id} className={css.Step} step={step} policyRun={policyRunFull} />
        ))}
    </div>
  );
};
