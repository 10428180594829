import { FC } from 'react';
import css from './management-tab-content.module.scss';
import { CommandsTab } from './components/commands-tab/commands-tab.component';
import { DeviceFullDto, Permission } from '../../../../../../../../../types/api';
import { ManagementTabTabs } from '../../../../../../constants';
import { ActivationTab } from './components/activation-tab/activation-tab.component';
import { GroupsTab } from './components/groups-tab/groups-tab.component';
import { PoliciesTab } from './components/policies-tab/policies-tab.component';
import { ProfilesTab } from './components/profiles-tab/profiles-tab.component';
import { Restricted } from '../../../../../../../../components/common/restricted';

interface IProps {
  openedTab: string;
  device?: DeviceFullDto;
}

export const ManagementTabContent: FC<IProps> = (props: IProps) => {
  const { openedTab, device } = props;
  return (
    <div className={css.ManagementTabContent}>
      <Restricted to={[Permission.ReadCommands, Permission.EditCommands]}>
        {openedTab === ManagementTabTabs.COMMANDS && <CommandsTab device={device} />}
      </Restricted>
      {openedTab === ManagementTabTabs.GROUPS && <GroupsTab device={device} />}
      {openedTab === ManagementTabTabs.ACTIVATION && <ActivationTab device={device} />}
      {openedTab === ManagementTabTabs.POLICIES && <PoliciesTab device={device} />}
      {openedTab === ManagementTabTabs.PROFILES && <ProfilesTab device={device} />}
    </div>
  );
};
