import { FC } from 'react';
import { TextInput } from '../../../components/text-input/text-input.component';
import css from './settings-license.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/button/button.component';
import { UseFormReturn } from 'react-hook-form';
import { UploadLicenseForm } from './settings-license.schema';
import { DeviceOsType, LicenseDto, LicenseStatus, Permission } from '../../../../types/api';
import { usePermission } from '../../../contexts/permission.context';
import { WithClassname } from '../../../../types/common';
import { FileInput } from '../../../components/file-input/file-input.component';

interface IProps {
  licenseOSTab: DeviceOsType;
  expirationDate: string;
  activationPeriod: number;
  totalDevicesEnrolled: number;
  license: LicenseDto | null;
  form: UseFormReturn<UploadLicenseForm>;
  onSubmit: () => void;
}

export const LicenseTab: FC<IProps & WithClassname> = (props) => {
  const {
    licenseOSTab,
    expirationDate,
    activationPeriod,
    totalDevicesEnrolled,
    license,
    form,
    onSubmit
  } = props;
  const { register } = form;

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  return (
    <div>
      <div className={css.Header}>
        {licenseOSTab === DeviceOsType.MacOS ? (
          <h5>{t('settings.tiles.license.page.title.macos_license_settings')}</h5>
        ) : (
          <h5>{t('settings.tiles.license.page.title.ios_license_settings')}</h5>
        )}
      </div>
      <div className={css.Content}>
        {license?.status === LicenseStatus.Active && (
          <>
            <TextInput
              className={css.Textarea}
              label={t('settings.tiles.license.page.code')}
              value={license.license_code}
              disabled={!!license?.license_code}
              textarea
              required
            />
            <p>
              {t('settings.tiles.license.page.expired_date', {
                date: expirationDate
              })}
              {'. '}
              {t('settings.tiles.license.page.days_left', {
                num_of_days: activationPeriod
              })}
            </p>
            {license?.max_device && totalDevicesEnrolled <= license.max_device && (
              <p>
                {t('settings.tiles.license.page.computers_enrolled', {
                  computers_enrolled: String(totalDevicesEnrolled)
                })}
                {'. '}
                {t('settings.tiles.license.page.computers_remainder', {
                  num_of_computers: license?.max_device
                    ? String(license.max_device - totalDevicesEnrolled)
                    : 0
                })}
                {license?.max_device === totalDevicesEnrolled && (
                  <p className={css.Error}>
                    {t('settings.tiles.license.page.max_num_of_devices')}{' '}
                  </p>
                )}
              </p>
            )}
            {license?.max_device && totalDevicesEnrolled > license.max_device && (
              <p>
                {t('settings.tiles.organization_information.page.max_num_of_devices_in_license')}{' '}
                {license?.max_device}
                {'. '}
                {t('settings.tiles.license.page.computers_enrolled', {
                  computers_enrolled: String(totalDevicesEnrolled)
                })}
                <p className={css.Error}>
                  {t('settings.tiles.license.page.number_of_devices_is_more_than_available', {
                    num: String(totalDevicesEnrolled - license?.max_device)
                  })}
                </p>
              </p>
            )}
          </>
        )}
        {license?.status === LicenseStatus.Expired && (
          <>
            <TextInput
              className={css.Textarea}
              label={t('settings.tiles.license.page.code')}
              value={license.license_code}
              disabled={!!license?.license_code}
              required
            />
            <p className={css.Error}>
              {t('settings.tiles.license.page.license_expire')}{' '}
              <a className={css.Email} href="mailto:support@ringomdm.ru?subject=LicenseExpired">
                support@ringomdm.ru
              </a>
            </p>
            <p>
              {t('settings.tiles.license.page.expired_date', {
                date: expirationDate
              })}
            </p>
            <p>
              {t('settings.tiles.license.page.computers_enrolled', {
                computers_enrolled: String(totalDevicesEnrolled)
              })}
            </p>
          </>
        )}
        <form className={css.Root} onSubmit={onSubmit}>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
            <FileInput
              label={t('settings.tiles.license.page.file_input')}
              accept=".pem"
              errorText={form.formState.errors.license?.message}
              register={register('license')}
            />
            <div className={css.Actions}>
              <Button theme="primary" type="submit">
                {t('settings.tiles.license.page.activate')}
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
