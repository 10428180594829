import { FC } from 'react';
import css from './inventory-tab-content.module.scss';
import { GeneralTable } from './components/general-table/general-table.component';
import { HardwareTable } from './components/hardware-table/hardware-table.component';
import { OsInfoTable } from './components/os-info-table/os-info-table.component';
import { InventoryTabMobileTabs, InventoryTabTabs } from '../../../../../../constants';
import { DeviceFullDto } from '../../../../../../../../../types/api';
import { SecurityTable } from './components/security-table/security-table.component';
import { StorageTable } from './components/storage-table/storage-table.component';
import { EncryptionTable } from './components/encryption-table/encryption-table.component';
import { ApplicationsTable } from './components/applications-table/applications-table.component';
import { ProfilesTable } from './components/profiles-table/profiles-table.component';
import { UserAccountsTable } from './components/user-accounts-table/user-accounts-table.component';
import { ServicesTable } from './components/services-table/services-table.component';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../contexts/device-section.context';
import { CertificatesTableMobile } from './components/certificates-table-mobile/certificates-table-mobile.component';
import { GeneralTableMobile } from './components/general-table-mobile/general-table-mobile.component';
import { OsInfoTableMobile } from './components/os-info-table-mobile/os-info-table-mobile.component';
import { SecurityTableMobile } from './components/security-table-mobile/security-table-mobile.component';
import { ProvisioningProfilesTable } from './components/provisioning-profiles-table-mobile/provisioning-profiles-table-mobile.component';
import { HardwareTableMobile } from './components/hardware-table-mobile/hardware-table-mobile.component';
import { ExtensionAttributesTable } from './components/extension-attributes-table/extension-attributes-table.component';
import { DeviceUser } from './components/device-user/device-user.component';
interface IProps {
  openedTab: string;
  device?: DeviceFullDto;
}

export const InventoryTabContent: FC<IProps> = (props: IProps) => {
  const { openedTab, device } = props;
  const { deviceType } = useDeviceSection();

  return (
    <div className={css.InventoryTabContent}>
      {deviceType === DeviceType.COMPUTERS ? (
        <>
          <GeneralTable device={device} isOpened={openedTab === InventoryTabTabs.GENERAL} />
          <HardwareTable device={device} isOpened={openedTab === InventoryTabTabs.HARDWARE} />
          <OsInfoTable device={device} isOpened={openedTab === InventoryTabTabs.OS_INFO} />
          <SecurityTable device={device} isOpened={openedTab === InventoryTabTabs.SECURITY} />
          <StorageTable device={device} isOpened={openedTab === InventoryTabTabs.STORAGE} />
          <EncryptionTable device={device} isOpened={openedTab === InventoryTabTabs.ENCRYPTION} />
          <ApplicationsTable
            device={device}
            isOpened={openedTab === InventoryTabTabs.APPLICATIONS}
          />
          <ProfilesTable device={device} isOpened={openedTab === InventoryTabTabs.PROFILES} />
          <UserAccountsTable
            device={device}
            isOpened={openedTab === InventoryTabTabs.USER_ACCOUNTS}
          />
          <ServicesTable device={device} isOpened={openedTab === InventoryTabTabs.SERVICES} />
          <ExtensionAttributesTable
            device={device}
            isOpened={openedTab === InventoryTabTabs.EXTENSION_ATTRIBUTES}
          />
        </>
      ) : (
        <>
          <GeneralTableMobile
            device={device}
            isOpened={openedTab === InventoryTabMobileTabs.GENERAL}
          />
          <OsInfoTableMobile
            device={device}
            isOpened={openedTab === InventoryTabMobileTabs.OS_INFO}
          />
          <HardwareTableMobile
            device={device}
            isOpened={openedTab === InventoryTabMobileTabs.HARDWARE}
          />
          <SecurityTableMobile
            device={device}
            isOpened={openedTab === InventoryTabMobileTabs.SECURITY}
          />
          <ApplicationsTable
            device={device}
            isOpened={openedTab === InventoryTabMobileTabs.APPLICATIONS}
          />
          <ProfilesTable device={device} isOpened={openedTab === InventoryTabMobileTabs.PROFILES} />
          <ProvisioningProfilesTable
            device={device}
            isOpened={openedTab === InventoryTabMobileTabs.PROVISIONING_PROFILES}
          />
          <CertificatesTableMobile
            device={device}
            isOpened={openedTab === InventoryTabMobileTabs.CERTIFICATES}
          />
        </>
      )}
      <DeviceUser device={device} isOpened={openedTab === InventoryTabTabs.DEVICE_USER} />
    </div>
  );
};
