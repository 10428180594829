import { FC } from 'react';
import css from './certificates-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const CertificatesTableMobile: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  return (
    <div className={css.Root}>
      <table className={css.CertificatesTable}>
        <thead>
          <tr>
            <th>
              {t('inventory_mobile.device_page.inventory_tab.certificates_tab.table.heading.name')}
            </th>
            <th>
              {t(
                'inventory_mobile.device_page.inventory_tab.certificates_tab.table.heading.is_identity'
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {device?.certificates?.map((i) => (
            <tr key={i.id}>
              <td title={i.common_name ? i.common_name : ''}>{i.common_name}</td>
              <td>
                {isBoolean(i.is_identity)
                  ? i.is_identity
                    ? t('inventory_mobile.device_page.inventory_tab.certificates_tab.table.yes')
                    : t('inventory_mobile.device_page.inventory_tab.certificates_tab.table.no')
                  : '---'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
