import { FC } from 'react';
import css from './hardware-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const HardwareTableMobile: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <table className={css.Root}>
      <tbody>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.capacity')}
          </td>
          <td>
            {di?.device_capacity
              ? `${di.device_capacity} ${t(
                  'inventory_mobile.device_page.inventory_tab.hardware_tab.table.gb'
                )}`
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.available_space'
            )}
          </td>
          <td>
            {di?.available_device_capacity
              ? `${di.available_device_capacity} ${t(
                  'inventory_mobile.device_page.inventory_tab.hardware_tab.table.gb'
                )} ${
                  di?.device_capacity
                    ? `(${Math.round(
                        100 * (di?.available_device_capacity / di?.device_capacity)
                      )}%)`
                    : ''
                }`
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.used_space')}
          </td>
          <td>
            {di?.used_space && di?.device_capacity
              ? `${di?.used_space} ${t(
                  'inventory_mobile.device_page.inventory_tab.hardware_tab.table.gb'
                )} ${`(${Math.round(100 * (di?.used_space / di?.device_capacity))}%)`}`
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.battery_level'
            )}
          </td>
          <td>{`${Math.round(Number(di?.battery_level) * 100)}%` || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.serial_number'
            )}
          </td>
          <td>{device?.serial_number || '---'}</td>
        </tr>
        <tr>
          <td>{t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.id')}</td>
          <td>{device?.id || '---'}</td>
        </tr>
        <tr>
          <td>{t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.udid')}</td>
          <td>{device?.udid || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.wifi_mac_address'
            )}
          </td>
          <td>{di?.wifi_mac || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.bluetooth_mac_address'
            )}
          </td>
          <td>{di?.bluetooth_mac || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.modem_firmware_version'
            )}
          </td>
          <td>{di?.modem_firmware_version || '---'}</td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.model')}
          </td>
          <td>{di?.model || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.model_identifier'
            )}
          </td>
          <td>{di?.model_identifier || di?.software_update_device_id || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.model_number'
            )}
          </td>
          <td>{di?.model_number || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.bluetooth_low_energy_capability'
            )}
          </td>
          <td>{'---'}</td>
        </tr>
      </tbody>
    </table>
  );
};
