import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import css from './device-table.module.scss';
import DataTable, { SortOrder } from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import {
  DeviceOsType,
  DeviceSimpleDto,
  DevicesSortField,
  GetDevicesResponseDto,
  PaginationSortOrder,
  SearchDeviceByConditionDto
} from '../../../../../types/api';
import { StaticGroupFormModes } from '../../static-group-devices.component';
import { formatDate } from '../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../const/pagination.const';
import { getDevices, searchDevices } from '../../../../../api/device';
import { useDebounce } from 'use-debounce';
import useDeviceSection, { DeviceType } from '../../../../contexts/device-section.context';
import { TextInput, TextInputType } from '../../../../components/text-input/text-input.component';
import { Button } from '../../../../components/button/button.component';

interface IProps {
  mode: StaticGroupFormModes;
  initialSelectedDevices: string[];
  updateInitialSelectedDevices: (devices: string[]) => void;
  selectedDevices: string[];
  updateSelectedDevices: (devices: string[]) => void;
}

export const DeviceTable: FC<IProps> = (props: IProps) => {
  const {
    mode,
    initialSelectedDevices,
    updateInitialSelectedDevices,
    selectedDevices,
    updateSelectedDevices
  } = props;

  const [deviceData, setDeviceData] = useState<DeviceSimpleDto[]>([]);
  const [devicesCount, setDevicesCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<DevicesSortField | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState<string>('');
  const [debouncedFilterText] = useDebounce(filterText, 1000);

  // Workaround: react-data-table fires "onSelectedRowsChange" with 0 rows selected
  // before component mount in reqct strict mode. Initialized state solves the problem
  const [isInitialized, setIsInitialized] = useState(false);

  const { t } = useTranslation();

  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  useEffect(() => {
    setIsInitialized(true);

    return () => {
      if (isInitialized) {
        updateInitialSelectedDevices(selectedDevices);
      }
    };
  }, []);

  const columns: TableColumn<DeviceSimpleDto>[] = [
    {
      name: t('static_groups.page.assignments_tab.heading.name'),
      selector: (row) =>
        row.device_information?.device_name
          ? row.device_information?.device_name
          : row.model_name
          ? row.model_name
          : '',
      sortable: true,
      sortField: DevicesSortField.DisplayName
    },
    {
      name: t('static_groups.page.assignments_tab.heading.os'),
      selector: (row: DeviceSimpleDto) =>
        row.device_information && row.device_information.os_version
          ? row.device_information.os_version
          : '',
      sortable: true,
      sortField: DevicesSortField.OsVersion
    },
    {
      name: t('static_groups.page.assignments_tab.heading.serial'),
      selector: (row: DeviceSimpleDto) => (row.serial_number ? row.serial_number : ''),
      sortable: true,
      sortField: DevicesSortField.SerialNumber
    },
    {
      name: t('static_groups.page.assignments_tab.heading.last_check_in'),
      selector: (row: DeviceSimpleDto) => formatDate(row.last_seen),
      sortable: true,
      sortField: DevicesSortField.LastSeen
    }
  ];

  const handleChangePage = async (value: number) => {
    updateInitialSelectedDevices(selectedDevices);
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<DeviceSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as DevicesSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  const getDevicesInfo = async () => {
    let response: GetDevicesResponseDto;

    if (debouncedFilterText) {
      const conditions: SearchDeviceByConditionDto[] = [
        {
          criteria: 'ModelName',
          operator: 'like',
          value: debouncedFilterText
        },
        {
          conjunctive: 'or',
          criteria: 'General.ComputerName',
          operator: 'like',
          value: debouncedFilterText
        },
        {
          conjunctive: 'or',
          criteria: 'OS.ProductVersion',
          operator: 'like',
          value: debouncedFilterText
        },
        {
          conjunctive: 'or',
          criteria: 'Hardware.SerialNumber',
          operator: 'like',
          value: debouncedFilterText
        }
      ];

      response = await searchDevices({
        conditions,
        page: currentPage,
        limit: rowsPerPage,
        sort_field: sortField,
        sort_order: sortOrder,
        os_type: deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS
      });
    } else {
      response = await getDevices({
        page: currentPage,
        limit: rowsPerPage,
        sort_field: sortField,
        sort_order: sortOrder,
        os_type: osType
      });
    }

    setDeviceData(response.devices);
    setDevicesCount(response.count);
  };

  useEffect(() => {
    getDevicesInfo();
  }, [rowsPerPage, sortOrder, sortField, currentPage, debouncedFilterText]);

  const isCheckboxDisabled = () => {
    return mode === StaticGroupFormModes.VIEW;
  };

  const handleSelected = useMemo(() => {
    return (selected: {
      allSelected: boolean;
      selectedCount: number;
      selectedRows: DeviceSimpleDto[];
    }) => {
      const selectedDevicesIds = selected.selectedRows.map((device) => device.id);

      const unselectedDevicesIds = deviceData
        .map((device) => device.id)
        .filter((id) => !selectedDevicesIds.includes(id));
      const oldSelectedDevices = selectedDevices;
      const oldAndNewDevices = oldSelectedDevices
        ? selectedDevicesIds.concat(oldSelectedDevices)
        : [];
      const oldAndNewDevicesFiltered = oldAndNewDevices.filter(
        (id, pos) => oldAndNewDevices.indexOf(id) === pos
      );
      const assignments = oldAndNewDevicesFiltered.filter(
        (id) => !unselectedDevicesIds.includes(id)
      );

      updateSelectedDevices(assignments);
      updateInitialSelectedDevices(assignments);
    };
  }, [selectedDevices, deviceData]);

  const isDeviceEnabled = useMemo(() => {
    return (device: DeviceSimpleDto) => {
      return initialSelectedDevices?.includes(device.id);
    };
  }, []);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText('');
        updateInitialSelectedDevices(selectedDevices);
      }
    };

    const handleUpdateFilterText = async (e: ChangeEvent) => {
      updateInitialSelectedDevices(selectedDevices);
      return e.target ? setFilterText((e.target as HTMLInputElement).value) : null;
    };

    return (
      <div className={css.Filter}>
        <TextInput
          className={css.FilterInput}
          type={TextInputType.TEXT}
          value={filterText}
          onChange={handleUpdateFilterText}
          placeholder={t('static_groups.page.assignments_tab.filter.message.devices')}
        />
        <Button className={css.FilterClearButton} onClick={handleClear}>
          {t('static_groups.page.assignments_tab.filter.clear_btn')}
        </Button>
      </div>
    );
  }, [filterText]);

  return (
    <div className={css.Root}>
      <DataTable
        keyField={'id'}
        columns={columns}
        data={deviceData}
        sortServer
        onSort={handleSort}
        pagination
        paginationServer
        paginationDefaultPage={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={devicesCount}
        paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
        selectableRows
        selectableRowDisabled={isCheckboxDisabled}
        selectableRowSelected={isDeviceEnabled}
        onSelectedRowsChange={handleSelected}
        subHeader
        subHeaderComponent={subHeaderComponent}
        paginationComponentOptions={{
          rowsPerPageText: t('common.table.rows_per_page'),
          rangeSeparatorText: t('common.table.of')
        }}
        paginationServerOptions={{
          persistSelectedOnSort: true,
          persistSelectedOnPageChange: true
        }}
        noDataComponent={t('common.no_records_in_table')}
      />
    </div>
  );
};
