import { FC } from 'react';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../../../types/api';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const ExtensionAttributesTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;

  if (!isOpened) {
    return <div />;
  }

  return (
    <div>
      <DeviceExtensionAttributes
        device={device}
        displayHeading={false}
        inventorySection={ExtensionAttributeInventorySection.ExtensionAttributes}
      ></DeviceExtensionAttributes>
    </div>
  );
};
