import { useState } from 'react';

const useRequest = <T = unknown>() => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const send = async (promise: Promise<T>): Promise<T> => {
    setLoading(true);
    try {
      const response = await promise;
      setData(response);
      setLoading(false);
      setError(null);
      return response;
    } catch (e) {
      setError(e);
      setLoading(false);
      throw e;
    }
  };

  return { send, data, loading, error };
};

export default useRequest;
