import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import css from './device-users-table.module.scss';
import DataTable, { SortOrder } from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import {
  DeviceUserSimpleDto,
  DeviceUserSortField,
  GetDeviceUserListRequestDto,
  PaginationSortOrder,
  SearchDeviceUserByConditionDto
} from '../../../../../types/api';
import { StaticGroupFormModes } from '../../static-group-device-users.component';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../const/pagination.const';
import { useDebounce } from 'use-debounce';
import { TextInput, TextInputType } from '../../../../components/text-input/text-input.component';
import { Button } from '../../../../components/button/button.component';
import { getDeviceUserList } from '../../../../../api/device-users';
import { WithClassname } from '../../../../../types/common';
import cn from 'classnames';
import { getDeviceUsersForStaticGroup } from '../../../../../api/static-groups-device-users';
import { useParams } from 'react-router-dom';

interface IProps {
  mode: StaticGroupFormModes;
  selectedDeviceUserIds: string[];
  onSelectDeviceUsers: (ids: string[]) => void;
}

export const DeviceUsersTable: FC<IProps & WithClassname> = (props) => {
  const { className, mode, selectedDeviceUserIds, onSelectDeviceUsers } = props;

  const params = useParams();
  const [tableDeviceUsers, setTableDeviceUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [tableCount, setTableCount] = useState(0);
  const [tableSelectedIds, setTableSelectedIds] = useState(selectedDeviceUserIds || []);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<DeviceUserSortField | undefined>(
    DeviceUserSortField.Username
  );
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState<string>('');
  const isInitialized = useRef(false);
  const [debouncedFilterText] = useDebounce(filterText, 1000);
  const [condition, setCondition] = useState<SearchDeviceUserByConditionDto[]>([]);

  const { t } = useTranslation();

  const getDevicesInfo = async (request: Partial<GetDeviceUserListRequestDto> = {}) => {
    if (mode === StaticGroupFormModes.VIEW) {
      if (!params.id) return;
      const response = await getDeviceUsersForStaticGroup(params.id, {
        page: request.page || currentPage,
        limit: request.limit || rowsPerPage,
        sort_field: request.sort_field || sortField,
        sort_order: request.sort_order || sortOrder
      });
      setTableDeviceUsers(response.users);
      setTableCount(response.count);
    } else {
      const conditions = request.conditions || condition;
      const response = await getDeviceUserList({
        conditions,
        page: request.page || currentPage,
        limit: request.limit || rowsPerPage,
        sort_field: request.sort_field || sortField,
        sort_order: request.sort_order || sortOrder
      });

      setTableDeviceUsers(response.users);
      setTableCount(response.count);
    }
  };

  const handleChangePage = async (value: number) => {
    if (!isInitialized.current) return;
    setCurrentPage(value);
    await getDevicesInfo({ page: value });
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (!isInitialized.current) return;
    setRowsPerPage(value);
    const maxPossiblePage = Math.ceil(tableCount / value);
    const changedPage = value > rowsPerPage ? Math.max(currentPage - 1, 1) : currentPage;
    setCurrentPage(Math.min(maxPossiblePage, changedPage));
    await getDevicesInfo({
      limit: value,
      page: Math.min(maxPossiblePage, changedPage)
    });
  };

  const handleSort = async (
    selectedColumn: TableColumn<DeviceUserSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (!isInitialized.current) return;
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as DeviceUserSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
    await getDevicesInfo({
      page: 1,
      sort_field: selectedColumn.sortField as DeviceUserSortField,
      sort_order: sortDirection as string as PaginationSortOrder
    });
  };

  const handleClearFilter = () => {
    if (filterText) setFilterText('');
  };

  const handleUpdateFilter = async (e: ChangeEvent) => {
    return e.target ? setFilterText((e.target as HTMLInputElement).value) : null;
  };

  useEffect(() => {
    void getDevicesInfo({ page: 1 }).then(() => {
      setCurrentPage(1);
      isInitialized.current = true;
    });
  }, [mode]);

  useEffect(() => {
    if (debouncedFilterText && mode === StaticGroupFormModes.VIEW) return;
    if (!isInitialized.current) return;
    if (debouncedFilterText) {
      const conditions: SearchDeviceUserByConditionDto[] = [
        {
          criteria: 'username',
          value: debouncedFilterText
        }
      ];
      setCondition(conditions);
      void getDevicesInfo({ conditions: conditions, page: 1 }).then(() => {
        setCurrentPage(1);
      });
    } else {
      setCondition([]);
      void getDevicesInfo({ page: 1 }).then(() => {
        setCurrentPage(1);
      });
    }
  }, [debouncedFilterText]);

  useEffect(() => {
    setTableSelectedIds(selectedDeviceUserIds);
  }, [selectedDeviceUserIds]);

  const handleSelectRow = (row: DeviceUserSimpleDto) => {
    const isFound = new Set(tableSelectedIds).has(row.id);
    let result: string[];
    if (isFound) {
      result = tableSelectedIds.filter((id) => id !== row.id);
    } else {
      result = [...tableSelectedIds, row.id];
    }
    setTableSelectedIds(result);
    onSelectDeviceUsers(result);
  };

  const isCheckboxDisabled = () => mode === StaticGroupFormModes.VIEW;
  const isDeviceSelected = (row: DeviceUserSimpleDto) => {
    return new Set(tableSelectedIds).has(row.id);
  };

  const columns: TableColumn<DeviceUserSimpleDto>[] = [
    {
      cell: (row) => (
        <label
          className={cn(css.SelectCellLabel, isCheckboxDisabled() && css.SelectCellLabelDisabled)}
        >
          <input
            className={css.SelectCellInput}
            type="checkbox"
            disabled={isCheckboxDisabled()}
            checked={isDeviceSelected(row)}
            onChange={() => handleSelectRow(row)}
          />
        </label>
      ),
      width: '30px',
      style: { padding: 0, cursor: 'pointer' }
    },
    {
      name: t('static_groups.page.device_users_assignments_tab.column_username'),
      selector: (row) => row.username,
      sortable: true,
      sortField: DeviceUserSortField.Username
    }
  ];

  return (
    <div className={cn(css.Root, className)}>
      {mode !== StaticGroupFormModes.VIEW && (
        <div className={css.Filter}>
          <TextInput
            className={css.FilterInput}
            type={TextInputType.TEXT}
            value={filterText}
            onChange={handleUpdateFilter}
            placeholder={t('static_groups.page.assignments_tab.filter.message.device_users')}
          />
          <Button className={css.FilterClearButton} onClick={handleClearFilter}>
            {t('static_groups.page.assignments_tab.filter.clear_btn')}
          </Button>
        </div>
      )}
      <DataTable
        keyField={'id'}
        columns={columns}
        data={tableDeviceUsers}
        sortServer
        onSort={handleSort}
        pagination
        paginationServer
        paginationDefaultPage={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={tableCount}
        paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
        paginationComponentOptions={{
          rowsPerPageText: t('common.table.rows_per_page'),
          rangeSeparatorText: t('common.table.of')
        }}
        paginationServerOptions={{
          persistSelectedOnSort: true,
          persistSelectedOnPageChange: true
        }}
        noDataComponent={t('common.no_records_in_table')}
      />
    </div>
  );
};
