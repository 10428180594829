import { FC, useEffect, useState } from 'react';
import DataTable, { SortOrder } from 'react-data-table-component';
import css from './smart-group-device-users-list.module.scss';

import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import {
  GetSmartGroupsDeviceUsersResponseDto,
  PaginationSortOrder,
  Permission,
  SmartGroupDeviceUsersSimpleDto,
  SmartGroupDeviceUsersSortField
} from '../../../types/api';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/button/button.component';
import { formatDate } from '../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../contexts/permission.context';
import { getSmartGroups } from '../../../api/smart-group-device-users';

export const SmartGroupDeviceUsersListComponent: FC = () => {
  const navigate = useNavigate();

  const [smartGroupsData, setSmartGroupsData] = useState<SmartGroupDeviceUsersSimpleDto[]>([]);
  const [smartGroupsCount, setSmartGroupsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<SmartGroupDeviceUsersSortField | undefined>(
    SmartGroupDeviceUsersSortField.Title
  );
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const columns: TableColumn<SmartGroupDeviceUsersSimpleDto>[] = [
    {
      name: t('smart_groups.table.heading.name'),
      selector: (row) => (row.title ? row.title : ''),
      sortField: SmartGroupDeviceUsersSortField.Title,
      sortable: true,
      maxWidth: '240px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('smart_groups.table.heading.count'),
      selector: (row) => (row.device_users_count ? row.device_users_count : 0),
      sortable: true,
      sortField: SmartGroupDeviceUsersSortField.DeviceUsersCount,
      style: { cursor: 'pointer' }
    },
    {
      name: t('smart_groups.table.heading.created'),
      selector: (row) => formatDate(row.created_at),
      sortable: true,
      sortField: SmartGroupDeviceUsersSortField.CreatedAt,
      style: { cursor: 'pointer' }
    },
    {
      name: t('smart_groups.table.heading.updated'),
      selector: (row) => formatDate(row.updated_at),
      sortable: true,
      sortField: SmartGroupDeviceUsersSortField.UpdatedAt,
      style: { cursor: 'pointer' }
    }
  ];

  const handleRowClicked = (row: SmartGroupDeviceUsersSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<SmartGroupDeviceUsersSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as SmartGroupDeviceUsersSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    const fetchSmartGroupsData = async () => {
      const result: GetSmartGroupsDeviceUsersResponseDto = await getSmartGroups({
        sort_field: sortField,
        sort_order: sortOrder,
        page: currentPage,
        limit: rowsPerPage
      });
      const smartGroups: SmartGroupDeviceUsersSimpleDto[] = result.groups;
      setSmartGroupsData(smartGroups);
      setSmartGroupsCount(result.count);
    };

    void fetchSmartGroupsData();
  }, [sortOrder, sortField, currentPage, rowsPerPage]);

  return (
    <div id="smart-groups" className={css.SmartGroupsContainer}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditGroups)} theme="primary">
            {t('smart_groups.new_btn')}
          </Button>
        </Link>
      </div>
      {smartGroupsData && (
        <DataTable
          columns={columns}
          data={smartGroupsData}
          onRowClicked={handleRowClicked}
          noDataComponent={t('common.no_records_in_table')}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={smartGroupsCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
    </div>
  );
};
