import { FC, FormEvent, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import css from './extension-attribute-form.module.scss';
import {
  ExtensionAttributeDataType,
  ExtensionAttributeInputType,
  Permission
} from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { TextInput } from '../../../../../components/text-input/text-input.component';
import {
  dataTypeOptions,
  extensionAttributeFormSchema,
  ExtensionAttributeFormValues,
  inputTypeOptions,
  inventorySectionOptions,
  ISelectOption
} from './extension-attribute-form.schema';
import { Button } from '../../../../../components/button/button.component';
import { Modal } from '../../../../../components/modal/modal.component';
import { filterChangedFormFields } from '../../../../../../utils/react-hook-form.utils';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { Dropdown } from '../../../../../components/dropdown/dropdown.component';
import { TextInputType } from '../../../../../components/text-input/text-input.component';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: ExtensionAttributeFormValues;
  onCreate?: (values: ExtensionAttributeFormValues) => void;
  onEdit?: () => void;
  onSave?: (values: Partial<ExtensionAttributeFormValues>) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

export const ExtensionAttributeFormComponent: FC<IProps> = (props) => {
  const { mode, values, onCreate, onEdit, onSave, onCancel, onDelete } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [inputType, setInputType] = useState<ExtensionAttributeInputType>(
    values?.input_type || ExtensionAttributeInputType.Text
  );

  const extensionAttributeForm = useForm<ExtensionAttributeFormValues>({
    mode: 'onChange',
    resolver: yupResolver(extensionAttributeFormSchema),
    defaultValues: values || extensionAttributeFormSchema.getDefault(),
    values
  });

  const { fields, append, remove } = useFieldArray({
    control: extensionAttributeForm.control,
    name: 'options'
  });

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    const isValid = await extensionAttributeForm.trigger();
    if (!isValid) return;

    onCreate?.(extensionAttributeForm.getValues());
  };

  const handleEdit = () => onEdit?.();

  const handleSave = async () => {
    const isValid = await extensionAttributeForm.trigger();
    if (!isValid) return;

    const changedValues = filterChangedFormFields(
      extensionAttributeForm.getValues(),
      extensionAttributeForm.formState.dirtyFields
    );

    onSave?.(changedValues);
  };

  const handleCancel = async () => {
    extensionAttributeForm.reset();
    onCancel?.();
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const handleDeleteOption = (index: number) => () => {
    remove(index);
  };

  const handleAddOption = () => {
    append({ value: '' });
  };

  const columns: TableColumn<ISelectOption>[] = [
    {
      name: t('settings.tiles.extension_attributes.page.extension_attribute_page.select.options'),
      cell: (row, index) => (
        <TextInput
          className={css.CellTextInput}
          required
          register={extensionAttributeForm.register(`options.${index}.value`)}
          disabled={mode === 'view'}
          type={
            extensionAttributeForm.getValues().data_type === ExtensionAttributeDataType.Date
              ? TextInputType.DATE_TIME
              : extensionAttributeForm.getValues().data_type === ExtensionAttributeDataType.Number
              ? TextInputType.NUMBER_STRING
              : TextInputType.TEXT
          }
        />
      )
    },
    {
      cell: (row, index) =>
        mode !== 'view' && (
          <Button type="button" theme="danger" onClick={handleDeleteOption(index)}>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.delete_btn')}
          </Button>
        ),
      style: { justifyContent: 'end' }
    }
  ];

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditDevices)}>
        <h5 className={css.Title}>
          {mode === 'create'
            ? t('settings.tiles.extension_attributes.page.extension_attribute_page.new_title')
            : values?.display_name || ''}
        </h5>
        <div className={css.Content}>
          <TextInput
            label={t(
              'settings.tiles.extension_attributes.page.extension_attribute_page.display_name'
            )}
            required
            errorText={extensionAttributeForm.formState.errors.display_name?.message}
            register={extensionAttributeForm.register('display_name')}
            disabled={mode === 'view'}
          />
          <TextInput
            className={css.Textarea}
            textarea
            label={t(
              'settings.tiles.extension_attributes.page.extension_attribute_page.description'
            )}
            errorText={extensionAttributeForm.formState.errors.description?.message}
            register={extensionAttributeForm.register('description')}
            disabled={mode === 'view'}
          />
          <Dropdown
            label={t('settings.tiles.extension_attributes.page.extension_attribute_page.data_type')}
            options={dataTypeOptions}
            disabled={mode === 'view'}
            register={extensionAttributeForm.register('data_type')}
          />
          <Dropdown
            label={t(
              'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display'
            )}
            options={inventorySectionOptions}
            disabled={mode === 'view'}
            register={extensionAttributeForm.register('inventory_display')}
          />
          <Dropdown
            label={t(
              'settings.tiles.extension_attributes.page.extension_attribute_page.input_type'
            )}
            options={inputTypeOptions}
            disabled={mode === 'view'}
            register={extensionAttributeForm.register('input_type', {
              onChange: (e) => setInputType(e.target.value)
            })}
          />
          {inputType === ExtensionAttributeInputType.Script && (
            <TextInput
              className={cn(css.Textarea, css.Code)}
              label={t('settings.tiles.extension_attributes.page.extension_attribute_page.script')}
              textarea
              errorText={extensionAttributeForm.formState.errors.script?.message}
              register={extensionAttributeForm.register('script')}
              disabled={mode === 'view'}
            />
          )}
          {inputType === ExtensionAttributeInputType.Select && (
            <>
              {mode !== 'view' && (
                <Button theme="primary" onClick={handleAddOption}>
                  {t('settings.tiles.extension_attributes.page.extension_attribute_page.add_btn')}
                </Button>
              )}
              <DataTable
                columns={columns}
                data={fields}
                noDataComponent={t('common.no_records_in_table')}
              />
            </>
          )}
        </div>
        <div className={css.ActionButtons}>
          {mode === 'create' && (
            <Button className={css.ActionBtn} theme="primary" onClick={handleCreate}>
              {t('settings.tiles.extension_attributes.page.extension_attribute_page.create_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <>
              <Button className={css.ActionBtn} theme="primary" onClick={handleEdit}>
                {t('settings.tiles.extension_attributes.page.extension_attribute_page.edit_btn')}
              </Button>
              <Button className={css.ActionBtn} theme="danger" onClick={handleDeleteModalOpen}>
                {t('settings.tiles.extension_attributes.page.extension_attribute_page.delete_btn')}
              </Button>
            </>
          )}

          {mode === 'edit' && (
            <>
              <Button className={css.ActionBtn} theme="primary" onClick={handleSave}>
                {t('settings.tiles.extension_attributes.page.extension_attribute_page.save_btn')}
              </Button>
              <Button className={css.ActionBtn} theme="danger" onClick={handleCancel}>
                {t('settings.tiles.extension_attributes.page.extension_attribute_page.cancel_btn')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
      <Modal
        title={t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.modal_delete.title'
        )}
        submitButtonName={t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.modal_delete.delete_btn'
        )}
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
      >
        <p>
          {t(
            'settings.tiles.extension_attributes.page.extension_attribute_page.modal_delete.message',
            {
              display_name: values?.display_name
            }
          )}
        </p>
      </Modal>
    </form>
  );
};
