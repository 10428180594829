import { ChangeEvent, FC, useEffect, useState } from 'react';
import css from './device-extension-attributes.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeDataType,
  ExtensionAttributeFullDto,
  ExtensionAttributeInputType,
  ExtensionAttributeInventorySection,
  GetExtensionAttributesResponseDto,
  Permission
} from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { getExtensionAttributes } from '../../../../../../../../../../../api/extension-attributes';
import { DropdownOption } from '../../../../../../../../../../components/dropdown/dropdown.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import {
  getDeviceExtensionAttributes,
  updateExtensionAttributeValue
} from '../../../../../../../../../../../api/device';
import { Button } from '../../../../../../../../../../components/button/button.component';
import { toast } from 'react-toastify';
import { getLocalizedErrorString } from '../../../../../../../../../../../utils/localize-error';
import { formatDate } from '../../../../../../../../../../../utils/time.utils';
import { usePermission } from '../../../../../../../../../../contexts/permission.context';
interface IProps {
  inventorySection: ExtensionAttributeInventorySection;
  displayHeading?: boolean;
  device?: DeviceFullDto;
}

interface DeviceExtensionAttributesValue {
  extension_attribute: ExtensionAttributeFullDto;
  extension_value?: string | null;
}

export const DeviceExtensionAttributes: FC<IProps> = (props: IProps) => {
  const { device, inventorySection, displayHeading = true } = props;
  const [currentlyChangingValues, setCurrentlyChangingValues] = useState<string[]>([]);
  const [deviceExtensionAttributes, setDeviceExtensionAttributes] = useState<
    DeviceExtensionAttributesValue[]
  >([]);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const getExtensionAttributesValues = async () => {
    const deviceAttributeValues: DeviceExtensionAttributesValue[] = [];
    getExtensionAttributes().then((req: GetExtensionAttributesResponseDto) => {
      req.extension_attributes.forEach((attribute) => {
        const deviceAttributeValue = device?.device_extension_attributes?.find(
          (attr) => attr.extension_attribute?.id === attribute.id
        )?.value;
        const deviceExtensionAttributesValue: DeviceExtensionAttributesValue = {
          extension_attribute: attribute,
          extension_value: deviceAttributeValue
        };
        deviceAttributeValues.push(deviceExtensionAttributesValue);
      });
      setDeviceExtensionAttributes(deviceAttributeValues);
    });
  };

  const addToCurrentlyChanging = (id: string) => {
    const currentlyChangingValuesNow = currentlyChangingValues.slice();
    currentlyChangingValuesNow.push(id);
    setCurrentlyChangingValues(currentlyChangingValuesNow);
  };

  const handleEditExtensionAttributeClick = (id: string) => {
    addToCurrentlyChanging(id);
  };

  const handleSaveExtensionAttributesValuesClick = async () => {
    if (device) {
      for (let i = 0, l = deviceExtensionAttributes.length; i < l; i++) {
        const existingValue = device.device_extension_attributes?.find(
          (attr) =>
            attr.extension_attribute?.id === deviceExtensionAttributes[i].extension_attribute.id
        )?.value;
        try {
          if (deviceExtensionAttributes[i].extension_value) {
            if (existingValue !== deviceExtensionAttributes[i].extension_value) {
              await updateExtensionAttributeValue(device.id, {
                extension_attribute_id: deviceExtensionAttributes[i].extension_attribute.id,
                value: deviceExtensionAttributes[i].extension_value
              });
            }
          } else {
            await updateExtensionAttributeValue(device.id, {
              extension_attribute_id: deviceExtensionAttributes[i].extension_attribute.id,
              value: null
            });
          }
        } catch (error) {
          const localizedErrorString = getLocalizedErrorString(error as Error);
          console.log(localizedErrorString);
          toast.error(localizedErrorString, { autoClose: 5000 });
          return;
        }
      }
      const newAttributes = await getDeviceExtensionAttributes(device.id);
      device.device_extension_attributes = newAttributes;
      setCurrentlyChangingValues([]);
    }
  };

  const handleCancelExtensionAttributesValuesClick = () => {
    setCurrentlyChangingValues([]);
    getExtensionAttributesValues();
  };

  const handleAttributeChange = (
    deviceExtensionAttribute: DeviceExtensionAttributesValue,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const currentDeviceExtensionAttributes = deviceExtensionAttributes.slice();
    const attributeIndex = currentDeviceExtensionAttributes.findIndex(
      (attribute) => attribute.extension_attribute === deviceExtensionAttribute.extension_attribute
    );
    currentDeviceExtensionAttributes[attributeIndex].extension_value = event.target.value;
    setDeviceExtensionAttributes(currentDeviceExtensionAttributes);
  };

  useEffect(() => {
    getExtensionAttributesValues();
  }, [device]);

  return (
    <div className={css.Root}>
      {displayHeading ? (
        <span className={css.Heading}>
          {t('inventory.device_page.inventory_tab.device_extension_attributes.heading')}
        </span>
      ) : (
        <></>
      )}
      <table className={css.ExtensionAttributesTable}>
        <tbody>
          {deviceExtensionAttributes.length > 0 ? (
            deviceExtensionAttributes
              ?.filter((attr) => attr.extension_attribute.inventory_display === inventorySection)
              .map((deviceExtensionAttribute) => {
                if (
                  currentlyChangingValues.includes(deviceExtensionAttribute.extension_attribute.id)
                ) {
                  const attributeOptions: DropdownOption[] = [{ text: '---', value: '---' }];
                  switch (deviceExtensionAttribute.extension_attribute.input_type) {
                    case ExtensionAttributeInputType.Text:
                      return (
                        <tr key={deviceExtensionAttribute.extension_attribute.id}>
                          <td>{deviceExtensionAttribute.extension_attribute.display_name}</td>
                          <td>
                            <input
                              disabled={!isAllowedTo(Permission.EditDevices)}
                              key={`input${deviceExtensionAttribute.extension_attribute.id}`}
                              className={`${css.ExtensionAttributeInput} ${css.Hidden}`}
                              value={
                                deviceExtensionAttribute.extension_value
                                  ? deviceExtensionAttribute.extension_value
                                  : ''
                              }
                              onChange={(e) => handleAttributeChange(deviceExtensionAttribute, e)}
                              type={
                                deviceExtensionAttribute.extension_attribute.data_type ===
                                ExtensionAttributeDataType.String
                                  ? 'text'
                                  : deviceExtensionAttribute.extension_attribute.data_type ===
                                    ExtensionAttributeDataType.Number
                                  ? 'number'
                                  : 'datetime-local'
                              }
                            />
                          </td>
                        </tr>
                      );
                    case ExtensionAttributeInputType.Select:
                      deviceExtensionAttribute.extension_attribute.options?.forEach((option) => {
                        attributeOptions.push({ text: option, value: option });
                      });
                      return (
                        <tr key={deviceExtensionAttribute.extension_attribute.id}>
                          <td>{deviceExtensionAttribute.extension_attribute.display_name}</td>
                          <td>
                            <select
                              disabled={!isAllowedTo(Permission.EditDevices)}
                              className={css.ExtensionAttributeInput}
                              value={
                                deviceExtensionAttribute.extension_value
                                  ? deviceExtensionAttribute.extension_value
                                  : '---'
                              }
                              onChange={(e) => handleAttributeChange(deviceExtensionAttribute, e)}
                            >
                              <option value="">---</option>
                              {deviceExtensionAttribute.extension_attribute.options?.map(
                                (option) => {
                                  return (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </td>
                        </tr>
                      );

                    case ExtensionAttributeInputType.Script:
                      return (
                        <tr key={deviceExtensionAttribute.extension_attribute.id}>
                          <td>{deviceExtensionAttribute.extension_attribute.display_name}</td>
                          <td>
                            {deviceExtensionAttribute.extension_value
                              ? deviceExtensionAttribute.extension_attribute.data_type ===
                                ExtensionAttributeDataType.Date
                                ? formatDate(deviceExtensionAttribute.extension_value)
                                : deviceExtensionAttribute.extension_value
                              : '---'}
                          </td>
                        </tr>
                      );
                    default:
                      return (
                        <tr key={deviceExtensionAttribute.extension_attribute.id}>
                          <td>{deviceExtensionAttribute.extension_attribute.display_name}</td>
                          <td>
                            {deviceExtensionAttribute.extension_value
                              ? deviceExtensionAttribute.extension_attribute.data_type ===
                                ExtensionAttributeDataType.Date
                                ? formatDate(deviceExtensionAttribute.extension_value)
                                : deviceExtensionAttribute.extension_value
                              : '---'}
                          </td>
                        </tr>
                      );
                  }
                } else {
                  return (
                    <tr key={deviceExtensionAttribute.extension_attribute.id}>
                      <td>{deviceExtensionAttribute.extension_attribute.display_name}</td>
                      <td>
                        {deviceExtensionAttribute.extension_value
                          ? deviceExtensionAttribute.extension_attribute.data_type ===
                            ExtensionAttributeDataType.Date
                            ? formatDate(deviceExtensionAttribute.extension_value)
                            : deviceExtensionAttribute.extension_value
                          : '---'}
                        {deviceExtensionAttribute.extension_attribute.input_type !==
                        ExtensionAttributeInputType.Script ? (
                          <Button
                            isDisabled={!isAllowedTo(Permission.EditDevices)}
                            theme="primary"
                            className={css.EditButton}
                            onClick={() =>
                              handleEditExtensionAttributeClick(
                                deviceExtensionAttribute.extension_attribute.id
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>{' '}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                }
              })
          ) : (
            <tr>
              {t('inventory.device_page.inventory_tab.device_extension_attributes.no_attributes')}
            </tr>
          )}
        </tbody>
      </table>
      {currentlyChangingValues.length > 0 ? (
        <div className={css.Actions}>
          <Button
            isDisabled={!isAllowedTo(Permission.EditDevices)}
            theme="primary"
            onClick={handleSaveExtensionAttributesValuesClick}
          >
            {t('inventory.device_page.inventory_tab.device_extension_attributes.save_btn')}
          </Button>
          <Button
            isDisabled={!isAllowedTo(Permission.EditDevices)}
            theme="danger"
            onClick={handleCancelExtensionAttributesValuesClick}
          >
            {t('inventory.device_page.inventory_tab.device_extension_attributes.cancel_btn')}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
