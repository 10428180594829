import { FC } from 'react';
import css from './os-info-table.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const OsInfoTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <div>
      <table className={css.Root}>
        <tbody>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.os_info_tab.table.heading.os')}</td>
            <td>{di?.os_name || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.os_info_tab.table.heading.os_version')}</td>
            <td>{di?.os_version || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.os_info_tab.table.heading.os_build')}</td>
            <td>{di?.os_build_version || '---'}</td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.os_info_tab.table.heading.os_rapid_security_response'
              )}
            </td>
            <td>{di?.os_rapid_security_response || '---'}</td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.os_info_tab.table.heading.software_update_device_id'
              )}
            </td>
            <td>{di?.software_update_device_id || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.os_info_tab.table.heading.ad_status')}</td>
            <td>
              {isBoolean(di?.os_ad_bind)
                ? di?.os_ad_bind
                  ? t('inventory.device_page.inventory_tab.os_info_tab.table.enabled')
                  : t('inventory.device_page.inventory_tab.os_info_tab.table.disabled')
                : '---'}
            </td>
          </tr>
        </tbody>
      </table>
      <DeviceExtensionAttributes
        device={device}
        inventorySection={ExtensionAttributeInventorySection.OS}
      ></DeviceExtensionAttributes>
    </div>
  );
};
