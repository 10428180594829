import { FC, ReactNode } from 'react';
import { WithClassname } from '../../../types/common';
import css from './button.module.scss';
import cn from 'classnames';

export type ButtonTheme =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'danger'
  | 'warning'
  | 'light'
  | 'dark';

interface IProps {
  children?: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  theme?: ButtonTheme;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const Button: FC<IProps & WithClassname> = (props) => {
  const {
    className,
    children,
    type = 'button',
    theme = 'default',
    isLoading = false,
    isDisabled = false,
    onClick
  } = props;

  const handleClick = () => onClick?.();
  return (
    <button
      className={cn(className, `btn btn-${theme}`)}
      type={type}
      onClick={handleClick}
      disabled={isDisabled}
    >
      {isLoading && (
        <span
          className={cn(css.Spinner, 'spinner-border', 'spinner-border-sm')}
          role="status"
          aria-hidden="true"
        />
      )}
      {children}
    </button>
  );
};
