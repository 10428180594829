import { FC } from 'react';
import css from './services-table.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const ServicesTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  return (
    <table className={css.Root}>
      <thead>
        <tr>
          <th>{t('inventory.device_page.inventory_tab.services_tab.table.heading.name')}</th>
        </tr>
      </thead>
      <tbody>
        {device?.services?.map((i) => (
          <tr key={i.id}>
            <td>{i.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
