import cn from 'classnames';
import { FC } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import css from './app.module.scss';
import { DeviceSections, Paths } from './constants';
import { WithAxios } from './components/common/with-axios.component';
import { PrivateRoute } from './components/common/private-route';
import { SignIn } from './views/sign-in/sign-in.component';
import { AuthProvider } from './contexts/auth.context';
import { Home } from './views/home/home.component';
import { Header } from './components/header/header.component';
import { Footer } from './components/footer/footer.component';
import { PolicyList } from './views/policy-list/policy-list.component';
import { Inventory } from './views/inventory/inventory.component';
import { Sidebar } from './components/sidebar/sidebar.component';
import { Policy } from './views/policy/policy.component';
import { StaticGroupDevicesList } from './views/static-group-devices-list/static-group-devices-list.component';
import { SmartGroupDevices } from './views/smart-group-devices/smart-group-devices.component';
import { StaticGroupDevices } from './views/static-group-devices/static-group-devices.component';
import { ConfigurationProfileList } from './views/configuration-profile-list/configuration-profile-list.component';
import { ConfigurationProfile } from './views/configuration-profile/configuration-profile.component';
import { Settings } from './views/settings/settings.component';
import '../i18n/i18n';
import Toast from '../app/components/toast/toast.component';
import { RestrictedRoute } from './components/common/restricted-route';
import { Permission } from '../types/api';
import { PermissionProvider } from './contexts/permission.context';
import { DeviceUsersComponent } from './views/device-users/device-users.component';
import { DeviceUserListComponent } from './views/device-users-list/user-list.component';
import { StaticGroupDeviceUsersList } from './views/static-group-device-users-list/static-group-device-users-list.component';
import { StaticGroupDeviceUsers } from './views/static-group-device-users/static-group-device-users.component';
import { SmartGroupDeviceUsersListComponent } from './views/smart-group-device-users-list/smart-group-device-users-list.component';
import { SmartGroupDevicesList } from './views/smart-group-devices-list/smart-group-devices-list.component';
import { SmartGroupDeviceUsers } from './views/smart-group-device-users/smart-group-device-users.component';
import { DeviceApplication } from './views/device-application/device-application.component';
import { DeviceApplicationList } from './views/device-application-list/device-application-list.component';

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute redirectTo={Paths.SIGN_IN}>
            <Header />
            <Sidebar />
            <div className={cn(css.Content, 'content-wrapper')}>
              <Outlet />
            </div>
            <Footer />
          </PrivateRoute>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path={`${DeviceSections.COMPUTERS}`}>
          <Route
            element={<RestrictedRoute to={[Permission.ReadPolicies, Permission.EditPolicies]} />}
          >
            <Route path={Paths.POLICY} element={<Policy />} />
            <Route path={Paths.POLICY_LIST} element={<PolicyList />} />
          </Route>
          <Route
            element={<RestrictedRoute to={[Permission.ReadProfiles, Permission.EditProfiles]} />}
          >
            <Route path={Paths.CONFIGURATION_PROFILE} element={<ConfigurationProfile />} />
            <Route path={Paths.CONFIGURATION_PROFILE_LIST} element={<ConfigurationProfileList />} />
          </Route>
          <Route
            element={<RestrictedRoute to={[Permission.ReadDevices, Permission.EditDevices]} />}
          >
            <Route
              path={`${Paths.INVENTORY}/*`}
              element={<Inventory key="inventory-computers" />}
            />
          </Route>
          <Route element={<RestrictedRoute to={[Permission.ReadGroups, Permission.EditGroups]} />}>
            <Route path={Paths.SMART_GROUP_DEVICES} element={<SmartGroupDevices />} />
            <Route path={Paths.SMART_GROUP_DEVICES_LIST} element={<SmartGroupDevicesList />} />
            <Route path={Paths.STATIC_GROUP_DEVICES} element={<StaticGroupDevices />} />
            <Route
              path={Paths.STATIC_GROUP_DEVICES_LIST}
              element={<StaticGroupDevicesList />}
            />{' '}
          </Route>
          <Route path="" element={<Navigate to={Paths.HOME} replace />} />
        </Route>
        <Route path={`${DeviceSections.MOBILE}`}>
          <Route
            element={<RestrictedRoute to={[Permission.ReadDevices, Permission.EditDevices]} />}
          >
            <Route path={`${Paths.INVENTORY}/*`} element={<Inventory key="inventory-mobile" />} />
          </Route>
          <Route
            element={<RestrictedRoute to={[Permission.ReadProfiles, Permission.EditProfiles]} />}
          >
            <Route path={Paths.CONFIGURATION_PROFILE} element={<ConfigurationProfile />} />
            <Route path={Paths.CONFIGURATION_PROFILE_LIST} element={<ConfigurationProfileList />} />
          </Route>
          <Route
            element={
              <RestrictedRoute
                to={[Permission.ReadDeviceApplications, Permission.EditDeviceApplications]}
              />
            }
          >
            <Route path={`${Paths.DEVICE_APPLICATION}/*`} element={<DeviceApplication />} />
            <Route
              path={`${Paths.DEVICE_APPLICATION_LIST}/*`}
              element={<DeviceApplicationList />}
            />
          </Route>
          <Route element={<RestrictedRoute to={[Permission.ReadGroups, Permission.EditGroups]} />}>
            <Route path={Paths.SMART_GROUP_DEVICES} element={<SmartGroupDevices />} />
            <Route path={Paths.SMART_GROUP_DEVICES_LIST} element={<SmartGroupDevicesList />} />
            <Route path={Paths.STATIC_GROUP_DEVICES} element={<StaticGroupDevices />} />
            <Route path={Paths.STATIC_GROUP_DEVICES_LIST} element={<StaticGroupDevicesList />} />
          </Route>
          <Route path="" element={<Navigate to={Paths.HOME} replace />} />
        </Route>
        <Route element={<RestrictedRoute to={[Permission.ReadDevices, Permission.EditDevices]} />}>
          <Route path={`${Paths.INVENTORY}/*`} element={<Inventory />} />
        </Route>
        <Route
          element={
            <RestrictedRoute to={[Permission.ReadDeviceUsers, Permission.EditDeviceUsers]} />
          }
        >
          <Route path={`${Paths.DEVICE_USER_LIST}`} element={<DeviceUserListComponent />} />
          <Route path={`${Paths.DEVICE_USER}`} element={<DeviceUsersComponent />} />
        </Route>
        <Route element={<RestrictedRoute to={[Permission.ReadGroups, Permission.EditGroups]} />}>
          <Route
            path={`${Paths.STATIC_GROUP_DEVICE_USERS_LIST}`}
            element={<StaticGroupDeviceUsersList />}
          />
          <Route
            path={`${Paths.SMART_GROUP_DEVICE_USERS_LIST}`}
            element={<SmartGroupDeviceUsersListComponent />}
          />
          <Route path={Paths.STATIC_GROUP_DEVICE_USERS} element={<StaticGroupDeviceUsers />} />
          <Route path={Paths.SMART_GROUP_DEVICE_USERS} element={<SmartGroupDeviceUsers />} />
        </Route>
        <Route path={`${Paths.SETTINGS}/*`} element={<Settings />} />
      </Route>
      <Route path={Paths.SIGN_IN} element={<SignIn />} />
      <Route path="*" element={<Navigate to={Paths.HOME} replace />} />
    </Routes>
  );
};

export const App: FC = () => {
  return (
    <>
      <AuthProvider>
        <PermissionProvider>
          <BrowserRouter>
            <WithAxios>
              <div className={css.App}>
                <div className={css.Body}>
                  <Toast />
                  <AppRoutes />
                </div>
              </div>
            </WithAxios>
          </BrowserRouter>
        </PermissionProvider>
      </AuthProvider>
    </>
  );
};
