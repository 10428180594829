import { FC, FormEvent, useEffect, useState } from 'react';
import css from './settings-package-point.module.scss';
import { TextInput, TextInputType } from '../../../components/text-input/text-input.component';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CreatePackagePointDto,
  PackagePointFullDto,
  Permission,
  UpdatePackagePointDto
} from '../../../../types/api';
import {
  createPackagePoint,
  deletePackagePoint,
  getPackagePoint,
  updatePackagePoint
} from '../../../../api/packages';
import { Button } from '../../../components/button/button.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { PackagePointForm, packagePointFormSchema } from './settings-package-point.schema';
import { Dropdown, DropdownOption } from '../../../components/dropdown/dropdown.component';
import { Modal } from '../../../components/modal/modal.component';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';
import { toast } from 'react-toastify';

const translation = (key: string) =>
  `settings.tiles.package_distribution_points.page.package_distribution_point_page.${key}`;

export const SettingsPackagePoint: FC = () => {
  const { id } = useParams();
  const crudRequest = useRequest<PackagePointFullDto>();
  const navigate = useNavigate();
  const { register, formState, setValue, watch, trigger, getValues, reset } =
    useForm<PackagePointForm>({
      mode: 'onChange',
      resolver: yupResolver(packagePointFormSchema),
      defaultValues: packagePointFormSchema.getDefault()
    });
  const [packagePoint, setPackagePoint] = useState<PackagePointFullDto>();
  const [isEdit, setIsEdit] = useState(false);
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState<PackagePointForm>();
  const [isPasswordEdited, setIsPasswordEdited] = useState(false);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const isNew = id === 'new';
  const isDisabled = !isNew && !isEdit;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormChange = (event: FormEvent) => {
    if ((event.target as HTMLFormElement).name === 'read_password' && !isPasswordEdited && isEdit) {
      const nativeData = (event.nativeEvent as InputEvent).data;
      setValue('read_password', nativeData || '');
      setIsPasswordEdited(true);
    }
  };

  const handleFormSubmit = async (event: FormEvent) => {
    if (!id || crudRequest.loading) return;
    event.preventDefault();
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: CreatePackagePointDto = {
        display_name: values.display_name,
        type: values.type,
        server: values.server,
        port: values.port ? Number(values.port) : null,
        read_password: values.read_password,
        read_username: values.read_username,
        share_name: values.share_name
      };
      if (isEdit) {
        if (!isPasswordEdited) {
          delete (requestBody as UpdatePackagePointDto).read_password;
        }
        const updated = await crudRequest.send(updatePackagePoint(id, requestBody));
        if (requestBody.read_password) {
          setValue('read_password', '********');
        }
        setPackagePoint(updated);
        setValue('display_name', updated.display_name);
        setValue('type', updated.type);
        setValue('server', updated.server);
        setValue('port', !!updated.port || updated.port === 0 ? String(updated.port) : '');
        setValue('share_name', updated.share_name || '');
        setValue('read_username', updated.read_username || '');
        setValue('read_password', updated.read_password || '');
        setIsPasswordEdited(false);
        setIsEdit(false);
      } else {
        const created = await crudRequest.send(createPackagePoint(requestBody));
        setPackagePoint(created);
        if (requestBody.read_password) {
          setValue('read_password', '********');
        }
        setIsPasswordEdited(false);
        setValue('display_name', created.display_name);
        setValue('type', created.type);
        setValue('server', created.server);
        setValue('port', !!created.port || created.port === 0 ? String(created.port) : '');
        setValue('share_name', created.share_name || '');
        setValue('read_username', created.read_username || '');
        setValue('read_password', created.read_password || '');
        navigate(`/settings/package-points/${created.id}`, { replace: true });
      }
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
  };

  const handleEditRequest = () => {
    setValuesBeforeEdit(getValues());
    setIsEdit(true);
  };
  const handleCancel = () => {
    if (isNew) {
      navigate(`/settings/package-points`);
      return;
    }
    reset(valuesBeforeEdit);
    setIsEdit(false);
    setIsPasswordEdited(false);
  };

  const handleDeleteModalOpen = () => setIsDeleteModalShown(true);
  const handleDeleteModalClose = () => {
    if (crudRequest.loading) return;
    setIsDeleteModalShown(false);
  };
  const handleDeleteModalSubmit = async () => {
    if (!id || crudRequest.loading) return;
    try {
      await crudRequest.send(deletePackagePoint(id));
      navigate(`/settings/package-points`, { replace: true });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    handleDeleteModalClose();
  };

  useEffect(() => {
    const init = async () => {
      if (!id || id === 'new') return;
      try {
        const result = await getPackagePoint(id);
        setPackagePoint(result);
        setValue('display_name', result.display_name);
        setValue('type', result.type);
        setValue('server', result.server);
        setValue('port', !!result.port || result.port === 0 ? String(result.port) : '');
        setValue('share_name', result.share_name || '');
        setValue('read_username', result.read_username || '');
        setValue('read_password', result.read_password || '');
      } catch {
        navigate(`/settings/package-points`, { replace: true });
      }
    };
    void init();
  }, []);

  const dropdownTypeOptions: DropdownOption[] = [
    { text: 'HTTP', value: 'http' },
    { text: 'SMB', value: 'smb' }
  ];
  return (
    <div className={css.Root}>
      <Breadcrumbs title={packagePoint?.display_name} />
      {(packagePoint || isNew) && (
        <form className={css.Form} onSubmit={handleFormSubmit} onChange={handleFormChange}>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditPackages)}>
            <h5 className={css.Title}>
              {isNew ? t(translation('new_title')) : packagePoint?.display_name}
            </h5>
            <TextInput
              className={css.Input}
              label={t(translation('display_name'))}
              required
              register={register('display_name')}
              errorText={formState.errors.display_name?.message}
              disabled={isDisabled}
            />
            <Dropdown
              className={css.Input}
              label={t(translation('protocol'))}
              options={dropdownTypeOptions}
              register={register('type')}
              disabled={isDisabled}
            />
            <TextInput
              className={css.Input}
              label={t(translation('server'))}
              required
              register={register('server')}
              errorText={formState.errors.server?.message}
              disabled={isDisabled}
            />
            <TextInput
              className={css.Input}
              label={t(translation('port'))}
              type={TextInputType.INTEGER}
              register={register('port')}
              errorText={formState.errors.port?.message}
              disabled={isDisabled}
            />
            <TextInput
              className={css.Input}
              label={
                watch('type') === 'smb'
                  ? t(translation('share_name'))
                  : t(translation('path_to_packages'))
              }
              register={register('share_name')}
              errorText={formState.errors.share_name?.message}
              disabled={isDisabled}
            />
            <TextInput
              className={css.Input}
              label={t(translation('username'))}
              register={register('read_username')}
              errorText={formState.errors.read_username?.message}
              disabled={isDisabled}
            />
            <TextInput
              className={css.Input}
              label={t(translation('password'))}
              type={TextInputType.PASSWORD}
              register={register('read_password')}
              errorText={formState.errors.read_password?.message}
              disabled={isDisabled}
            />
            <div className={css.ActionBtnGroup}>
              {!isNew && !isEdit && (
                <Button className={css.ActionBtn} theme="primary" onClick={handleEditRequest}>
                  {t(translation('edit_btn'))}
                </Button>
              )}
              {(isNew || isEdit) && (
                <Button
                  className={css.ActionBtn}
                  type="submit"
                  theme="primary"
                  isLoading={crudRequest.loading}
                >
                  {isNew ? t(translation('create_btn')) : t(translation('submit_btn'))}
                </Button>
              )}
              {(isNew || isEdit) && (
                <Button theme="danger" className={css.ActionBtn} onClick={handleCancel}>
                  {t(translation('cancel_btn'))}
                </Button>
              )}
              {!isNew && !isEdit && (
                <Button
                  className={css.ActionBtn}
                  isLoading={crudRequest.loading}
                  theme="danger"
                  onClick={handleDeleteModalOpen}
                >
                  {t(translation('delete_btn'))}
                </Button>
              )}
            </div>
          </fieldset>
        </form>
      )}
      <Modal
        title={t(translation('delete_modal.title'))}
        submitButtonName={t(translation('delete_modal.delete_btn'))}
        isOpen={isDeleteModalShown}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
        isLoading={crudRequest.loading}
      >
        <p>{t(translation('delete_modal.message'), { point_name: packagePoint?.display_name })}</p>
      </Modal>
    </div>
  );
};
