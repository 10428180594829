import { ChangeEvent, FC } from 'react';
import css from './device-application-search-form.module.scss';
import {
  TextInput,
  TextInputType
} from '../../../../../components/text-input/text-input.component';
import { Dropdown, DropdownOption } from '../../../../../components/dropdown/dropdown.component';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CountryCode } from '../../../../../../types/api';

interface IProps {
  filterText: string;
  setFilterText: (text: string) => void;
  country: CountryCode;
  setCountry: (country: CountryCode) => void;
  isLoading: boolean;
}

export const CountryOptions: DropdownOption[] = Object.values(CountryCode).map((i) => ({
  text: i,
  value: i
}));

export const DeviceApplicationSearchForm: FC<IProps> = (props: IProps) => {
  const { filterText, setFilterText, setCountry, isLoading, country } = props;
  const { t } = useTranslation();

  const handleUpdateFilter = async (e: ChangeEvent) => {
    return e.target ? setFilterText((e.target as HTMLInputElement).value) : null;
  };

  const handleChangeCountry = async (e: ChangeEvent) => {
    const value = e.target ? (e.target as HTMLInputElement).value : null;
    setCountry(value as CountryCode);
  };

  return (
    <div className={css.Root}>
      <div className={css.Label}>{t('device_apps.installation.search_step.title')}</div>
      <TextInput
        className={css.FilterInput}
        type={TextInputType.TEXT}
        value={filterText}
        onChange={handleUpdateFilter}
        disabled={isLoading}
        placeholder={t('device_apps.installation.search_step.search_placeholder')}
      />
      <div className={css.DropdownBlock}>
        <div className={css.Label}>{t('device_apps.installation.search_step.country')}</div>
        <Dropdown
          className={cn(css.Input, css.CountryDropdown)}
          options={CountryOptions}
          disabled={isLoading}
          value={country}
          onChange={handleChangeCountry}
        />
      </div>
    </div>
  );
};
