import { FC, FormEvent, useEffect } from 'react';
import css from './device-user-form.module.scss';
import { useTranslation } from 'react-i18next';
import { deviceUserFormSchema, DeviceUserFormValues } from './device-user-form.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { usePermission } from '../../../../../../../../../../contexts/permission.context';
import { Button } from '../../../../../../../../../../components/button/button.component';
import {
  TextInput,
  TextInputType
} from '../../../../../../../../../../components/text-input/text-input.component';
import { Permission } from '../../../../../../../../../../../types/api';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: DeviceUserFormValues;
  hideActions?: boolean;
  onCreate?: (values: DeviceUserFormValues) => void;
  onCreateCancel?: () => void;
  onUnlink?: () => void;
}

export const DeviceUsersForm: FC<IProps> = (props) => {
  const { mode, values, onCreate, onUnlink, onCreateCancel } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const deviceUserForm = useForm<DeviceUserFormValues>({
    mode: 'onChange',
    resolver: yupResolver(deviceUserFormSchema),
    defaultValues: values || deviceUserFormSchema.getDefault()
  });

  useEffect(() => {
    deviceUserForm.reset(values);
  }, [values]);

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    const isValid = await deviceUserForm.trigger();
    if (!isValid) return;

    onCreate?.(deviceUserForm.getValues());
  };

  const handleUnlink = () => onUnlink?.();
  const handleCreateCancel = async () => {
    await deviceUserForm.reset();
    onCreateCancel?.();
  };

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditDeviceUsers)}>
        <h5 className={css.Title}>
          {mode === 'create' ? t('device_users.user_form.new_title') : values?.username || ''}
        </h5>
        <div className={css.Content}>
          <TextInput
            label={t('device_users.user_form.column_username')}
            required
            errorText={deviceUserForm.formState.errors.username?.message}
            register={deviceUserForm.register('username')}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_email')}
            errorText={deviceUserForm.formState.errors.email?.message}
            register={deviceUserForm.register('email')}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_employee_name')}
            errorText={deviceUserForm.formState.errors.employee_name?.message}
            register={deviceUserForm.register('employee_name')}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_phone_number')}
            errorText={deviceUserForm.formState.errors.phone_number?.message}
            register={deviceUserForm.register('phone_number')}
            type={TextInputType.TEL}
            pattern={'[0-9]{3}-[0-9]{3}-[0-9]{4}'}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_position')}
            errorText={deviceUserForm.formState.errors.position?.message}
            register={deviceUserForm.register('position')}
            disabled={mode === 'view'}
          />
          <div className={css.ActionButtons}>
            {mode === 'view' && (
              <Button theme="danger" onClick={handleUnlink}>
                {t('inventory.device_page.inventory_tab.device_user_tab.buttons.unlink')}
              </Button>
            )}
            {mode === 'create' && (
              <>
                <Button theme="danger" onClick={handleCreateCancel}>
                  {t('inventory.device_page.inventory_tab.device_user_tab.buttons.cancel')}
                </Button>
                <Button theme="primary" onClick={handleCreate}>
                  {t('inventory.device_page.inventory_tab.device_user_tab.buttons.create')}
                </Button>
              </>
            )}
          </div>
        </div>
      </fieldset>
    </form>
  );
};
