import { FC, FormEvent, useEffect } from 'react';
import css from './settings-policies-config.module.scss';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { Button } from '../../../components/button/button.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { PoliciesConfigForm, policiesConfigFormSchema } from './settings-policies-config.schema';
import { getSettings, updateSettings } from '../../../../api/settings';
import { toast } from 'react-toastify';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Checkbox } from '../../../components/checkbox/checkbox.component';
import { usePermission } from '../../../contexts/permission.context';

export const SettingsPoliciesConfig: FC = () => {
  const initRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const updateRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, setValue, trigger, getValues } = useForm<PoliciesConfigForm>({
    mode: 'onChange',
    resolver: yupResolver(policiesConfigFormSchema),
    defaultValues: policiesConfigFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (initRequest.loading || updateRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        policy_leave_history_on_delete: Boolean(values.policy_leave_history_on_delete)
      };
      await updateRequest.send(updateSettings(requestBody));
    } catch (error: unknown) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  useEffect(() => {
    const init = async () => {
      const settings = await initRequest.send(getSettings(['policy_leave_history_on_delete']));
      if (settings.policy_leave_history_on_delete) {
        setValue('policy_leave_history_on_delete', settings.policy_leave_history_on_delete);
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs />
      <form className={css.Form} onSubmit={handleFormSubmit}>
        <h5 className={css.SettingsTitle}>{t('settings.tiles.policies_config.name')}</h5>
        <Checkbox
          className={css.Input}
          label={t('settings.tiles.policies_config.page.policy_leave_history_on_delete')}
          register={register('policy_leave_history_on_delete')}
          disabled={
            initRequest.loading ||
            !isAllowedTo(Permission.EditPolicies) ||
            !isAllowedTo(Permission.EditAdministration)
          }
        />
        <div className={css.ActionBtnGroup}>
          <Button
            className={css.ActionBtn}
            type="submit"
            theme="primary"
            isLoading={updateRequest.loading}
            isDisabled={
              !isAllowedTo(Permission.EditPolicies) || !isAllowedTo(Permission.EditAdministration)
            }
          >
            {t('settings.tiles.policies_config.page.update_btn')}
          </Button>
        </div>
      </form>
    </div>
  );
};
