import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import css from './device-user.module.scss';
import {
  CreateDeviceUserRequestDto,
  DeviceFullDto,
  DeviceUserSimpleDto,
  DeviceUserSortField,
  PaginationSortOrder,
  SearchDeviceUserByConditionDto
} from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../../../../../../../components/text-input/text-input.component';
import { Button } from '../../../../../../../../../../components/button/button.component';
import {
  createDeviceUser,
  getDeviceUserList
} from '../../../../../../../../../../../api/device-users';
import {
  getDeviceUserByDeviceId,
  linkDeviceToUser,
  unlinkDeviceUser
} from '../../../../../../../../../../../api/device';
import { DeviceUserList } from '../device-user-list/device-user-list.component';
import { toast } from 'react-toastify';
import { getLocalizedErrorString } from '../../../../../../../../../../../utils/localize-error';
import useRequest from '../../../../../../../../../../../hooks/useRequest';
import { Modal } from '../../../../../../../../../../components/modal/modal.component';
import { DeviceUserFormValues } from '../device-user-form/device-user-form.schema';
import { DeviceUsersForm } from '../device-user-form/device-user-form.component';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const DeviceUser: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();
  const crudRequest = useRequest<DeviceUserSimpleDto>();
  const userRef = useRef<DeviceUserSimpleDto>();

  const [isFormShown, setIsFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>('view');
  const [userFormValues, setUserFormValues] = useState<DeviceUserFormValues>();

  const [inputUsername, setInputUsername] = useState('');
  const [foundUsers, setFoundUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [foundUsersCount, setFoundUsersCount] = useState(0);

  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [sortField, setSortField] = useState<DeviceUserSortField | undefined>(undefined);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [isUnlinkModalShown, setIsUnlinkModalShown] = useState(false);

  const userToForm = (input: DeviceUserSimpleDto): DeviceUserFormValues => {
    return {
      username: input.username,
      ...(input?.email && { email: input?.email }),
      ...(input?.employee_name && { employee_name: input?.employee_name }),
      ...(input?.phone_number && { phone_number: input?.phone_number }),
      ...(input?.position && { position: input?.position }),
      computers_count: input.computers_count,
      mobile_devices_count: input.mobile_devices_count
    };
  };
  const search = async () => {
    const updateData = async () => {
      const conditions: SearchDeviceUserByConditionDto[] = inputUsername
        ? [
            {
              criteria: 'username',
              value: inputUsername
            }
          ]
        : [];
      const response = await getDeviceUserList({
        conditions,
        page: currentPage,
        limit: rowsPerPage,
        sort_order: sortOrder,
        sort_field: sortField
      });
      setFoundUsers(response.users);
      setFoundUsersCount(response.count);
    };
    await updateData();
  };

  const handleShowForm = () => setIsFormShown(true);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setInputUsername(e.target.value);
  const handleCancel = () => setFormMode('view');
  const handleUserUnlinkRequest = () => setIsUnlinkModalShown(true);
  const handleUserUnlinkCancel = () => setIsUnlinkModalShown(false);
  const handleUserUnlink = async () => {
    if (device?.udid) {
      await unlinkDeviceUser(device.id);
      setIsUnlinkModalShown(false);
      setUserFormValues(undefined);
      toast.success(
        t(
          'inventory.device_page.inventory_tab.device_user_tab.form.responses.successfully_unlinked'
        ),
        {
          autoClose: 5000
        }
      );
    }
  };
  const handleLinkUser = (row: DeviceUserSimpleDto) => async () => {
    if (device?.udid) {
      const response = await linkDeviceToUser(device.id, { device_user_id: row.id });
      userRef.current = response;
      setUserFormValues(userToForm(response));
      setFoundUsers([]);
      setFormMode('view');
      toast.success(
        t(
          'inventory.device_page.inventory_tab.device_user_tab.form.responses.successfully_linked',
          { username: response.username }
        ),
        { autoClose: 5000 }
      );
    }
  };
  const handleUserCreate = async (values: DeviceUserFormValues) => {
    if (crudRequest.loading) return;

    const request: CreateDeviceUserRequestDto = {
      username: values.username,
      ...(values.email && { email: values.email }),
      ...(values.employee_name && { employee_name: values.employee_name }),
      ...(values.phone_number && { phone_number: values.phone_number }),
      ...(values.position && { position: values.position })
    };
    try {
      const response = await crudRequest.send(createDeviceUser(request));
      if (device?.udid) {
        await linkDeviceToUser(device.id, { device_user_id: response.id });
      }
      setFoundUsers([]);
      setFormMode('view');
      setUserFormValues(userToForm(response));
      toast.success(
        t(
          'inventory.device_page.inventory_tab.device_user_tab.form.responses.successfully_created',
          { username: values.username }
        ),
        { autoClose: 5000 }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (device?.udid) {
        try {
          const response = await getDeviceUserByDeviceId(device.id);
          userRef.current = response;
          if (response) {
            setUserFormValues(userToForm(response));
            setIsFormShown(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    void getData();
  }, [device?.udid]);

  useEffect(() => {
    void search();
  }, [currentPage, rowsPerPage, sortOrder]);

  if (!isOpened) {
    return <div />;
  }

  if (!isFormShown) {
    return (
      <div className={css.Root}>
        <p>{t('inventory.device_page.inventory_tab.device_user_tab.no_linked_user_text')}</p>
        <Button theme="primary" onClick={handleShowForm}>
          {t('inventory.device_page.inventory_tab.device_user_tab.no_linked_user_btn')}
        </Button>
      </div>
    );
  }

  return (
    <div className={css.Root}>
      {(!userFormValues || formMode === 'edit') && formMode !== 'create' && (
        <>
          <span>{t('inventory.device_page.inventory_tab.device_user_tab.form.search_label')}</span>
          <div className={css.SearchBar}>
            <TextInput onChange={handleInputChange} required className={css.Input} />
            <Button className={css.SearchButton} onClick={search}>
              {t('inventory.device_page.inventory_tab.device_user_tab.buttons.search')}
            </Button>
          </div>
          {foundUsers.length !== 0 && device?.udid && (
            <DeviceUserList
              deviceUsers={foundUsers}
              deviceUsersCount={foundUsersCount}
              handleLinkUser={handleLinkUser}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setRowsPerPage}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              onSearch={search}
              setSortOrder={setSortOrder}
              setSortField={setSortField}
            />
          )}
          <hr />
        </>
      )}
      {(userFormValues || (!userFormValues && formMode === 'edit') || formMode === 'create') && (
        <div>
          <DeviceUsersForm
            mode={formMode}
            values={userFormValues}
            onCreate={handleUserCreate}
            onCreateCancel={handleCancel}
            onUnlink={handleUserUnlinkRequest}
          />
        </div>
      )}
      {(!userFormValues || formMode === 'edit') && formMode !== 'create' && (
        <>
          <Button theme="primary" onClick={() => setFormMode('create')}>
            {t('inventory.device_page.inventory_tab.device_user_tab.buttons.new_user')}
          </Button>
        </>
      )}
      <Modal
        title={t('inventory.device_page.inventory_tab.device_user_tab.unlink_modal_title', {
          username: userRef.current?.username
        })}
        submitButtonName={t(
          'inventory.device_page.inventory_tab.device_user_tab.unlink_modal_submit_btn'
        )}
        isOpen={isUnlinkModalShown}
        onRequestClose={handleUserUnlinkCancel}
        onSubmit={handleUserUnlink}
      >
        <p>
          {t('inventory.device_page.inventory_tab.device_user_tab.unlink_modal_text', {
            username: userRef.current?.username
          })}
        </p>
      </Modal>
    </div>
  );
};
