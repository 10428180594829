import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { WithChildren } from '../../../types/common';
import { Permission } from '../../../types/api';
import { usePermission } from '../../contexts/permission.context';
import css from '../../views/inventory/components/inventory-device-page/components/terminal-tab.module.scss';

type IProps = {
  to: Permission[];
  fallback?: ReactNode;
};

export const Restricted: FC<IProps & WithChildren> = (props) => {
  const { to, children, fallback } = props;

  const { loading, isAllowedTo } = usePermission();

  if (loading) {
    return (
      <span
        className={cn(css.Spinner, 'spinner-border', 'spinner-border-sm')}
        role="status"
        aria-hidden="true"
      />
    );
  }

  if (isAllowedTo(...to)) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
