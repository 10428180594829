import { FC, FormEvent, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import css from './configuration-profile-upload-modal.module.scss';
import { FileInput } from '../../../../components/file-input/file-input.component';
import { Modal } from '../../../../components/modal/modal.component';
import { ConfigurationProfileUploadForm } from './configuration-profile-upload.schema';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onCancel: () => void;
  form: UseFormReturn<ConfigurationProfileUploadForm>;
}

export const ConfigurationProfileUploadModal: FC<IProps> = (props: IProps) => {
  const { isOpen, onRequestClose, form, onCancel } = props;
  const [isProfileUploaded, setIsProfileUploaded] = useState<boolean>(false);
  const { t } = useTranslation();

  const { register, formState } = form;

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };
  const handleUpload = async () => {
    onRequestClose();
  };
  const handleUploadFormChanged = () => {
    setIsProfileUploaded(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      onSubmit={handleUpload}
      hideSubmit={!isProfileUploaded}
      title={t('profiles.profile_form.upload_modal.title')}
    >
      <form className={css.Form} onSubmit={handleFormSubmit} onChange={handleUploadFormChanged}>
        <FileInput
          className={css.Input}
          btnLabel={t('profiles.profile_form.upload_modal.choose_file')}
          accept={'.mobileconfig'}
          register={register('config_profile')}
          errorText={formState.errors?.config_profile?.message}
        />
      </form>
    </Modal>
  );
};
