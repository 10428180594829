import { FC } from 'react';
import cn from 'classnames';
import css from './dropdown.module.scss';
import { WithClassname, WithInputProps } from '../../../types/common';

export interface DropdownOption {
  value: string;
  text: string;
}

interface IProps {
  options: Array<DropdownOption>;
  label?: string;
  disabled?: boolean;
}

export const Dropdown: FC<IProps & WithInputProps & WithClassname> = (props) => {
  const {
    className,
    label,
    options,
    value,
    disabled = false,
    register = {},
    onChange = () => {}
  } = props;
  return (
    <label className={cn(className, css.Root, 'form-group')}>
      {label && <span className={css.Label}>{label}</span>}
      <select
        value={value}
        onChange={onChange}
        {...register}
        className="form-control"
        disabled={disabled}
      >
        {options.map((i) => (
          <option key={i.value} value={i.value}>
            {i.text}
          </option>
        ))}
      </select>
    </label>
  );
};
