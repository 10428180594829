import { FC, ReactNode } from 'react';
import { WithClassname } from '../../../types/common';
import cn from 'classnames';
import ReactModal from 'react-modal';

import css from './modal.module.scss';
import { Button, ButtonTheme } from '../button/button.component';
import { useTranslation } from 'react-i18next';

interface IProps {
  children?: ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
  title: string;
  submitButtonName?: string;
  submitButtonTheme?: ButtonTheme;
  cancelButtonName?: string;
  cancelButtonTheme?: ButtonTheme;
  isLoading?: boolean;
  error?: string;
  hideSubmit?: boolean;
}

export const Modal: FC<IProps & WithClassname> = (props) => {
  const { t } = useTranslation();

  const {
    className,
    children,
    isOpen,
    onRequestClose,
    onSubmit,
    title,
    submitButtonName = t('common.modal.default_submit_btn'),
    submitButtonTheme = 'primary',
    cancelButtonName = t('common.modal.cancel_btn'),
    cancelButtonTheme = 'danger',
    isLoading = false,
    error,
    hideSubmit
  } = props;

  return (
    <ReactModal
      className={cn(className, css.Root)}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={css.Overlay}
    >
      <div className="modal-header">
        <h3 className="modal-title">{title}</h3>
      </div>
      <div className={cn(css.ModalBody, 'modal-body')}>{children}</div>
      <div className="modal-footer">
        {error && <span className={css.Error}>{error}</span>}
        <Button theme={cancelButtonTheme} onClick={onRequestClose}>
          {cancelButtonName}
        </Button>
        {!hideSubmit ? (
          <Button theme={submitButtonTheme} isLoading={isLoading} onClick={onSubmit}>
            {submitButtonName}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </ReactModal>
  );
};
