import { TestConfig } from 'yup';
import i18next from '../i18n/i18n';

export function notEmpty(message?: string): TestConfig<string | undefined> {
  return {
    name: 'not-empty',
    message: message || 'Value should not be empty!',
    test: (value?: string) => !!value && !!value.trim()
  };
}

export function isPort(message?: string): TestConfig<string | undefined> {
  return {
    name: 'is-port',
    message: message || i18next.t('common.errors.invalid_port'),
    test: (value?: string) => {
      if (value === undefined || value === '') return true;
      const asNumber = Number(value);
      return asNumber >= 1 && asNumber <= 65535;
    }
  };
}

export function fileNotEmpty(message?: string): TestConfig<FileList | undefined> {
  return {
    name: 'file-not-empty',
    message: message || 'File is required!',
    test: (value?: FileList) => !!value && value.length > 0
  };
}

export function fileExtension(
  extensions: string[],
  message?: string
): TestConfig<FileList | undefined> {
  return {
    name: 'file-extension',
    message: message || `${i18next.t('common.errors.file_extension')}: ${extensions.join(', ')}!`,
    test: (value?: FileList) => {
      if (!value || !value[0]) return true;
      const split = value[0].name.split('.');
      const ext = split[split.length - 1];
      return extensions.includes(ext);
    }
  };
}

export function isHttpUrl(message?: string): TestConfig<string | undefined> {
  return {
    name: 'is-http-url',
    message: message || i18next.t('common.errors.invalid_http_url'),
    test: (value?: string) =>
      !!value &&
      /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*))$/.test(
        value
      )
  };
}

export function isOptionalHttpUrl(message?: string): TestConfig<string | undefined> {
  return {
    name: 'is-optional-http-url',
    message: message || i18next.t('common.errors.invalid_http_url'),
    test: (value?: string) =>
      !value ||
      /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*))$/.test(
        value
      )
  };
}

export function isEmail(message?: string): TestConfig<string | undefined> {
  return {
    name: 'is-email',
    message: message || i18next.t('common.errors.invalid_email'),
    test: (value?: string) =>
      !value || /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/.test(value)
  };
}

export function isHostname(message?: string): TestConfig<string | undefined> {
  return {
    name: 'is-hostname',
    message: message || i18next.t('common.errors.invalid_hostname'),
    test: (value?: string) =>
      !!value && /^(([a-z\d]+\.)+[a-z]{2,}|(\d{1,3}\.){3}\d{1,3})$/.test(value)
  };
}

export function isDistinguishedName(message?: string): TestConfig<string | undefined> {
  return {
    name: 'is-distinguished-name',
    message: message || i18next.t('common.errors.invalid_distinguished_name'),
    test: (value?: string) =>
      !value ||
      /^(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=+<>#;\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*")(?:\+(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=+<>#;\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*"))*(?:,(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=+<>#;\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*")(?:\+(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=+<>#;\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=+<>#;\\"]|\\[\dA-Fa-f]{2})*"))*)*$/.test(
        value
      )
  };
}
