import css from './settings-scripts.module.scss';
import { FC, useEffect, useState } from 'react';
import { ScriptFullDto } from '../../../../types/api';
import { useNavigate, useParams } from 'react-router-dom';
import { ScriptForm } from './components/script-form/script-form.component';
import { ScriptFormValues } from './components/script-form/script-form.schema';
import { createScript, deleteScript, getScript, updateScript } from '../../../../api/scripts';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { Paths } from '../../../constants';
import { SettingsPaths } from '../settings.const';
import { toast } from 'react-toastify';

export const Script: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'update' | 'edit'>('view');
  const [script, setScript] = useState<ScriptFullDto>();
  const [scriptFormValues, setScriptFormValues] = useState<ScriptFormValues>();
  const [isScriptFormShown, setIsScriptFormShown] = useState(false);

  const handleScriptCreate = async (values: ScriptFormValues) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const response = await createScript({
        name: values.display_name,
        description: values.description,
        script: values.script
      });
      navigate(`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', response.id)}`);
      setScript(response);
      setScriptFormValues({
        display_name: response.display_name,
        description: response.description || undefined,
        script: values.script
      });
      setFormMode('view');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleScriptDelete = async () => {
    if (isLoading || !script) return;
    setIsLoading(true);
    try {
      await deleteScript(script.id);
      navigate('/settings/scripts');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleScriptEdit = () => {
    setFormMode('edit');
  };

  const handleScriptCancel = () => {
    window.location.reload();
  };

  const handleScriptSave = async (values: ScriptFormValues) => {
    if (isLoading || !script) return;
    setIsLoading(true);
    try {
      const response = await updateScript(script.id, {
        name: values.display_name,
        description: values.description,
        script: values.script
      });
      setFormMode('view');
      setScript(response);
      setScriptFormValues({
        display_name: response.display_name,
        description: response.description || undefined,
        script: response.content
      });

      navigate(`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', response.id)}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const response = await getScript(id);
      setScript(response);
      setScriptFormValues({
        display_name: response.display_name,
        description: response.description || undefined,
        script: response.content
      });
    }
    setIsScriptFormShown(true);
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs title={script?.display_name} />
      {isScriptFormShown && (
        <ScriptForm
          mode={id === 'new' ? 'create' : formMode}
          values={scriptFormValues}
          onCreate={handleScriptCreate}
          onDelete={handleScriptDelete}
          onEdit={handleScriptEdit}
          onSave={handleScriptSave}
          onCancel={handleScriptCancel}
        />
      )}
    </div>
  );
};
