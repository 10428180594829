import cn from 'classnames';
import css from './policy-run-step.module.scss';
import { FC } from 'react';
import {
  PolicyPayloadPackageModifyType,
  PolicyRunFullDto,
  PolicyRunStepSimpleDto
} from '../../../../../types/api';
import { Dictionary, WithClassname } from '../../../../../types/common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { policyPayloadPackageModifyTypes } from '../../../../../const/policies.const';
import { isResultShownForCommandType } from './policy-run-step.const';
import { Paths } from '../../../../constants';
import { SettingsPaths } from '../../../settings/settings.const';

interface IProps {
  policyRun: PolicyRunFullDto;
  step: PolicyRunStepSimpleDto;
}

export const PolicyRunStep: FC<IProps & WithClassname> = (props) => {
  const { className, step, policyRun } = props;
  const { t } = useTranslation();

  const getStepStatus = (): { title: string; theme?: string } => {
    if (step.success)
      return { title: t('policies.policy_run_step.step_status_success'), theme: 'success' };
    if (step.success === false)
      return { title: t('policies.policy_run_step.step_status_failed'), theme: 'danger' };
    if (policyRun.canceled)
      return { title: t('policies.policy_run_step.step_status_canceled'), theme: 'secondary' };
    if (policyRun.cancel_pending)
      return {
        title: t('policies.policy_run_step.step_status_cancel_pending'),
        theme: 'secondary'
      };
    if (step.in_progress)
      return { title: t('policies.policy_run_step.step_status_in_progress'), theme: 'warning' };
    return { title: t('policies.policy_run_step.step_status_scheduled') };
  };

  const configuration = step.configuration as Dictionary;
  const stepStatus = getStepStatus();
  return (
    <div className={cn(className, 'card', css.Root)}>
      {step.command_type === 'RunCustomScript' && !!step.configuration && (
        <p>
          {t('policies.policy_run_step.action_run_script')}{' '}
          <Link
            to={`${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', configuration.script_id)}`}
            target="_blank"
          >
            {configuration.script_title}
          </Link>
          {configuration.script_arguments &&
            (configuration.script_arguments as unknown as string[]).some((i) => i !== '') && (
              <span>
                , {t('policies.policy_run_step.action_arguments')}: [
                {(configuration.script_arguments as unknown as string[]).join()}]
              </span>
            )}
        </p>
      )}
      {step.command_type === 'ModifyPackage' && (
        <p>
          {
            policyPayloadPackageModifyTypes[
              configuration.modify_type as PolicyPayloadPackageModifyType
            ]
          }{' '}
          <Link to={`/settings/packages/${configuration.package_id}`} target="_blank">
            {t('policies.policy_run_step.link_package')}
          </Link>
        </p>
      )}
      {step.command_type === 'GetDeviceInformation' && (
        <p>{t('policies.policy_run_step.action_get_device_information')}</p>
      )}
      <p>
        <span className={css.StatusPrefix}>{t('policies.policy_run_step.title_step_status')}</span>
        <small className={`badge badge-${stepStatus.theme}`}>{stepStatus.title}</small>
      </p>
      {isResultShownForCommandType(step.command_type) && (
        <div>
          {step.agent_command && step.agent_command?.result?.length && (
            <div className={css.ResultBlock}>
              <p className={css.ResultPrefix}>
                {t('policies.policy_run_step.title_step_script_stdout')}
              </p>
              <div className={css.ResultCodeBlock}>
                {step.agent_command.result.filter(Boolean).map((i) => (
                  <code className={css.ResultCode} key={i}>
                    {i}
                  </code>
                ))}
              </div>
            </div>
          )}
          {step.agent_command && step.agent_command?.result_error?.length && (
            <div className={css.ResultBlock}>
              <p className={css.ResultPrefix}>
                {t('policies.policy_run_step.title_step_script_stderr')}
              </p>
              <div className={css.ResultCodeBlock}>
                {step.agent_command.result_error.filter(Boolean).map((i) => (
                  <code className={css.ResultCode} key={i}>
                    {i}
                  </code>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
