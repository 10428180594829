import { ChangeEvent, FC, useEffect, useState } from 'react';
import css from './history-tab.module.scss';
import {
  DeviceFullDto,
  GetHistoryResponseDto,
  HistoryDto,
  HistoryElementType,
  HistoryMdmCommandType,
  HistorySortField,
  PaginationSortOrder
} from '../../../../../../../types/api';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { getHistory } from '../../../../../../../api/device';
import { formatDate } from '../../../../../../../utils/time.utils';
import {
  TextInput,
  TextInputType
} from '../../../../../../components/text-input/text-input.component';
import { Button } from '../../../../../../components/button/button.component';
import { deviceHistoryType, historyMdmCommandType } from '../../../../../../../const/history.const';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../../../const/pagination.const';

interface IProps {
  device?: DeviceFullDto;
}

export const HistoryTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [historyData, setHistoryData] = useState<HistoryDto[]>([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<HistorySortField | undefined>(
    HistorySortField.CreatedAt
  );
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);

  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');

  const [confirmedStartTime, setConfirmedStartTime] = useState<string>('');
  const [confirmedEndTime, setConfirmedEndTime] = useState<string>('');

  const { t } = useTranslation();

  const getHistoryForDevice = () => {
    if (device) {
      getHistory({
        element_type: HistoryElementType.Device,
        element_id: device.id,
        ...(confirmedStartTime ? { start_time: `${confirmedStartTime}T00:00:00.000Z` } : {}),
        ...(confirmedEndTime ? { end_time: `${confirmedEndTime}T23:59:59.999Z` } : {}),
        page: currentPage,
        limit: rowsPerPage,
        sort_field: sortField,
        sort_order: sortOrder
      }).then((req: GetHistoryResponseDto) => {
        const history = req.history;
        setHistoryData(history);
        setHistoryCount(req.count);
      });
    }
  };

  const handleFilterClick = () => {
    setConfirmedStartTime(startTime);
    setConfirmedEndTime(endTime);
  };

  const onStartDateInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStartTime(event.target.value);
  };

  const onEndDateInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (selectedColumn: TableColumn<HistoryDto>, sortDirection: SortOrder) => {
    if (selectedColumn.sortField) setSortField(selectedColumn.sortField as HistorySortField);
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    getHistoryForDevice();
  }, [sortField, sortOrder, rowsPerPage, currentPage, confirmedStartTime, confirmedEndTime]);

  const columns: TableColumn<HistoryDto>[] = [
    {
      name: t('inventory.device_page.history_tab.table.heading.event'),
      selector: (row) => (row.history_type ? deviceHistoryType[row.history_type] : '')
    },
    {
      name: t('inventory.device_page.history_tab.table.heading.info'),
      selector: (row) =>
        row.additional_info &&
        Object.keys(row.additional_info).length &&
        Object.entries(row.additional_info)[0].includes('command')
          ? historyMdmCommandType[Object.values(row.additional_info)[0] as HistoryMdmCommandType]
          : ''
    },
    {
      name: t('inventory.device_page.history_tab.table.heading.time'),
      selector: (row) => (row.created_at ? formatDate(row.created_at) : ''),
      sortable: true,
      sortField: HistorySortField.CreatedAt
    }
  ];

  return (
    <div className={css.HistoryTab}>
      <div className={css.HistoryDateFilter}>
        <TextInput
          type={TextInputType.DATE}
          className={css.HistoryDatePicker}
          value={startTime}
          onChange={onStartDateInputChange}
        />
        <TextInput
          type={TextInputType.DATE}
          className={css.HistoryDatePicker}
          value={endTime}
          onChange={onEndDateInputChange}
        />
        <Button theme="primary" onClick={handleFilterClick}>
          {t('inventory.device_page.history_tab.filter_btn')}
        </Button>
      </div>
      <div className={css.DataTableContainer}>
        <DataTable
          columns={columns}
          data={historyData}
          noDataComponent={t('common.no_records_in_table')}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={historyCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      </div>
    </div>
  );
};
