import { FC, useEffect, useState } from 'react';
import css from './static-group-device-users.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { StaticGroupForm } from './components/static-group-form/static-group-form.component';
import { StaticGroupGeneralForm } from './components/static-group-form/static-group-form.schema';
import {
  CreateStaticGroupDeviceUsersRequestDto,
  StaticGroupDeviceUsersFullDto,
  UpdateStaticGroupDeviceUsersRequestDto
} from '../../../types/api';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { toast } from 'react-toastify';
import {
  createStaticGroup,
  deleteStaticGroup,
  getStaticGroup,
  updateStaticGroup
} from '../../../api/static-groups-device-users';
import { useTranslation } from 'react-i18next';

export enum StaticGroupFormModes {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view'
}

export const StaticGroupDeviceUsers: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [staticGroup, setStaticGroup] = useState<StaticGroupDeviceUsersFullDto>();
  const [isStaticGroupFormShown, setIsStaticGroupFormShown] = useState(false);
  const [staticGroupFormMode, setStaticGroupFormMode] = useState<StaticGroupFormModes>(
    StaticGroupFormModes.CREATE
  );

  const handleStaticGroupCreate = async (values: StaticGroupGeneralForm, deviceUsers: string[]) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const request: CreateStaticGroupDeviceUsersRequestDto = {
        title: values.display_name,
        device_users: deviceUsers
      };
      const response = await createStaticGroup(request);
      setStaticGroup(response);
      setStaticGroupFormMode(StaticGroupFormModes.VIEW);
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleStaticGroupUpdateStart = () => setStaticGroupFormMode(StaticGroupFormModes.UPDATE);
  const handleStaticGroupUpdateCancel = () => setStaticGroupFormMode(StaticGroupFormModes.VIEW);
  const handleStaticGroupUpdate = async (values: StaticGroupGeneralForm, deviceUsers: string[]) => {
    if (isLoading || !staticGroup) return;
    setIsLoading(true);
    try {
      const request: UpdateStaticGroupDeviceUsersRequestDto = {
        title: values.display_name,
        device_users: deviceUsers
      };
      const response = await updateStaticGroup(request, staticGroup.id);
      setStaticGroup(response);
      setStaticGroupFormMode(StaticGroupFormModes.VIEW);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleStaticGroupDelete = async () => {
    if (isLoading || !staticGroup) return;
    setIsLoading(true);
    try {
      await deleteStaticGroup(staticGroup.id);
      navigate('./..');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const groupResponse = await getStaticGroup(id);
      setStaticGroup(groupResponse);
      setStaticGroupFormMode(StaticGroupFormModes.VIEW);
    }

    setIsStaticGroupFormShown(true);
  };

  useEffect(() => {
    void init();
  }, []);

  const isNew = id === 'new';
  return (
    <div className={css.Root}>
      <Breadcrumbs title={isNew ? t('static_groups.page.new_group_title') : staticGroup?.title} />
      {isStaticGroupFormShown && (
        <StaticGroupForm
          mode={staticGroupFormMode}
          onCreate={handleStaticGroupCreate}
          onUpdateStart={handleStaticGroupUpdateStart}
          onUpdateCancel={handleStaticGroupUpdateCancel}
          onUpdate={handleStaticGroupUpdate}
          group={staticGroup}
          onDelete={handleStaticGroupDelete}
        />
      )}
    </div>
  );
};
