import { FC, FormEvent, useEffect, useState } from 'react';
import css from './device-user-form.module.scss';
import { Permission } from '../../../../../types/api';
import { usePermission } from '../../../../contexts/permission.context';
import { useTranslation } from 'react-i18next';
import { TextInput, TextInputType } from '../../../../components/text-input/text-input.component';
import { deviceUserFormSchema, DeviceUserFormValues } from './device-user-form.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button } from '../../../../components/button/button.component';
import { Modal } from '../../../../components/modal/modal.component';
import { filterChangedFormFields } from '../../../../../utils/react-hook-form.utils';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: DeviceUserFormValues;
  onCreate?: (values: DeviceUserFormValues) => void;
  onEdit?: () => void;
  onSave?: (values: Partial<DeviceUserFormValues>) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onCancelCreation?: () => void;
}

export const DeviceUsersForm: FC<IProps> = (props) => {
  const { mode, values, onCreate, onEdit, onSave, onCancel, onCancelCreation, onDelete } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deviceUserForm = useForm<DeviceUserFormValues>({
    mode: 'onChange',
    resolver: yupResolver(deviceUserFormSchema),
    defaultValues: values || deviceUserFormSchema.getDefault()
  });

  useEffect(() => {
    deviceUserForm.reset(values);
  }, [values]);

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    const isValid = await deviceUserForm.trigger();
    if (!isValid) return;

    onCreate?.(deviceUserForm.getValues());
  };

  const handleEdit = () => onEdit?.();

  const handleSave = async () => {
    const isValid = await deviceUserForm.trigger();
    if (!isValid) return;

    const changedValues = filterChangedFormFields(
      deviceUserForm.getValues(),
      deviceUserForm.formState.dirtyFields
    );

    onSave?.(changedValues);
  };

  const handleCancel = async () => {
    await deviceUserForm.reset();
    onCancel?.();
  };

  const handleCancelCreation = async () => {
    onCancelCreation?.();
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditDeviceUsers)}>
        <h5 className={css.Title}>
          {mode === 'create' ? t('device_users.user_form.new_title') : values?.username || ''}
        </h5>
        <div className={css.Content}>
          <TextInput
            label={t('device_users.user_form.column_username')}
            required
            errorText={deviceUserForm.formState.errors.username?.message}
            register={deviceUserForm.register('username')}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_email')}
            errorText={deviceUserForm.formState.errors.email?.message}
            register={deviceUserForm.register('email')}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_employee_name')}
            errorText={deviceUserForm.formState.errors.employee_name?.message}
            register={deviceUserForm.register('employee_name')}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_phone_number')}
            errorText={deviceUserForm.formState.errors.phone_number?.message}
            register={deviceUserForm.register('phone_number')}
            type={TextInputType.TEL}
            pattern={'[0-9]{3}-[0-9]{3}-[0-9]{4}'}
            disabled={mode === 'view'}
          />
          <TextInput
            label={t('device_users.user_form.column_position')}
            errorText={deviceUserForm.formState.errors.position?.message}
            register={deviceUserForm.register('position')}
            disabled={mode === 'view'}
          />
        </div>
        <div className={css.ActionButtons}>
          {mode === 'create' && (
            <>
              <Button theme="primary" onClick={handleCreate}>
                {t('device_users.buttons.create')}
              </Button>
              <Button theme="danger" onClick={handleCancelCreation}>
                {t('device_users.buttons.cancel')}
              </Button>
            </>
          )}
          {mode === 'view' && (
            <>
              <Button theme="primary" onClick={handleEdit}>
                {t('device_users.buttons.edit')}
              </Button>
              <Button
                theme="danger"
                isDisabled={!isAllowedTo(Permission.EditDeviceUsers)}
                onClick={handleDeleteModalOpen}
              >
                {t('device_users.buttons.delete')}
              </Button>
            </>
          )}
          {mode === 'edit' && (
            <>
              <Button theme="primary" onClick={handleSave}>
                {t('device_users.buttons.save')}
              </Button>
              <Button theme="danger" onClick={handleCancel}>
                {t('device_users.buttons.cancel')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
      <Modal
        title={t('device_users.modal_delete.title')}
        submitButtonName={t('device_users.modal_delete.delete_btn')}
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
      >
        <p>
          {t('device_users.modal_delete.message', {
            title: values?.username
          })}
        </p>
      </Modal>
    </form>
  );
};
