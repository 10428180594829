import { FC, useEffect, useState } from 'react';
import { DeviceFullDto, GroupSimpleDto } from '../../../../../../../../../../../types/api';
import { getGroupsForDevice } from '../../../../../../../../../../../api/groups';
import css from './groups-tab.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../../../contexts/device-section.context';

interface IProps {
  device?: DeviceFullDto;
}

export const GroupsTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [groups, setGroups] = useState<GroupSimpleDto[]>();
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();

  const createGroupLink = (group: GroupSimpleDto): string => {
    if (group.type === 'SmartDevices' || group.type === 'StaticDevices') {
      const groupDeviceType = deviceType === DeviceType.COMPUTERS ? 'computers' : 'mobile';
      const groupType = group.type === 'SmartDevices' ? 'smart-groups' : 'static-groups';
      return `/${groupDeviceType}/${groupType}/${group.id}`;
    } else {
      const groupType = group.type === 'SmartDeviceUsers' ? 'smart-groups' : 'static-groups';
      return `/device-users/${groupType}/${group.id}`;
    }
  };
  useEffect(() => {
    if (!device) return;
    getGroupsForDevice(device.id).then((res) => setGroups(res.groups));
  }, []);

  return (
    <table className={css.Root}>
      <thead>
        <tr>
          <th>{t('inventory.device_page.management_tab.groups_tab.table.heading.name')}</th>
          <th>{t('inventory.device_page.management_tab.groups_tab.table.heading.type')}</th>
        </tr>
      </thead>
      <tbody>
        {groups?.map((i) => (
          <tr key={i.id}>
            <td>
              <Link to={createGroupLink(i)} target="_blank">
                {i.title}
              </Link>
            </td>
            <td>{t(`inventory.device_page.management_tab.groups_tab.table.${i.type}`)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
