import { FC, useEffect, useState } from 'react';
import css from './devices-list-tab.module.scss';
import DataTable from 'react-data-table-component';
import { SortOrder, TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { DeviceSimpleDto, DevicesSortField, PaginationSortOrder } from '../../../../../types/api';
import { SmartGroupFormValues } from '../smart-group-devices-form/smart-group-devices-form.schema';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatDateFrom } from '../../../../../utils/time.utils';
import { getDevicesFromSmartGroup } from '../../../../../api/groups';
import { PaginationRowsPerPageOptions } from '../../../../../const/pagination.const';

interface IProps {
  form: UseFormReturn<SmartGroupFormValues>;
}

export const DevicesListTab: FC<IProps> = (props: IProps) => {
  const { form } = props;
  const { t } = useTranslation();

  const [deviceData, setDeviceData] = useState<DeviceSimpleDto[]>([]);
  const [devicesCount, setDevicesCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<DevicesSortField>(DevicesSortField.DisplayName);
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);

  const getDevicesInfo = async () => {
    const groupId = form.getValues().group.id;

    if (groupId) {
      const response = await getDevicesFromSmartGroup(groupId, {
        page: currentPage,
        limit: rowsPerPage,
        sort_field: sortField,
        sort_order: sortOrder
      });

      setDeviceData(response.devices);
      setDevicesCount(response.count);
    }
  };

  const columns: TableColumn<DeviceSimpleDto>[] = [
    {
      name: t('inventory.table.heading.name'),
      selector: (row) =>
        row.device_information?.device_name ? row.device_information?.device_name : '',
      sortField: DevicesSortField.DisplayName,
      sortable: true
    },
    {
      name: t('inventory.table.heading.model_name'),
      selector: (row) => (row.model_name ? row.model_name : ''),
      sortField: DevicesSortField.ModelName,
      sortable: true,
      style: { cursor: 'pointer' }
    },
    {
      name: t('inventory.table.heading.serial'),
      selector: (row) => (row.serial_number ? row.serial_number : ''),
      sortField: DevicesSortField.SerialNumber,
      sortable: true,
      style: { cursor: 'pointer' }
    },
    {
      name: t('inventory.table.heading.last_check_in'),
      selector: (row) => formatDateFrom(row.last_seen),
      sortField: DevicesSortField.LastSeen,
      sortable: true,
      style: { cursor: 'pointer' }
    }
  ];

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<DeviceSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) setSortField(selectedColumn.sortField as DevicesSortField);
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    getDevicesInfo();
  }, [rowsPerPage, sortOrder, sortField, currentPage, location]);

  return (
    <div className={css.Root}>
      <DataTable
        columns={columns}
        data={deviceData}
        noDataComponent={t('common.no_records_in_table')}
        sortServer
        onSort={handleSort}
        pagination
        paginationServer
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        paginationDefaultPage={currentPage}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={devicesCount}
        paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
        paginationComponentOptions={{
          rowsPerPageText: t('common.table.rows_per_page'),
          rangeSeparatorText: t('common.table.of')
        }}
      />
    </div>
  );
};
