import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './management-tab-navbar.module.scss';
import {
  faGear,
  faLayerGroup,
  faLock,
  faPassport,
  faPlay
} from '@fortawesome/free-solid-svg-icons';
import { ManagementTabTabs } from '../../../../../../constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '../../../../../../../../../types/api';
import { Restricted } from '../../../../../../../../components/common/restricted';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../contexts/device-section.context';

interface IProps {
  openedTab: string;
  onChangeTab: (tabName: string) => void;
}

export const ManagementTabNavbar: FC<IProps> = (props: IProps) => {
  const { openedTab, onChangeTab } = props;
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();

  const computerTabs = [
    {
      tab: ManagementTabTabs.COMMANDS,
      icon: faPlay,
      name: t('inventory.device_page.management_tab.commands_tab.name'),
      permissions: [Permission.ReadCommands, Permission.EditCommands]
    },
    {
      tab: ManagementTabTabs.POLICIES,
      icon: faPassport,
      name: t('inventory.device_page.management_tab.policies_tab.name'),
      permissions: null
    },
    {
      tab: ManagementTabTabs.PROFILES,
      icon: faGear,
      name: t('inventory.device_page.management_tab.profiles_tab.name'),
      permissions: null
    },
    {
      tab: ManagementTabTabs.ACTIVATION,
      icon: faLock,
      name: t('inventory.device_page.management_tab.activation_tab.name'),
      permissions: null
    },
    {
      tab: ManagementTabTabs.GROUPS,
      icon: faLayerGroup,
      name: t('inventory.device_page.management_tab.groups_tab.name'),
      permissions: null
    }
  ];

  const mobileTabs = [
    {
      tab: ManagementTabTabs.COMMANDS,
      icon: faPlay,
      name: t('inventory_mobile.device_page.management_tab.commands_tab.name'),
      permissions: [Permission.ReadCommands, Permission.EditCommands]
    },
    {
      tab: ManagementTabTabs.PROFILES,
      icon: faGear,
      name: t('inventory_mobile.device_page.management_tab.profiles_tab.name'),
      permissions: null
    },
    {
      tab: ManagementTabTabs.ACTIVATION,
      icon: faLock,
      name: t('inventory_mobile.device_page.management_tab.activation_tab.name'),
      permissions: null
    },
    {
      tab: ManagementTabTabs.GROUPS,
      icon: faLayerGroup,
      name: t('inventory_mobile.device_page.management_tab.groups_tab.name'),
      permissions: null
    }
  ];

  const actualTabs = deviceType === DeviceType.COMPUTERS ? computerTabs : mobileTabs;

  return (
    <div className={css.ManagementTabNavbar}>
      <ul className={css.Tabs}>
        {actualTabs.map((tab, i) => {
          return tab.permissions ? (
            <Restricted to={tab.permissions}>
              <li
                className={`${css.Tab} ${openedTab == tab.tab ? css.Active : ''}`}
                onClick={() => onChangeTab(tab.tab)}
                title={tab.name}
                key={i}
              >
                <FontAwesomeIcon icon={tab.icon} />
                {tab.name}
              </li>
            </Restricted>
          ) : (
            <li
              className={`${css.Tab} ${openedTab == tab.tab ? css.Active : ''}`}
              onClick={() => onChangeTab(tab.tab)}
              title={tab.name}
              key={i}
            >
              <FontAwesomeIcon icon={tab.icon} />
              {tab.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
