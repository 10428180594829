import { FC, useEffect, useState } from 'react';
import { TabItem, TabNavbar } from '../../../components/tab/tab-navbar/tab-navbar.component';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SettingsPkiCertificateList } from '../settings-pki-certificate-list/settings-pki-certificate-list';
import { SettingsPkiCa } from '../settings-pki-ca/settings-pki-ca';
import { SettingsPkiCertificate } from '../settings-pki-certificate/settings-pki-certificate';
import { useTranslation } from 'react-i18next';

enum PkiTabs {
  CERTIFICATES = 'certificates',
  MANAGE_CA = 'manage_ca'
}

export const SettingsPki: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('');

  const handleTitleChange = (title: string) => {
    setBreadcrumbTitle(title);
  };

  const navbarTabs: TabItem[] = [
    { key: PkiTabs.CERTIFICATES, link: '', title: t('settings.tiles.pki.page.certificates') },
    { key: PkiTabs.MANAGE_CA, link: 'ca', title: t('settings.tiles.pki.page.manage_ca') }
  ];
  const activeTab = () => {
    if (location.pathname.endsWith('/pki/ca')) {
      return PkiTabs.MANAGE_CA;
    }
    return PkiTabs.CERTIFICATES;
  };

  useEffect(() => {
    setBreadcrumbTitle('');
  }, [location]);

  return (
    <div>
      <Breadcrumbs title={breadcrumbTitle} />
      <TabNavbar isLinks tabs={navbarTabs} activeTab={activeTab()} />
      <Routes>
        <Route path="/" element={<SettingsPkiCertificateList />} />
        <Route path="/ca" element={<SettingsPkiCa />} />
        <Route path="/:id" element={<SettingsPkiCertificate onTitleChange={handleTitleChange} />} />
      </Routes>
    </div>
  );
};
