import cn from 'classnames';
import css from './policy-run-list.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { PolicyRunSimpleDto } from '../../../../../types/api';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { WithClassname } from '../../../../../types/common';
import { acknowledgeAllPolicyRuns, getPolicyRuns, rerunPolicy } from '../../../../../api/policies';
import { PolicyRun } from '../policy-run/policy-run.component';
import { Button } from '../../../../components/button/button.component';
import useRequest from '../../../../../hooks/useRequest';
import { formatDate } from '../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../const/pagination.const';
import { Modal } from '../../../../components/modal/modal.component';

interface IProps {
  policyId?: string;
}

export const PolicyRunList: FC<IProps & WithClassname> = (props) => {
  const { className, policyId } = props;
  const acknowledgeAll = useRequest();
  const rerunPolicyRequest = useRequest();
  const [policyRuns, setPolicyRuns] = useState<PolicyRunSimpleDto[]>();
  const [policyRunsCount, setPolicyRunsCount] = useState(0);
  const [selectedPolicyRun, setSelectedPolicyRun] = useState<PolicyRunSimpleDto | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isRerunModalOpen, setIsRerunModalOpen] = useState(false);
  const currentPage = useRef(1);
  const { t } = useTranslation();

  const handlePolicyRunDeselect = () => setSelectedPolicyRun(null);
  const handleRunSelect = (policyRun: PolicyRunSimpleDto) => () => {
    setSelectedPolicyRun(policyRun);
  };

  const handleChangePage = async (value: number) => {
    const response = await getPolicyRuns({
      policy_id: policyId,
      page: value,
      limit: rowsPerPage
    });
    setPolicyRuns(response.policy_runs);
    currentPage.current = value;
  };
  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      currentPage.current = Math.max(currentPage.current - 1, 1);
    }
    setRowsPerPage(value);
    const response = await getPolicyRuns({
      policy_id: policyId,
      page: currentPage.current,
      limit: value
    });
    setPolicyRuns(response.policy_runs);
  };

  const handleAcknowledgeAll = async () => {
    if (!policyId || acknowledgeAll.loading) return;
    await acknowledgeAll.send(acknowledgeAllPolicyRuns(policyId));
    setPolicyRuns(policyRuns?.map((run) => ({ ...run, acknowledged: true })));
  };

  const handleRerunPolicyClick = () => {
    if (!policyId || rerunPolicyRequest.loading) return;
    setIsRerunModalOpen(true);
  };
  const handleRerunPolicyCancel = () => {
    setIsRerunModalOpen(false);
  };
  const handleRerunPolicyConfirm = async () => {
    if (!policyId || rerunPolicyRequest.loading) return;
    await rerunPolicyRequest.send(rerunPolicy(policyId));
    setIsRerunModalOpen(false);
    const response = await getPolicyRuns({
      policy_id: policyId,
      page: currentPage.current,
      limit: rowsPerPage
    });
    setPolicyRuns(response.policy_runs);
  };

  useEffect(() => {
    const init = async () => {
      if (!policyId) return;
      const response = await getPolicyRuns({ policy_id: policyId, limit: rowsPerPage });
      setPolicyRuns(response.policy_runs);
      setPolicyRunsCount(response.count);
    };
    void init();
  }, []);

  const getRunStatus = (run: PolicyRunSimpleDto): { title: string; theme?: string } => {
    if (run.canceled)
      return { title: t('policies.policy_run_list.statuses.canceled'), theme: 'secondary' };
    if (run.success)
      return { title: t('policies.policy_run_list.statuses.success'), theme: 'success' };
    if (run.success === false && run.acknowledged)
      return { title: t('policies.policy_run_list.statuses.failed'), theme: 'secondary' };
    if (run.success === false)
      return { title: t('policies.policy_run_list.statuses.failed'), theme: 'danger' };
    if (run.cancel_pending)
      return { title: t('policies.policy_run_list.statuses.cancel_pending'), theme: 'secondary' };
    if (run.in_progress)
      return { title: t('policies.policy_run_list.statuses.in_progress'), theme: 'warning' };
    return { title: t('policies.policy_run_list.statuses.scheduled') };
  };
  const policiesColumns: TableColumn<PolicyRunSimpleDto>[] = [
    {
      name: t('policies.policy_run_list.column_device'),
      selector: (policyRun) =>
        policyRun.device
          ? `${policyRun.device.model_name} (${policyRun.device.serial_number})`
          : t('policies.policy_run_list.device_deleted')
    },
    {
      name: t('policies.policy_run_list.column_started_at'),
      selector: (policyRun) => (policyRun.started_at ? formatDate(policyRun.started_at) : '---')
    },
    {
      name: t('policies.policy_run_list.column_finished_at'),
      selector: (policyRun) => (policyRun.finished_at ? formatDate(policyRun.finished_at) : '---')
    },
    {
      name: t('policies.policy_run_list.column_status'),
      cell: (policyRun) => {
        const { title, theme } = getRunStatus(policyRun);
        return <small className={`badge badge-${theme}`}>{title}</small>;
      }
    },
    {
      cell: (policyRun) => (
        <a onClick={handleRunSelect(policyRun)}>{t('policies.policy_run_list.view_btn')}</a>
      ),
      width: '150px'
    }
  ];
  return (
    <div className={cn(css.Root, className)}>
      <div className={css.TopSection}>
        {!selectedPolicyRun && !!policyRuns?.length && (
          <Button
            className={css.TopSectionBtn}
            onClick={handleAcknowledgeAll}
            isLoading={acknowledgeAll.loading}
            theme="primary"
          >
            {t('policies.policy_run_list.button_acknowledge_all')}
          </Button>
        )}
        {!selectedPolicyRun && (
          <Button
            className={css.TopSectionBtn}
            onClick={handleRerunPolicyClick}
            isLoading={rerunPolicyRequest.loading}
            theme="primary"
          >
            {t('policies.policy_run_list.button_rerun_policy')}
          </Button>
        )}
      </div>
      {!selectedPolicyRun && policyRuns && (
        <DataTable
          className={css.Table}
          columns={policiesColumns}
          data={policyRuns}
          pagination
          paginationServer
          paginationDefaultPage={currentPage.current}
          paginationTotalRows={policyRunsCount}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          noDataComponent={t('common.no_records_in_table')}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
      {selectedPolicyRun && (
        <PolicyRun policyRun={selectedPolicyRun} onBack={handlePolicyRunDeselect} />
      )}
      <Modal
        isOpen={isRerunModalOpen}
        onRequestClose={handleRerunPolicyCancel}
        onSubmit={handleRerunPolicyConfirm}
        isLoading={rerunPolicyRequest.loading}
        submitButtonName={t('policies.policy_run_list.modal_rerun_policy_submit')}
        title={t('policies.policy_run_list.modal_rerun_policy_title')}
      >
        {t('policies.policy_run_list.modal_rerun_policy_text')}
      </Modal>
    </div>
  );
};
