import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import css from './device-application-assistant.module.scss';
import { AppStoreApplicationList } from './app-store-application-list/app-store-application-list.component';
import { DeviceApplicationSearchForm } from './device-application-search-form/device-application-search-form';
import cn from 'classnames';
import { DeviceApplicationType } from './device-application-type/device-application-type';
import { Button } from '../../../../components/button/button.component';
import { CountryCode, SearchDeviceApplication } from '../../../../../types/api';
import { getAppStoreApplications } from '../../../../../api/device-applications';
import { useTranslation } from 'react-i18next';

enum InstallationSteps {
  NEW,
  SEARCH,
  CREATE
}

interface IProps {
  setMode: (mode: 'view' | 'create' | 'edit' | 'add') => void;
  handleAddApp: (app: SearchDeviceApplication) => void;
  setAppFormShown: (value: boolean) => void;
  onEnterManually: () => void;
}

export const MobileDeviceAppAssistant: FC<IProps> = (props: IProps) => {
  const { handleAddApp, onEnterManually } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [step, setStep] = useState<InstallationSteps | null>(InstallationSteps.NEW);
  const [country, setCountry] = useState<CountryCode>(CountryCode.RussianFederation);
  const [filterText, setFilterText] = useState<string>('');
  const [filteredApps, setFilteredApps] = useState<SearchDeviceApplication[]>([]);
  const [deviceType, setDeviceType] = useState<'iPhone' | 'iPad'>('iPhone');
  const [isLoading, setIsLoading] = useState(false);
  const apps = useRef<SearchDeviceApplication[]>([]);

  const filterApps = (type: 'iPhone' | 'iPad') => {
    const deviceApps = apps.current.filter((i) => {
      const arr = i.supportedDevices.filter((e) => e.startsWith(type));
      if (arr.length) return i;
    });
    setFilteredApps(deviceApps);
    return deviceApps;
  };

  useEffect(() => {
    const app = filterApps(deviceType);
    setFilteredApps(app);
  }, [deviceType]);

  const searchApps = async () => {
    setIsLoading(true);
    const response = await getAppStoreApplications({
      term: filterText,
      country_code: country
    });
    apps.current = response.ios_apps_list.filter((i) => i.price === 0);
    filterApps(deviceType);
    setIsLoading(false);
  };

  const nextStep = async () => {
    if (step !== null) {
      if (step + 1 === InstallationSteps.CREATE) {
        await searchApps();
      }
      setStep(step + 1);
    }
  };
  const previousStep = () => {
    if (step !== null) {
      if (step === InstallationSteps.CREATE) {
        setCountry(CountryCode.RussianFederation);
      }
      setStep(step - 1);
    }
  };

  const handleCancel = () => navigate('./..');

  const handleEnterManually = () => {
    setStep(null);
    onEnterManually?.();
  };

  return (
    <div>
      {step !== null && (
        <div className={css.Body}>
          <div className={css.Sidebar}>
            <ul className={css.List}>
              <li className={cn(css.Tab, step === InstallationSteps.NEW && css.TabHighlight)}>
                {t('device_apps.installation.type_selection_step.chapter')}
              </li>
              <li className={cn(css.Tab, step === InstallationSteps.SEARCH && css.TabHighlight)}>
                {t('device_apps.installation.search_step.chapter')}
              </li>
              <li className={cn(css.Tab, step === InstallationSteps.CREATE && css.TabHighlight)}>
                {t('device_apps.installation.add_app_step.chapter')}
              </li>
            </ul>
          </div>
          <div className={css.Content}>
            {step === InstallationSteps.NEW && <DeviceApplicationType />}
            {step === InstallationSteps.SEARCH && (
              <DeviceApplicationSearchForm
                filterText={filterText}
                setFilterText={setFilterText}
                setCountry={setCountry}
                country={country}
                isLoading={isLoading}
              />
            )}
            {step === InstallationSteps.CREATE && (
              <AppStoreApplicationList
                apps={filteredApps}
                deviceType={deviceType}
                setDeviceType={setDeviceType}
                setStep={setStep}
                handleAddApp={handleAddApp}
              />
            )}
            <div className={css.ActionButton}>
              {step === InstallationSteps.NEW && (
                <>
                  <Button className={css.Button} onClick={handleCancel} isDisabled={isLoading}>
                    {t('device_apps.installation.buttons.cancel')}
                  </Button>
                  <Button className={css.Button} onClick={nextStep} isDisabled={isLoading}>
                    {t('device_apps.installation.buttons.next')}
                  </Button>
                </>
              )}
              {step === InstallationSteps.SEARCH && (
                <>
                  <Button className={css.Button} onClick={handleCancel} isDisabled={isLoading}>
                    {t('device_apps.installation.buttons.cancel')}
                  </Button>
                  <Button className={css.Button} onClick={previousStep} isDisabled={isLoading}>
                    {t('device_apps.installation.buttons.previous')}
                  </Button>
                  <Button
                    className={css.Button}
                    onClick={handleEnterManually}
                    isDisabled={isLoading}
                  >
                    {t('device_apps.installation.buttons.enter_manually')}
                  </Button>
                  <Button
                    className={css.Button}
                    onClick={nextStep}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                  >
                    {t('device_apps.installation.buttons.next')}
                  </Button>
                </>
              )}
              {step === InstallationSteps.CREATE && (
                <>
                  <Button className={css.Button} onClick={handleCancel} isDisabled={isLoading}>
                    {t('device_apps.installation.buttons.cancel')}
                  </Button>
                  <Button className={css.Button} onClick={previousStep} isDisabled={isLoading}>
                    {t('device_apps.installation.buttons.previous')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
