import { FC, useState } from 'react';
import css from './device-application-form.module.scss';
import { TextInput } from '../../../../../components/text-input/text-input.component';
import cn from 'classnames';
import { Button } from '../../../../../components/button/button.component';
import { Permission } from '../../../../../../types/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  deviceApplicationFormSchema,
  DeviceApplicationFormValues
} from './device-application-form.schema';
import { usePermission } from '../../../../../contexts/permission.context';
import { Modal } from '../../../../../components/modal/modal.component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabItem, TabNavbar } from '../../../../../components/tab/tab-navbar/tab-navbar.component';
import { ScopeForm, ScopeValues } from '../../../../../components/scope-form/scope-form.component';

export enum DeviceApplicationFormTabs {
  GENERAL = 'General',
  SCOPE = 'Scope'
}

interface IProps {
  disabled?: boolean;
  mode: 'view' | 'create' | 'edit' | 'add';
  setMode: (mode: 'view' | 'create' | 'edit' | 'add') => void;
  values?: DeviceApplicationFormValues;
  onCreate?: (values: DeviceApplicationFormValues) => void;
  onEdit?: () => void;
  onSave?: (values: Partial<DeviceApplicationFormValues>) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  isLoading: boolean;
}

export const DeviceApplicationForm: FC<IProps> = (props: IProps) => {
  const { mode, values, onCreate, onEdit, onSave, onCancel, onDelete, disabled, isLoading } = props;
  const { isAllowedTo } = usePermission();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(DeviceApplicationFormTabs.GENERAL);
  const [scopeValues, setScopeValues] = useState<ScopeValues>(
    values?.scope || { scoped: true, scopeEntriesInclude: [], scopeEntriesExclude: [] }
  );
  const appForm = useForm<DeviceApplicationFormValues>({
    mode: 'onChange',
    resolver: yupResolver(deviceApplicationFormSchema),
    defaultValues: values || deviceApplicationFormSchema.getDefault()
  });

  const handleCreate = async () => {
    const isValid = await appForm.trigger();
    if (!isValid) return;

    onCreate?.(appForm.getValues());
  };

  const handleEditCancel = async () => {
    await appForm.reset();
    onCancel?.();
  };

  const handleCancelCreation = async () => navigate('./..');

  const handleEdit = () => onEdit?.();

  const handleSave = async () => {
    const isValid = await appForm.trigger();
    if (!isValid) return;
    const errorKeys = Object.keys(appForm.formState.errors);
    if (errorKeys.length > 0) return;

    onSave?.({
      ...appForm.getValues(),
      scope: scopeValues
    });
  };

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);

  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const handleChangeTab = (tab: string) => setActiveTab(tab);

  const handleScopeChange = (input: Partial<ScopeValues>) => {
    const newScope = { ...scopeValues, ...input };
    setScopeValues(newScope);
    appForm.setValue('scope', newScope);
  };

  const tabs: TabItem[] = [
    {
      key: DeviceApplicationFormTabs.GENERAL,
      title: t('device_apps.app_form.tab.general')
    },
    { key: DeviceApplicationFormTabs.SCOPE, title: t('device_apps.app_form.tab.scope') }
  ];

  return (
    <div className={css.Root}>
      <TabNavbar tabs={tabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
      {activeTab === DeviceApplicationFormTabs.GENERAL && (
        <div className={css.Form}>
          <h5 className={css.Title}>{mode !== 'create' ? values?.display_name : ''}</h5>
          <div className={css.Label}>{t('device_apps.app_form.display_name')}</div>
          <div className={css.Description}>
            {t('device_apps.app_form.description.display_name')}
          </div>
          <TextInput
            className={css.Input}
            register={appForm.register('display_name')}
            errorText={appForm.formState.errors.display_name?.message}
            disabled={disabled || isLoading}
          />
          <div className={css.Label}>{t('device_apps.app_form.short_version')}</div>
          <div className={css.Description}>
            {t('device_apps.app_form.description.short_version')}
          </div>
          <TextInput
            className={cn(css.Input, 'form-group')}
            register={appForm.register('version')}
            errorText={appForm.formState.errors.version?.message}
            disabled={disabled || isLoading}
          />
          <div className={css.Label}>{t('device_apps.app_form.bundle_id')}</div>
          <div className={css.Description}>{t('device_apps.app_form.description.bundle_id')}</div>
          <TextInput
            className={cn(css.Input, 'form-group')}
            register={appForm.register('bundle_id')}
            errorText={appForm.formState.errors.bundle_id?.message}
            disabled={disabled || isLoading}
          />
          <div className={cn(css.Input, 'form-check')}>
            <input
              checked={true}
              className={cn(css.Checkbox, 'form-check-input')}
              disabled
              type="checkbox"
            />
            <span className={css.CheckboxLabel}>{t('device_apps.app_form.app_is_free')}</span>
          </div>
          <div className={css.Label}>{t('device_apps.app_form.app_url')}</div>
          <div className={css.Description}>
            {t('device_apps.app_form.description.app_url_template')}
          </div>
          <TextInput
            className={css.Input}
            register={appForm.register('app_view_url')}
            errorText={appForm.formState.errors.app_view_url?.message}
            disabled={disabled || isLoading}
          />
          <div className={css.Label}>{t('device_apps.app_form.install_automatically')}</div>
        </div>
      )}
      {activeTab === DeviceApplicationFormTabs.SCOPE && (
        <ScopeForm
          className={css.Scope}
          values={scopeValues}
          disabled={mode === 'view' || isLoading}
          onChange={handleScopeChange}
        />
      )}
      <div className={css.ActionButtons}>
        {mode === 'create' && (
          <>
            <Button
              theme="primary"
              onClick={handleCreate}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              {t('device_apps.app_form.buttons.create')}
            </Button>
            <Button theme="danger" onClick={handleCancelCreation} isDisabled={isLoading}>
              {t('device_apps.app_form.buttons.cancel')}
            </Button>
          </>
        )}
        {mode === 'view' && (
          <>
            <Button
              isDisabled={!isAllowedTo(Permission.EditDeviceApplications) || isLoading}
              theme="primary"
              className={css.Button}
              onClick={handleEdit}
            >
              {t('device_apps.app_form.buttons.edit')}
            </Button>
            <Button
              isDisabled={!isAllowedTo(Permission.EditDeviceApplications) || isLoading}
              theme="danger"
              className={css.Button}
              onClick={handleDeleteModalOpen}
              isLoading={isLoading}
            >
              {t('device_apps.app_form.buttons.delete')}
            </Button>
          </>
        )}
        {mode === 'edit' && (
          <>
            <Button
              className={css.Button}
              theme="primary"
              onClick={handleSave}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              {t('device_apps.app_form.buttons.save')}
            </Button>
            <Button
              className={css.Button}
              theme="danger"
              onClick={handleEditCancel}
              isDisabled={isLoading}
            >
              {t('device_apps.app_form.buttons.cancel')}
            </Button>
          </>
        )}
      </div>
      <Modal
        title={t('device_apps.app_form.modal_delete.title', {
          title: values?.display_name
        })}
        submitButtonName={t('device_apps.app_form.modal_delete.delete_btn')}
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
      >
        <p>
          {t('device_apps.app_form.modal_delete.message', {
            title: values?.display_name
          })}
        </p>
      </Modal>
    </div>
  );
};
