import { FC, ReactElement } from 'react';
import css from './tab-navbar.module.scss';
import { WithChildren, WithClassname } from '../../../../types/common';
import cn from 'classnames';
import { TabNavItem } from './tab-navbar-item/tab-nav-item.component';

export interface TabItem {
  key: string;
  title: string;
  link?: string;
  disabled?: boolean;
}

interface IProps {
  tabs: TabItem[];
  activeTab: string;
  isLinks?: boolean;
  tabClassName?: string;
  onChangeTab?: (tab: string) => void;
}

export const TabNavbar: FC<IProps & WithClassname & WithChildren> = (props): ReactElement => {
  const {
    className,
    tabs,
    tabClassName,
    onChangeTab,
    activeTab,
    isLinks = false,
    children
  } = props;
  return (
    <div className={cn(className, css.Root)}>
      <ul className={css.Tabs}>
        {tabs.map((tab) => (
          <TabNavItem
            className={tabClassName}
            key={tab.key}
            link={tab.link}
            isLink={isLinks}
            title={tab.title}
            active={activeTab === tab.key}
            onClick={() => onChangeTab?.(tab.key)}
            disabled={tab.disabled}
          />
        ))}
      </ul>
      {children}
    </div>
  );
};
