import css from './policy-list.module.scss';
import { FC, useEffect, useState } from 'react';
import { getPolicies } from '../../../api/policies';
import {
  PaginationSortOrder,
  Permission,
  PoliciesSortField,
  PolicySimpleDto
} from '../../../types/api';
import { policyFrequencyTitles, policyTriggerTitles } from '../../../const/policies.const';
import { Link } from 'react-router-dom';
import { Button } from '../../components/button/button.component';
import cn from 'classnames';
import DataTable, { SortOrder } from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../contexts/permission.context';

export const PolicyList: FC = () => {
  const [policies, setPolicies] = useState<PolicySimpleDto[]>();
  const [policiesCount, setPoliciesCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<PoliciesSortField | undefined>(
    PoliciesSortField.Title
  );
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const init = async () => {
    const response = await getPolicies({
      page: currentPage,
      limit: rowsPerPage,
      sort_field: sortField,
      sort_order: sortOrder
    });
    setPolicies(response.policies);
    setPoliciesCount(response.count);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<PolicySimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) setSortField(selectedColumn.sortField as PoliciesSortField);
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    void init();
  }, [currentPage, rowsPerPage, sortField, sortOrder]);

  const getPolicyIcon = (policy: PolicySimpleDto) => {
    if (!policy.enabled) return css.PolicyIconDisabled;
    if (policy.unacknowledged_failed_policy_run_count > 0) return css.PolicyIconHasErrors;
    return css.PolicyIconEnabled;
  };
  const policiesColumns: TableColumn<PolicySimpleDto>[] = [
    {
      width: '20px',
      cell: (policy) => {
        return <FontAwesomeIcon className={getPolicyIcon(policy)} icon={faCircle} />;
      }
    },
    {
      name: t('policy_list.policy_table_name'),
      cell: (policy) => <Link to={`${policy.id}`}>{policy.title}</Link>,
      sortable: true,
      sortField: PoliciesSortField.Title
    },
    {
      name: t('policy_list.policy_table_frequency'),
      selector: (policy) =>
        policy.configuration ? policyFrequencyTitles[policy.configuration.frequency] : ''
    },
    {
      name: t('policy_list.policy_table_trigger'),
      selector: (policy) => {
        if (!policy.configuration || policy.configuration.triggers.length === 0) return '---';
        return policy.configuration.triggers.map((i) => policyTriggerTitles[i]).join(', ');
      }
    },
    {
      name: t('policy_list.policy_table_scope'),
      selector: (policy) =>
        policy.scoped
          ? t('policy_list.policy_table_specific_computers')
          : t('policy_list.policy_table_all_computers')
    },
    {
      name: t('policy_list.policy_table_devices_in_scope'),
      selector: (policy) => policy.scoped_devices_count
    },
    {
      name: t('policy_list.policy_table_successful_runs'),
      selector: (policy) => policy.successful_distinct_runs_count,
      sortable: true,
      sortField: PoliciesSortField.SuccessfulDistinctRunsCount
    }
  ];
  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditPolicies)} theme="primary">
            {t('policy_list.button_add_policy')}
          </Button>
        </Link>
      </div>
      {policies && (
        <DataTable
          noDataComponent={t('common.no_records_in_table')}
          columns={policiesColumns}
          data={policies}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={policiesCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
    </div>
  );
};
