import { FC } from 'react';
import cn from 'classnames';

import css from './checkbox.module.scss';
import { WithClassname, WithInputProps } from '../../../types/common';

interface IProps {
  label?: string;
  disabled?: boolean;
  checked?: boolean;
}

export const Checkbox: FC<IProps & WithInputProps & WithClassname> = (props) => {
  const { label, disabled = false, checked = undefined, register = {}, className } = props;
  return (
    <label className={cn(className, css.Root, 'form-check')}>
      <input
        {...register}
        checked={checked}
        className={cn(css.Input, 'form-check-input')}
        disabled={disabled}
        type="checkbox"
      />
      {label && <span className={css.Label}>{label}</span>}
    </label>
  );
};
