import { boolean, object, string } from 'yup';
import { ScopeValues } from '../../../../../components/scope-form/scope-form.component';
import i18next from '../../../../../../i18n/i18n';

export interface DeviceApplicationFormValues {
  display_name: string;
  bundle_id: string;
  version: string;
  source?: string;
  app_view_url: string;
  free: boolean;
  management_flags?: number;
  scope?: ScopeValues;
}

export const deviceApplicationFormSchema = object({
  display_name: string()
    .required(i18next.t('device_apps.app_form.errors.field_is_required'))
    .trim(),
  version: string().required(i18next.t('device_apps.app_form.errors.field_is_required')),
  bundle_id: string().required(i18next.t('device_apps.app_form.errors.field_is_required')),
  app_view_url: string().required(i18next.t('device_apps.app_form.errors.field_is_required')),
  free: boolean().default(true)
});
