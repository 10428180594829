import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import css from './user-list.module.scss';
import { Permission, UserSimpleDto } from '../../../../types/api';
import { getUsers } from '../../../../api/users';
import { formatDate } from '../../../../utils/time.utils';
import { Button } from '../../../components/button/button.component';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../../contexts/permission.context';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { userOriginTypeTitles } from '../../../../const/users.const';

export const UserListComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [users, setUsers] = useState<UserSimpleDto[]>([]);

  const dateSort = (dateA: string | number | Date, dateB: string | number | Date) => {
    const timestampA = new Date(dateA).getTime();
    const timestampB = new Date(dateB).getTime();

    if (timestampA > timestampB) {
      return 1;
    }

    if (timestampB > timestampA) {
      return -1;
    }

    return 0;
  };

  const handleRowClicked = (row: UserSimpleDto) => {
    navigate(row.id);
  };

  const usersColumns: TableColumn<UserSimpleDto>[] = [
    {
      name: t('settings.tiles.users.page.table.heading.username'),
      selector: (row: UserSimpleDto) => row.username,
      style: { maxWidth: '350px' }
    },
    {
      name: t('settings.tiles.users.page.table.heading.display_name'),
      selector: (row: UserSimpleDto) => row.display_name,
      style: { maxWidth: '350px' }
    },
    {
      name: t('settings.tiles.users.page.table.heading.origin_type'),
      selector: (row: UserSimpleDto) => userOriginTypeTitles[row.origin_type],
      style: { maxWidth: '350px' }
    },
    {
      name: t('settings.tiles.users.page.table.heading.role'),
      selector: (row: UserSimpleDto) => row.role.title,
      style: { maxWidth: '350px' }
    },
    {
      name: t('settings.tiles.users.page.table.heading.created'),
      selector: (row: UserSimpleDto) => formatDate(row.created_at),
      sortable: true,
      sortFunction: (rowA, rowB) => dateSort(rowA.created_at, rowB.created_at)
    },
    {
      name: t('settings.tiles.users.page.table.heading.updated'),
      selector: (row: UserSimpleDto) => formatDate(row.updated_at),
      sortable: true,
      sortFunction: (rowA, rowB) => dateSort(rowA.updated_at, rowB.updated_at)
    }
  ];

  const init = async () => {
    try {
      const response = await getUsers();
      setUsers(response.users);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditAdministration)} theme="primary">
            {t('settings.tiles.users.page.new_btn')}
          </Button>
        </Link>
      </div>
      {users && (
        <DataTable
          onRowClicked={handleRowClicked}
          noDataComponent={t('common.no_records_in_table')}
          columns={usersColumns}
          data={users}
        />
      )}
    </div>
  );
};
