import { FC } from 'react';
import css from './encryption-table.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const EncryptionTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <table className={css.Root}>
      <tbody>
        <tr>
          <td>
            {t(
              'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.encryption_state'
            )}
          </td>
          <td>
            {isBoolean(di?.filevault_enabled)
              ? di?.filevault_enabled
                ? t('inventory.device_page.inventory_tab.disk_encryption_tab.table.enabled')
                : t('inventory.device_page.inventory_tab.disk_encryption_tab.table.disabled')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.institutional_recovery_key_status'
            )}
          </td>
          <td>
            {isBoolean(di?.filevault_has_institutional_key)
              ? di?.filevault_has_institutional_key
                ? t('inventory.device_page.inventory_tab.disk_encryption_tab.table.enabled')
                : t('inventory.device_page.inventory_tab.disk_encryption_tab.table.disabled')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.personal_recovery_key_status'
            )}
          </td>
          <td>
            {isBoolean(di?.filevault_has_personal_key)
              ? di?.filevault_has_personal_key
                ? t('inventory.device_page.inventory_tab.disk_encryption_tab.table.enabled')
                : t('inventory.device_page.inventory_tab.disk_encryption_tab.table.disabled')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.personal_recovery_key'
            )}
          </td>
          <td>{di?.personal_recovery_key || '---'}</td>
        </tr>
      </tbody>
    </table>
  );
};
