import { object, string } from 'yup';

export interface UserLdapFormValues {
  username: string;
  display_name: string;
  role_id: string;
}

export const userLdapFormSchema = object({
  username: string(),
  display_name: string(),
  role_id: string()
}).required();

export interface SearchLdapFormValues {
  search: string;
}

export const searchLdapFormSchema = object({
  search: string()
}).required();
