import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { useAuthContext } from '../../contexts/auth.context';
import { Link, useNavigate } from 'react-router-dom';
import { FC, useState } from 'react';
import css from './header.module.scss';
import cn from 'classnames';
import { Paths } from '../../constants';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal/modal.component';

export const Header: FC = () => {
  const { authService, authState } = useAuthContext();
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogOut = async () => {
    await authService.signOut();
    navigate('/auth');
  };

  return (
    <>
      {/* Navbar */}
      <nav
        className={`main-header navbar navbar-expand navbar-light navbar-white ${css.MainHeader}`}
      >
        <div className={`container ${css.MainHeaderContainer}`}>
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                <FontAwesomeIcon icon={faBars} />{' '}
              </a>
            </li>
          </ul>
          {/* Right navbar links */}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className={cn(css.NavLink, 'nav-link', 'dropdown-toggle')}
                href="#"
                id="navbarDropdown4"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className={cn(css.Username)}>{authState.username}</span>
                <FontAwesomeIcon icon={faUser} />
                {/*<span className="badge badge-danger navbar-badge">3</span>*/}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown4">
                <a className="dropdown-item" onClick={() => setIsLogOutModalOpen(true)} href="#">
                  {t('header.log_out')}
                </a>
              </div>
            </li>
            {/* <li className="nav-item">
              <a className={cn(css.NavLink, 'nav-link')} href="#">
                <FontAwesomeIcon icon={faBell} />
                span className="badge badge-warning navbar-badge">15</span>
              </a>
            </li> */}
            <li className="nav-item">
              <Link className={cn(css.NavLink, 'nav-link')} to={Paths.SETTINGS}>
                <FontAwesomeIcon icon={faGear} />
              </Link>
            </li>
          </ul>
        </div>
        <Modal
          title={''}
          submitButtonName={t('log_out.log_out_btn')}
          submitButtonTheme={'danger'}
          cancelButtonName={t('log_out.cancel_btn')}
          cancelButtonTheme={'primary'}
          isOpen={isLogOutModalOpen}
          onRequestClose={() => setIsLogOutModalOpen(false)}
          onSubmit={handleLogOut}
        >
          {t('log_out.confirmation_message')}
        </Modal>
      </nav>
      {/* /.navbar */}
    </>
  );
};
