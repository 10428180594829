import { FC } from 'react';
import css from './hardware-table.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const HardwareTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <div>
      <table className={css.Root}>
        <tbody>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.hardware_tab.table.heading.model')}</td>
            <td>{device?.model_name || '---'}</td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.hardware_tab.table.heading.model_identifier')}
            </td>
            <td>{di?.model_identifier || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.hardware_tab.table.heading.id')}</td>
            <td>{device?.id.toUpperCase() || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.hardware_tab.table.heading.udid')}</td>
            <td>{device?.udid.toUpperCase() || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.hardware_tab.table.heading.serial')}</td>
            <td>{device?.serial_number || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.hardware_tab.table.heading.cpu_type')}</td>
            <td>{di?.cpu_type || '---'}</td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.hardware_tab.table.heading.primary_network_adapter'
              )}
            </td>
            <td>{di?.main_network_interface || '---'}</td>
          </tr>
          <tr>
            <td>
              {t(
                'inventory.device_page.inventory_tab.hardware_tab.table.heading.primary_mac_address'
              )}
            </td>
            <td>{di?.main_network_interface_mac || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.hardware_tab.table.heading.total_ram')}</td>
            <td>{di?.total_ram_mb ? `${Math.round(di.total_ram_mb / 1024)} GB` : '---'}</td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.hardware_tab.table.heading.battery_capacity')}
            </td>
            <td>{device?.device_information?.battery_capacity || '---'}</td>
          </tr>
        </tbody>
      </table>
      <DeviceExtensionAttributes
        device={device}
        inventorySection={ExtensionAttributeInventorySection.Hardware}
      ></DeviceExtensionAttributes>
    </div>
  );
};
