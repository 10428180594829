import { FC, useState } from 'react';
import css from './activation-tab.module.scss';
import {
  DeviceActivationInfoDto,
  DeviceFullDto,
  Permission
} from '../../../../../../../../../../../types/api';
import { getActivationInfo } from '../../../../../../../../../../../api/device';
import { Button } from '../../../../../../../../../../components/button/button.component';
import useRequest from '../../../../../../../../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../../../../../../../contexts/permission.context';
import { BlankPage } from '../../../../../../../../../../components/blank-page/blank-page.component';

interface IProps {
  device?: DeviceFullDto;
}

export const ActivationTab: FC<IProps> = (props: IProps) => {
  const { device } = props;

  const getActivationInfoRequest = useRequest<DeviceActivationInfoDto | null>();

  const [activationInfo, setActivationInfo] = useState('');
  const [activationInfoError, setActivationInfoError] = useState('');
  const [isCodeShown, setIsCodeShown] = useState(false);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleShowCodeClick = async () => {
    try {
      if (device) {
        const activationInfoResponse = await getActivationInfoRequest.send(
          getActivationInfo(device.id)
        );
        if (activationInfoResponse) {
          if (activationInfoResponse.activation_lock_bypass_code) {
            setActivationInfoError('');
            setActivationInfo(activationInfoResponse.activation_lock_bypass_code);
            setIsCodeShown(true);
          }
          if (activationInfoResponse.error) {
            setActivationInfo('');
            setActivationInfoError(activationInfoResponse.error);
            setIsCodeShown(false);
          }
        }
      }
    } catch (error) {
      setActivationInfo('');
      setIsCodeShown(false);
      console.log(error);
    }
  };

  return device?.mdm_enrollment_status ? (
    <div>
      <h6>{t('inventory.device_page.management_tab.activation_tab.activation_form.name')}</h6>
      <Button
        className={`${css.ShowCodeButton} ${!isCodeShown ? '' : css.Hidden}`}
        onClick={handleShowCodeClick}
        theme="primary"
        isLoading={getActivationInfoRequest.loading}
        isDisabled={!isAllowedTo(Permission.EditCommands)}
      >
        {t('inventory.device_page.management_tab.activation_tab.activation_form.update_btn')}
      </Button>
      <div className={`${css.CodeName} ${isCodeShown ? '' : css.Hidden}`}>
        {t('inventory.device_page.management_tab.activation_tab.activation_form.title')}
      </div>
      <div className={`${css.ActivationCode} ${isCodeShown ? '' : css.Hidden}`}>
        {activationInfo}
      </div>
      <div className={`${css.Error} ${getActivationInfoRequest.error ? '' : css.Hidden}`}>
        {t('inventory.device_page.management_tab.activation_tab.activation_form.error')}
      </div>
      <div className={`${css.Error} ${activationInfoError ? '' : css.Hidden}`}>
        {activationInfoError}
      </div>
    </div>
  ) : (
    <BlankPage text={t('inventory.device_page.management_tab.commands_tab.device_offline')} />
  );
};
