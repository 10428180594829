import cn from 'classnames';
import css from './scope-form.module.scss';
import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownOption } from '../dropdown/dropdown.component';
import { getScopeOptions } from '../../../api/scope';
import {
  DeviceOsType,
  PaginationSortOrder,
  ScopeEntryOperationType,
  ScopeOptionDto,
  ScopeOptionType
} from '../../../types/api';
import DataTable, { SortOrder } from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { scopeOptionTitles } from '../../../const/scope.const';
import { WithClassname } from '../../../types/common';
import { Button } from '../button/button.component';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export interface ScopeValues {
  scoped: boolean;
  scopeEntriesInclude: ScopeOptionDto[];
  scopeEntriesExclude: ScopeOptionDto[];
}

interface IProps {
  values: ScopeValues;
  disabled?: boolean;
  onChange?: (values: Partial<ScopeValues>) => void;
}

export const ScopeForm: FC<IProps & WithClassname> = (props) => {
  const { className, values, disabled = false, onChange } = props;
  const selectedScopeIncludeSet = useRef(new Set(values?.scopeEntriesInclude.map((i) => i.id)));
  const selectedScopeExcludeSet = useRef(new Set(values?.scopeEntriesExclude.map((i) => i.id)));
  const [areShownScopeOptions, setAreShownScopeOptions] = useState(false);
  const [scopeOptions, setScopeOptions] = useState<ScopeOptionDto[]>();
  const [scopeOptionsCount, setScopeOptionsCount] = useState(0);
  const [includeScopeActiveTab, setIncludeScopeActiveTab] = useState<ScopeOptionType>(
    ScopeOptionType.Device
  );
  const [excludeScopeActiveTab, setExcludeScopeActiveTab] = useState<ScopeOptionType>(
    ScopeOptionType.Device
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);

  const [shownScopeOperation, setShownScopeOperation] = useState<ScopeEntryOperationType>(
    ScopeEntryOperationType.Include
  );

  const { t } = useTranslation();

  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const targetComputersOptions: DropdownOption[] = [
    {
      text:
        osType === DeviceOsType.MacOS
          ? t('scope_form.target_computers_all_computers')
          : t('scope_form.target_computers_all_mobile'),
      value: 'all'
    },
    {
      text:
        osType === DeviceOsType.MacOS
          ? t('scope_form.target_computers_specific_computers')
          : t('scope_form.target_computers_specific_mobile'),
      value: 'specific'
    }
  ];

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<ScopeOptionDto>,
    sortDirection: SortOrder
  ) => {
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    const activeTab =
      shownScopeOperation === ScopeEntryOperationType.Include
        ? includeScopeActiveTab
        : excludeScopeActiveTab;

    const updateData = async () => {
      const result = await getScopeOptions({
        page: currentPage,
        limit: rowsPerPage,
        sort_order: sortOrder,
        type: activeTab,
        target_os_type: osType
      });
      setScopeOptions(result.options);
      setScopeOptionsCount(result.count);
    };

    updateData();
  }, [
    areShownScopeOptions,
    currentPage,
    rowsPerPage,
    sortOrder,
    includeScopeActiveTab,
    excludeScopeActiveTab,
    shownScopeOperation
  ]);

  const handleOpenTargetAdd = async () => {
    setAreShownScopeOptions(true);
  };

  const handleSelectScopeOption = (option: ScopeOptionDto) => () => {
    if (shownScopeOperation === ScopeEntryOperationType.Include) {
      selectedScopeIncludeSet.current.add(option.id);
      onChange?.({ scopeEntriesInclude: [...values.scopeEntriesInclude, option] });
    } else {
      selectedScopeExcludeSet.current.add(option.id);
      onChange?.({ scopeEntriesExclude: [...values.scopeEntriesExclude, option] });
    }
  };

  const handleRemoveScopeOption = (option: ScopeOptionDto) => () => {
    if (shownScopeOperation === ScopeEntryOperationType.Include) {
      selectedScopeIncludeSet.current.delete(option.id);
      onChange?.({
        scopeEntriesInclude: values.scopeEntriesInclude.filter((i) => i.id !== option.id)
      });
    } else {
      selectedScopeExcludeSet.current.delete(option.id);
      onChange?.({
        scopeEntriesExclude: values.scopeEntriesExclude.filter((i) => i.id !== option.id)
      });
    }
  };

  const handleTargetChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    const updatedScoped = value === 'specific';
    onChange?.({
      scoped: updatedScoped,
      scopeEntriesInclude: values.scopeEntriesInclude
    });
  };

  const handleShownOperationChange = (operation: ScopeEntryOperationType) => () => {
    setShownScopeOperation(operation);
  };

  const handleIncludeActiveTabChange = (tab: ScopeOptionType) => () => {
    setIncludeScopeActiveTab(tab);
  };

  const handleExcludeActiveTabChange = (tab: ScopeOptionType) => () => {
    setExcludeScopeActiveTab(tab);
  };

  useEffect(() => {
    if (disabled) setAreShownScopeOptions(false);
  }, [disabled]);

  const selectedOptionsTableColumns: TableColumn<ScopeOptionDto>[] = [
    {
      name: t('scope_form.selected_options_column_name'),
      selector: (row) => row.display_name,
      style: { maxWidth: '1000px' }
    },
    {
      name: t('scope_form.selected_options_column_type'),
      selector: (row) => scopeOptionTitles[row.type]
    },
    {
      cell: (row) =>
        !disabled && (
          <Button className={css.TableBtn} theme="danger" onClick={handleRemoveScopeOption(row)}>
            {t('scope_form.selected_options_button_remove')}
          </Button>
        )
    }
  ];
  const includeOptionsTableColumns: TableColumn<ScopeOptionDto>[] = [
    {
      name: t('scope_form.options_column_name'),
      selector: (row) => row.display_name,
      sortable: true,
      style: { maxWidth: '1000px' }
    },
    {
      cell: (row) =>
        !disabled &&
        (selectedScopeIncludeSet.current.has(row.id) ? (
          <Button className={css.TableBtnDisabled} isDisabled>
            {t('scope_form.options_button_added')}
          </Button>
        ) : (
          <Button className={css.TableBtn} theme="success" onClick={handleSelectScopeOption(row)}>
            {t('scope_form.options_button_add')}
          </Button>
        ))
    }
  ];

  const excludeOptionsTableColumns: TableColumn<ScopeOptionDto>[] = [
    {
      name: t('scope_form.options_column_name'),
      selector: (row) => row.display_name,
      sortable: true,
      style: { maxWidth: '1000px' }
    },
    {
      cell: (row) =>
        !disabled &&
        (selectedScopeExcludeSet.current.has(row.id) ? (
          <Button className={css.TableBtnDisabled} isDisabled>
            Добавлено
          </Button>
        ) : (
          <Button className={css.TableBtn} theme="success" onClick={handleSelectScopeOption(row)}>
            {t('scope_form.options_button_add')}
          </Button>
        ))
    }
  ];

  const shownScopeEntries =
    shownScopeOperation === ScopeEntryOperationType.Include
      ? values.scopeEntriesInclude
      : values.scopeEntriesExclude;
  const shownScopeOptions = (): ScopeOptionDto[] => {
    if (!scopeOptions) return [];
    return scopeOptions;
  };
  return (
    <div className={cn(css.Root, className)}>
      <div className={css.OperationTypeButtonGroup}>
        <Button
          className={cn(
            css.OperationTypeButton,
            shownScopeOperation === ScopeEntryOperationType.Include && css.OperationTypeButtonActive
          )}
          onClick={handleShownOperationChange(ScopeEntryOperationType.Include)}
        >
          {t('scope_form.inclusions_option')}
        </Button>
        <Button
          className={cn(
            css.OperationTypeButton,
            shownScopeOperation === ScopeEntryOperationType.Exclude && css.OperationTypeButtonActive
          )}
          onClick={handleShownOperationChange(ScopeEntryOperationType.Exclude)}
        >
          {t('scope_form.exclusions_option')}
        </Button>
      </div>

      {shownScopeOperation === ScopeEntryOperationType.Include && (
        <div>
          <Dropdown
            label={t(
              osType === DeviceOsType.MacOS
                ? 'scope_form.dropdown_label_target_computers'
                : 'scope_form.dropdown_label_target_mobile'
            )}
            options={targetComputersOptions}
            value={values.scoped ? 'specific' : 'all'}
            disabled={disabled}
            onChange={handleTargetChange}
          />
          {values.scoped && (
            <div>
              <div className={css.SelectedTargets}>
                <div className={css.SelectedTargetsTitle}>
                  <span>{t('scope_form.title_selected_entities')}</span>
                  {!disabled && (
                    <Button
                      className={css.ShowScopeOptionBtn}
                      theme="primary"
                      onClick={handleOpenTargetAdd}
                    >
                      {t('scope_form.button_add_target')}
                    </Button>
                  )}
                </div>
                <DataTable
                  columns={selectedOptionsTableColumns}
                  data={shownScopeEntries}
                  noDataComponent={t('common.no_records_in_table')}
                  pagination
                  paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
                  paginationComponentOptions={{
                    rowsPerPageText: t('common.table.rows_per_page'),
                    rangeSeparatorText: t('common.table.of')
                  }}
                />
              </div>
              {areShownScopeOptions && scopeOptions && (
                <div>
                  <div className={css.AvailableTargetsTitle}>
                    <span> {t('scope_form.title_available_options')}</span>
                  </div>
                  <div className={css.OperationTypeButtonGroup}>
                    <Button
                      className={cn(
                        css.OperationTypeButton,
                        includeScopeActiveTab !== ScopeOptionType.Device &&
                          css.OperationTypeButtonActive
                      )}
                      onClick={handleIncludeActiveTabChange(ScopeOptionType.Device)}
                    >
                      {t('scope_form.options_tab_devices')}
                    </Button>
                    <Button
                      className={cn(
                        css.OperationTypeButton,
                        includeScopeActiveTab !== ScopeOptionType.StaticGroupDevices &&
                          css.OperationTypeButtonActive
                      )}
                      onClick={handleIncludeActiveTabChange(ScopeOptionType.StaticGroupDevices)}
                    >
                      {t('scope_form.options_tab_static_groups_devices')}
                    </Button>
                    <Button
                      className={cn(
                        css.OperationTypeButton,
                        includeScopeActiveTab !== ScopeOptionType.SmartGroupDevices &&
                          css.OperationTypeButtonActive
                      )}
                      onClick={handleIncludeActiveTabChange(ScopeOptionType.SmartGroupDevices)}
                    >
                      {t('scope_form.options_tab_smart_groups_devices')}
                    </Button>
                    <Button
                      className={cn(
                        css.OperationTypeButton,
                        includeScopeActiveTab !== ScopeOptionType.DeviceUser &&
                          css.OperationTypeButtonActive
                      )}
                      onClick={handleIncludeActiveTabChange(ScopeOptionType.DeviceUser)}
                    >
                      {t('scope_form.options_tab_device_users')}
                    </Button>
                    <Button
                      className={cn(
                        css.OperationTypeButton,
                        includeScopeActiveTab !== ScopeOptionType.StaticGroupDeviceUsers &&
                          css.OperationTypeButtonActive
                      )}
                      onClick={handleIncludeActiveTabChange(ScopeOptionType.StaticGroupDeviceUsers)}
                    >
                      {t('scope_form.options_tab_static_groups_device_users')}
                    </Button>
                    <Button
                      className={cn(
                        css.OperationTypeButton,
                        includeScopeActiveTab !== ScopeOptionType.SmartGroupDeviceUsers &&
                          css.OperationTypeButtonActive
                      )}
                      onClick={handleIncludeActiveTabChange(ScopeOptionType.SmartGroupDeviceUsers)}
                    >
                      {t('scope_form.options_tab_smart_groups_device_users')}
                    </Button>
                  </div>
                  <DataTable
                    columns={includeOptionsTableColumns}
                    className={css.Table}
                    data={shownScopeOptions()}
                    noDataComponent={t('common.no_records_in_table')}
                    pagination
                    paginationServer
                    paginationDefaultPage={currentPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    paginationPerPage={rowsPerPage}
                    paginationTotalRows={scopeOptionsCount}
                    paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
                    paginationComponentOptions={{
                      rowsPerPageText: t('common.table.rows_per_page'),
                      rangeSeparatorText: t('common.table.of')
                    }}
                    sortServer
                    onSort={handleSort}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {shownScopeOperation === ScopeEntryOperationType.Exclude && (
        <div>
          <div className={css.SelectedTargets}>
            <div className={css.SelectedTargetsTitle}>
              <span>
                {t(
                  osType === DeviceOsType.MacOS
                    ? 'scope_form.title_selected_computer_targets'
                    : 'scope_form.title_selected_mobile_targets'
                )}
              </span>
              {!disabled && (
                <Button
                  className={css.ShowScopeOptionBtn}
                  theme="primary"
                  onClick={handleOpenTargetAdd}
                >
                  {t('scope_form.button_add_target')}
                </Button>
              )}
            </div>
            <DataTable
              columns={selectedOptionsTableColumns}
              className={css.Table}
              data={shownScopeEntries}
              noDataComponent={t('common.no_records_in_table')}
              pagination
              paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
              paginationComponentOptions={{
                rowsPerPageText: t('common.table.rows_per_page'),
                rangeSeparatorText: t('common.table.of')
              }}
            />
          </div>
          {areShownScopeOptions && scopeOptions && (
            <div>
              <div className={css.AvailableTargetsTitle}>
                <span> {t('scope_form.title_available_options')}</span>
              </div>
              <div className={css.OperationTypeButtonGroup}>
                <Button
                  className={cn(
                    css.OperationTypeButton,
                    excludeScopeActiveTab !== ScopeOptionType.Device &&
                      css.OperationTypeButtonActive
                  )}
                  onClick={handleExcludeActiveTabChange(ScopeOptionType.Device)}
                >
                  {t('scope_form.options_tab_devices')}
                </Button>
                <Button
                  className={cn(
                    css.OperationTypeButton,
                    excludeScopeActiveTab !== ScopeOptionType.StaticGroupDevices &&
                      css.OperationTypeButtonActive
                  )}
                  onClick={handleExcludeActiveTabChange(ScopeOptionType.StaticGroupDevices)}
                >
                  {t('scope_form.options_tab_static_groups_devices')}
                </Button>
                <Button
                  className={cn(
                    css.OperationTypeButton,
                    excludeScopeActiveTab !== ScopeOptionType.SmartGroupDevices &&
                      css.OperationTypeButtonActive
                  )}
                  onClick={handleExcludeActiveTabChange(ScopeOptionType.SmartGroupDevices)}
                >
                  {t('scope_form.options_tab_smart_groups_devices')}
                </Button>
                <Button
                  className={cn(
                    css.OperationTypeButton,
                    excludeScopeActiveTab !== ScopeOptionType.DeviceUser &&
                      css.OperationTypeButtonActive
                  )}
                  onClick={handleExcludeActiveTabChange(ScopeOptionType.DeviceUser)}
                >
                  {t('scope_form.options_tab_device_users')}
                </Button>
                <Button
                  className={cn(
                    css.OperationTypeButton,
                    excludeScopeActiveTab !== ScopeOptionType.StaticGroupDeviceUsers &&
                      css.OperationTypeButtonActive
                  )}
                  onClick={handleExcludeActiveTabChange(ScopeOptionType.StaticGroupDeviceUsers)}
                >
                  {t('scope_form.options_tab_static_groups_device_users')}
                </Button>
                <Button
                  className={cn(
                    css.OperationTypeButton,
                    excludeScopeActiveTab !== ScopeOptionType.SmartGroupDeviceUsers &&
                      css.OperationTypeButtonActive
                  )}
                  onClick={handleExcludeActiveTabChange(ScopeOptionType.SmartGroupDeviceUsers)}
                >
                  {t('scope_form.options_tab_smart_groups_device_users')}
                </Button>
              </div>
              <DataTable
                columns={excludeOptionsTableColumns}
                data={shownScopeOptions()}
                noDataComponent={t('common.no_records_in_table')}
                pagination
                paginationServer
                paginationDefaultPage={currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={scopeOptionsCount}
                paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
                paginationComponentOptions={{
                  rowsPerPageText: t('common.table.rows_per_page'),
                  rangeSeparatorText: t('common.table.of')
                }}
                sortServer
                onSort={handleSort}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
