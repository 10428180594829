import { FC, useEffect, useState } from 'react';
import DataTable, { SortOrder } from 'react-data-table-component';
import css from './static-group-devices-list.module.scss';

import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import {
  DeviceOsType,
  GetStaticGroupsDevicesResponseDto,
  PaginationSortOrder,
  Permission,
  StaticGroupDevicesSimpleDto,
  StaticGroupDevicesSortField
} from '../../../types/api';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/button/button.component';
import { getStaticGroups } from '../../../api/static-groups-devices';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../contexts/permission.context';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export const StaticGroupDevicesList: FC = () => {
  const navigate = useNavigate();

  const [staticGroupsCount, setStaticGroupsCount] = useState(0);
  const [staticGroupsData, setStaticGroupsData] = useState<StaticGroupDevicesSimpleDto[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<StaticGroupDevicesSortField | undefined>(
    StaticGroupDevicesSortField.Title
  );
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const columns: TableColumn<StaticGroupDevicesSimpleDto>[] = [
    {
      name: t('static_groups.table.heading.name'),
      sortField: StaticGroupDevicesSortField.Title,
      selector: (row: StaticGroupDevicesSimpleDto) => (row.title ? row.title : ''),
      sortable: true,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('static_groups.table.heading.id'),
      sortField: StaticGroupDevicesSortField.Id,
      selector: (row: StaticGroupDevicesSimpleDto) => row.id,
      sortable: true,
      style: { cursor: 'pointer' }
    },
    {
      name: t('static_groups.table.heading.count'),
      sortField: StaticGroupDevicesSortField.DevicesCount,
      selector: (row: StaticGroupDevicesSimpleDto) => row.devices_count.toString(),
      sortable: true,
      style: { cursor: 'pointer' }
    }
  ];

  const reloadData = async () => {
    const response = await getStaticGroups({
      page: currentPage,
      limit: rowsPerPage,
      sort_field: sortField,
      sort_order: sortOrder,
      os_type: osType
    });
    setStaticGroupsData(response.groups);
    setStaticGroupsCount(response.count);
  };

  const handleRowClicked = (row: StaticGroupDevicesSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<StaticGroupDevicesSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as StaticGroupDevicesSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  useEffect(() => {
    reloadData();
  }, [currentPage, rowsPerPage, sortOrder, sortField]);

  useEffect(() => {
    const fetchStaticGroupsData = async () => {
      const result: GetStaticGroupsDevicesResponseDto = await getStaticGroups({
        limit: rowsPerPage,
        page: currentPage,
        sort_field: sortField,
        sort_order: sortOrder,
        os_type: osType
      });
      const staticGroups: StaticGroupDevicesSimpleDto[] = result.groups;
      setStaticGroupsData(staticGroups);
      setStaticGroupsCount(result.count);
    };

    void fetchStaticGroupsData();
  }, []);

  return (
    <div id="static-groups" className={css.StaticGroupsContainer}>
      <Breadcrumbs />
      <div className={css.Actions}>
        <Link to="new">
          <Button isDisabled={!isAllowedTo(Permission.EditGroups)} theme="primary">
            {t('static_groups.new_btn')}
          </Button>
        </Link>
      </div>
      {staticGroupsData && (
        <DataTable
          columns={columns}
          data={staticGroupsData}
          onRowClicked={handleRowClicked}
          noDataComponent={t('common.no_records_in_table')}
          sortServer
          onSort={handleSort}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={staticGroupsCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
        />
      )}
    </div>
  );
};
