import { KeyboardEvent, ChangeEvent, FC, useEffect, useState, createRef } from 'react';
import css from './terminal-tab.module.scss';
import { DeviceFullDto } from '../../../../../../types/api';
import { getAgentStatus, sendCommand } from '../../../../../../api/agent';
import { useTranslation } from 'react-i18next';
import useInterval from '../../../../../../hooks/useInterval';
import cn from 'classnames';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRequest from '../../../../../../hooks/useRequest';

interface IProps {
  device?: DeviceFullDto;
}

export const TerminalTab: FC<IProps> = (props) => {
  const { device } = props;
  const inputRef = createRef<HTMLInputElement>();
  const [input, setInput] = useState<string>('');
  const [output, setOutput] = useState<string>('');
  const [deviceOnline, setDeviceOnline] = useState<boolean>(false);
  const commandRequest = useRequest<string[]>();
  const { t } = useTranslation();

  const setFocusToInput = () => {
    inputRef.current?.scrollIntoView();
    inputRef.current?.focus();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

  const executeCommandOnDevice = async (command: string): Promise<string[]> => {
    if (!device) return [t('inventory.device_page.terminal_tab.device_offline_reconnect')];
    try {
      const response = await sendCommand(device.udid, { input: command });
      return response.output;
    } catch (error) {
      console.log(String(error));
      return [String(error)];
    }
  };

  const handleInputKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (commandRequest.loading) return;
    if (e.key === 'Enter') {
      let commandResponse: string[] = [];
      if (input !== '') {
        commandResponse = await commandRequest.send(executeCommandOnDevice(input));
      }
      let newOutput: string;
      newOutput = output === '' ? `$ ${input}` : `${output} \n  $ ${input}`;
      const responseString = commandResponse.join('\n');
      if (responseString !== '') {
        newOutput += '\n';
      }
      newOutput += commandResponse.join('\n');
      setOutput(newOutput);
      setInput('');
    }
    setFocusToInput();
  };

  const checkDeviceStatus = async () => {
    if (!device || deviceOnline) return;
    const response = await getAgentStatus(device.udid);
    setDeviceOnline(response.online);
  };

  useEffect(() => {
    setFocusToInput();
  });

  useEffect(() => {
    void checkDeviceStatus();
  }, [device]);

  useInterval(() => {
    void checkDeviceStatus();
  }, 10_000);

  return (
    <div className={css.Root} onClick={setFocusToInput}>
      {!deviceOnline && (
        <div>
          <span
            className={cn(css.Spinner, 'spinner-border', 'spinner-border-sm')}
            role="status"
            aria-hidden="true"
          />
          <span>{t('inventory.device_page.terminal_tab.device_connecting')}</span>
        </div>
      )}
      {deviceOnline && (
        <div>
          <FontAwesomeIcon className={css.Link} icon={faCheck} />
          <span>{t('inventory.device_page.terminal_tab.device_connected')}</span>
        </div>
      )}
      <div className={css.Terminal}>{output}</div>
      {deviceOnline && (
        <div className={css.InputContainer}>
          <span className={css.InputPrefix}>$</span>
          <input
            className={css.Input}
            ref={inputRef}
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
          />
        </div>
      )}
    </div>
  );
};
