import { FC } from 'react';
import css from './configuration-profile-payload-general.module.scss';
import { TextInput } from '../../../../components/text-input/text-input.component';
import { UseFormReturn } from 'react-hook-form';
import { ConfigurationProfileFormValues } from '../configuration-profile-form.schema';
import { useTranslation } from 'react-i18next';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  form: UseFormReturn<ConfigurationProfileFormValues>;
  disabled?: boolean;
}

export const ConfigurationProfileGeneral: FC<IProps> = (props) => {
  const { disabled = false, form } = props;
  const { register, formState, watch } = form;
  const { t } = useTranslation();

  const errors = formState.errors;

  return (
    <div className={css.Root}>
      <h5 className={css.GroupTitle}>Общие</h5>
      {watch('imported_payload') && (
        <div className={css.ImportedTeg}>
          {t('profiles.profile_form.options_tab.general.imported')}{' '}
          <FontAwesomeIcon icon={faFileImport} title="Imported" />
        </div>
      )}
      <TextInput
        className={css.Input}
        label={t('profiles.profile_form.options_tab.general.display_name')}
        register={register('display_name')}
        errorText={errors.display_name?.message}
        disabled={disabled}
        required
      />
      <TextInput
        className={css.Input}
        label={t('profiles.profile_form.options_tab.general.description')}
        register={register('description')}
        errorText={errors.description?.message}
        disabled={disabled}
      />
      <TextInput
        className={css.Input}
        label={t('profiles.profile_form.options_tab.general.organization_name')}
        register={register('organization_name')}
        errorText={errors.organization_name?.message}
        disabled={disabled}
      />
    </div>
  );
};
