import { FC, FormEvent, useEffect } from 'react';
import css from './settings-groups.module.scss';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { Button } from '../../../components/button/button.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { getSettings, updateSettings } from '../../../../api/settings';
import { toast } from 'react-toastify';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../../contexts/permission.context';
import { TextInput, TextInputType } from '../../../components/text-input/text-input.component';
import { Dropdown, DropdownOption } from '../../../components/dropdown/dropdown.component';
import { SmartGroupsSettingsForm, smartGroupsSettingsFormSchema } from './settings-groups.schema';

export const SettingsGroups: FC = () => {
  const initRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const updateRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, setValue, trigger, getValues, watch, formState } =
    useForm<SmartGroupsSettingsForm>({
      mode: 'onChange',
      resolver: yupResolver(smartGroupsSettingsFormSchema),
      defaultValues: smartGroupsSettingsFormSchema.getDefault()
    });
  const watchCustomInterval = watch('smart_groups_update_interval');
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (initRequest.loading || updateRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();

    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        smart_groups_update_interval:
          values.smart_groups_update_interval === 'custom' && Number(values.custom_interval)
            ? Number(values.custom_interval)
            : Number(values.smart_groups_update_interval)
      };
      await updateRequest.send(updateSettings(requestBody));
      toast.success(t('settings.tiles.smart_groups_config.page.settings_successfully_saved'), {
        autoClose: 5000
      });
    } catch (error: unknown) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  const intervalOptions: DropdownOption[] = [
    { text: '1', value: '1' },
    { text: '6', value: '6' },
    { text: '12', value: '12' },
    { text: '24', value: '24' },
    {
      text: t('settings.tiles.smart_groups_config.page.custom_interval'),
      value: 'custom'
    }
  ];

  useEffect(() => {
    const init = async () => {
      const settings = await initRequest.send(getSettings(['smart_groups_update_interval']));
      if (
        settings.smart_groups_update_interval &&
        [1, 6, 12, 24].includes(Number(settings.smart_groups_update_interval))
      ) {
        setValue('smart_groups_update_interval', settings.smart_groups_update_interval);
      } else {
        setValue('smart_groups_update_interval', 'custom');
        setValue('custom_interval', Number(settings.smart_groups_update_interval));
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs />
      <form className={css.Form} onSubmit={handleFormSubmit}>
        <h5 className={css.SettingsTitle}>{t('settings.tiles.smart_groups_config.name')}</h5>
        <Dropdown
          options={intervalOptions}
          label={t('settings.tiles.smart_groups_config.page.smart_groups_update_interval')}
          className={css.Input}
          register={register(`smart_groups_update_interval`)}
        />
        {watchCustomInterval === 'custom' && (
          <TextInput
            type={TextInputType.INTEGER}
            register={register('custom_interval')}
            label={t('settings.tiles.smart_groups_config.page.custom_interval')}
            className={css.Input}
            errorText={formState.errors.custom_interval?.message}
          />
        )}

        <div className={css.ActionBtnGroup}>
          <Button
            className={css.ActionBtn}
            type="submit"
            theme="primary"
            isLoading={updateRequest.loading}
            isDisabled={
              !isAllowedTo(Permission.EditGroups) || !isAllowedTo(Permission.EditAdministration)
            }
          >
            {t('settings.tiles.smart_groups_config.page.update_btn')}
          </Button>
        </div>
      </form>
    </div>
  );
};
