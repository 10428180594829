import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import css from './device-users.module.scss';
import {
  CreateDeviceUserRequestDto,
  DeviceUserSimpleDto,
  UpdateDeviceUserRequestDto
} from '../../../types/api';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { DeviceUsersForm } from './components/user-form/device-user-form.component';
import { DeviceUserFormValues } from './components/user-form/device-user-form.schema';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import useRequest from '../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../constants';
import {
  createDeviceUser,
  updateDeviceUser,
  deleteDeviceUser,
  getDeviceUser
} from '../../../api/device-users';

export const DeviceUsersComponent: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const crudRequest = useRequest<DeviceUserSimpleDto>();
  const userRef = useRef<DeviceUserSimpleDto>();

  const [isUserFormShown, setUserFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');
  const [userFormValues, setUserFormValues] = useState<DeviceUserFormValues>();

  const userToForm = (input: DeviceUserSimpleDto): DeviceUserFormValues => {
    return {
      username: input.username,
      ...(input?.email && { email: input?.email }),
      ...(input?.employee_name && { employee_name: input?.employee_name }),
      ...(input?.phone_number && { phone_number: input?.phone_number }),
      ...(input?.position && { position: input?.position }),
      computers_count: input.computers_count,
      mobile_devices_count: input.mobile_devices_count
    };
  };

  const handleUserCreate = async (values: DeviceUserFormValues) => {
    if (crudRequest.loading) return;

    const request: CreateDeviceUserRequestDto = {
      username: values.username,
      ...(values.email && { email: values.email }),
      ...(values.employee_name && { employee_name: values.employee_name }),
      ...(values.phone_number && { phone_number: values.phone_number }),
      ...(values.position && { position: values.position })
    };
    try {
      const response = await crudRequest.send(createDeviceUser(request));
      setUserFormValues(values);
      setFormMode('view');
      navigate(`/${Paths.DEVICE_USER.replace(':id', response.id)}`);
      toast.success(
        t('device_users.user_form.responses.successfully_created', {
          username: values.username
        }),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  const handleUserEdit = () => setFormMode('edit');
  const handleCancel = () => setFormMode('view');
  const handleCancelCreation = () => {
    navigate(`/${Paths.DEVICE_USER_LIST}`);
  };

  const handleUserSave = async (values: Partial<DeviceUserFormValues>) => {
    if (crudRequest.loading || !userRef.current) return;

    const request: UpdateDeviceUserRequestDto = {
      username: values.username,
      email: values.email,
      employee_name: values.employee_name,
      phone_number: values.phone_number,
      position: values.position
    };
    try {
      const response = await crudRequest.send(updateDeviceUser(userRef.current?.id, request));
      setUserFormValues(userToForm(response));
      setFormMode('view');
      toast.success(
        t('device_users.user_form.responses.successfully_updated', {
          username: response.username
        }),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  const handleUserDelete = async () => {
    if (crudRequest.loading || !userRef.current) return;
    try {
      const response = await crudRequest.send(deleteDeviceUser(userRef.current?.id));
      navigate(`/${Paths.DEVICE_USER_LIST}`);
      toast.success(
        t('device_users.user_form.responses.successfully_deleted', {
          username: response.username
        }),
        {
          autoClose: 5000
        }
      );
    } catch (e) {
      toast.error(getLocalizedErrorString(e as Error), {
        autoClose: 5000
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      try {
        if (!isNew) {
          const response = await getDeviceUser(id);
          userRef.current = response;
          setUserFormValues(userToForm(response));
        }
        setUserFormShown(true);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toast.error(localizedErrorString, {
          autoClose: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Breadcrumbs
        title={isNew ? t('device_users.user_form.new_title') : userRef.current?.username}
      />
      {isUserFormShown && (
        <DeviceUsersForm
          mode={formMode}
          values={userFormValues}
          onCreate={handleUserCreate}
          onEdit={handleUserEdit}
          onSave={handleUserSave}
          onDelete={handleUserDelete}
          onCancel={handleCancel}
          onCancelCreation={handleCancelCreation}
        />
      )}
    </div>
  );
};
