import { FC, FormEvent, useEffect, useState } from 'react';
import { getInactiveLicenseList, getLicenses, uploadLicense } from '../../../../api/settings';
import { useTranslation } from 'react-i18next';
import { DeviceOsType, LicenseDto, UploadLicenseFileRequestDto } from '../../../../types/api';
import { formatDate } from '../../../../utils/time.utils';
import { LicenseListComponent } from './settings-license-list';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { TabItem, TabNavbar } from '../../../components/tab/tab-navbar/tab-navbar.component';
import { LicenseTab } from './settings-license-form';
import { getTotalEnrolled } from '../../../../api/device';
import { useForm } from 'react-hook-form';
import { UploadLicenseForm, UploadLicenseFormSchema } from './settings-license.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { getLocalizedErrorString } from '../../../../utils/localize-error';

export const SettingsLicense: FC = () => {
  const [activeTab, setActiveTab] = useState<DeviceOsType>(DeviceOsType.MacOS);
  const [inactiveLicenses, setInactiveLicenses] = useState<LicenseDto[]>();

  const [macOSLicense, setMacOSLicense] = useState<LicenseDto | null>(null);
  const [expirationDateMacOSLicense, setExpirationDateMacOSLicense] = useState('');
  const [macOSLicenseActivationPeriod, setMacOSLicenseActivationPeriod] = useState(0);
  const [totalMacOSEnrolled, setTotalMacOSEnrolled] = useState<number>(0);

  const [iOSLicense, setIOSLicense] = useState<LicenseDto | null>(null);
  const [expirationDateIOSLicense, setExpirationDateIOSLicense] = useState('');
  const [iOSLicenseActivationPeriod, setIOSLicenseActivationPeriod] = useState(0);
  const [totalIOSEnrolled, setTotalIOSEnrolled] = useState<number>(0);

  const { t } = useTranslation();

  const licenseForm = useForm<UploadLicenseForm>({
    mode: 'onChange',
    resolver: yupResolver(UploadLicenseFormSchema),
    defaultValues: UploadLicenseFormSchema.getDefault()
  });

  const { trigger, getValues } = licenseForm;

  const tabs: TabItem[] = [
    { key: DeviceOsType.MacOS, title: t('settings.tiles.license.license_os_tab.macos_license') },
    {
      key: DeviceOsType.IOS,
      title: t('settings.tiles.license.license_os_tab.ios_license')
    }
  ];

  const handleChangeTab = (tab: string) => {
    if (tab === DeviceOsType.IOS) setActiveTab(DeviceOsType.IOS);
    if (tab === DeviceOsType.MacOS) setActiveTab(DeviceOsType.MacOS);
  };

  const init = async () => {
    try {
      const licenses = await getLicenses();
      if (licenses.macos_license) setMacOSLicense(licenses.macos_license);
      if (licenses.ios_license) setIOSLicense(licenses.ios_license);

      const inactiveLicenseList = await getInactiveLicenseList();
      setInactiveLicenses(inactiveLicenseList.licenses);

      const macOSEnrolled = await getTotalEnrolled({ os_type: DeviceOsType.MacOS });
      setTotalMacOSEnrolled(macOSEnrolled.count);
      const iOSEnrolled = await getTotalEnrolled({ os_type: DeviceOsType.IOS });
      setTotalIOSEnrolled(iOSEnrolled.count);

      const expDateMacOSLicense = licenses.macos_license?.expires_at;
      if (expDateMacOSLicense) {
        setExpirationDateMacOSLicense(formatDate(expDateMacOSLicense, 'dd-MM-yyyy'));
        const diff = new Date(expDateMacOSLicense).getTime() - new Date().getTime();
        setMacOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
      }
      const expDateIOSLicense = licenses.ios_license?.expires_at;
      if (expDateIOSLicense) {
        setExpirationDateIOSLicense(formatDate(expDateIOSLicense, 'dd-MM-yyyy'));
        const diff = new Date(expDateIOSLicense).getTime() - new Date().getTime();
        setIOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
      }
    } catch (e) {
      setMacOSLicense(null);
      setIOSLicense(null);
    }
  };

  const handleFormSubmit = async (event?: FormEvent) => {
    event?.preventDefault();
    const isValid = await trigger();

    if (!isValid) return;
    const values = getValues();
    const licenseFile = values.license[0];
    try {
      const request: UploadLicenseFileRequestDto = {
        os_type: activeTab,
        license: licenseFile
      };

      const license = await uploadLicense(request);
      toast.success(t('settings.tiles.upload_certificate.page.upload_success'), {
        autoClose: 5000
      });
      if (activeTab === DeviceOsType.MacOS) {
        setMacOSLicense(license);
        const expDateMacOSLicense = license.expires_at;
        if (expDateMacOSLicense) {
          setExpirationDateMacOSLicense(formatDate(expDateMacOSLicense, 'dd-MM-yyyy'));
          const diff = new Date(expDateMacOSLicense).getTime() - new Date().getTime();
          setMacOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
        }
      }
      if (activeTab === DeviceOsType.IOS) {
        setIOSLicense(license);
        const expDateIOSLicense = license.expires_at;
        if (expDateIOSLicense) {
          setExpirationDateIOSLicense(formatDate(expDateIOSLicense, 'dd-MM-yyyy'));
          const diff = new Date(expDateIOSLicense).getTime() - new Date().getTime();
          setIOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
        }
      }
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(localizedError);
      toast.error(localizedError, { autoClose: 5000 });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div>
      <Breadcrumbs />
      <TabNavbar tabs={tabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
      {activeTab === DeviceOsType.MacOS && (
        <LicenseTab
          form={licenseForm}
          licenseOSTab={activeTab}
          expirationDate={expirationDateMacOSLicense}
          activationPeriod={macOSLicenseActivationPeriod}
          totalDevicesEnrolled={totalMacOSEnrolled}
          license={macOSLicense}
          onSubmit={handleFormSubmit}
        />
      )}
      {activeTab === DeviceOsType.IOS && (
        <LicenseTab
          form={licenseForm}
          licenseOSTab={activeTab}
          expirationDate={expirationDateIOSLicense}
          activationPeriod={iOSLicenseActivationPeriod}
          totalDevicesEnrolled={totalIOSEnrolled}
          license={iOSLicense}
          onSubmit={handleFormSubmit}
        />
      )}
      <hr />
      {inactiveLicenses && <LicenseListComponent allLicenses={inactiveLicenses} />}
    </div>
  );
};
