import { ChangeEventHandler, FC, useState } from 'react';
import cn from 'classnames';
import css from './file-input.module.scss';
import { WithClassname, WithInputProps } from '../../../types/common';
import { useTranslation } from 'react-i18next';

interface IProps {
  value?: FileList;
  label?: string;
  subLabel?: string;
  btnLabel?: string;
  errorText?: string;
  accept?: string;
}

export const FileInput: FC<IProps & WithInputProps & WithClassname> = (props) => {
  const { t } = useTranslation();
  const {
    className,
    label,
    subLabel,
    value,
    errorText,
    accept,
    btnLabel = t('common.file_input.default_upload_btn'),
    register,
    onChange = () => {}
  } = props;
  const [filename, setFilename] = useState<string | undefined>(value?.[0]?.name);

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    setFilename(event.target.files?.[0]?.name || '');
    const patched = register?.onChange || onChange;
    if (patched) {
      await patched(event);
    }
  };

  return (
    <label className={cn(className, css.Root, 'form-group')}>
      {label && <p className={css.Label}>{label}</p>}
      {subLabel && <p className={css.SubLabel}>{subLabel}</p>}
      <div className={cn(css.InputGroup, 'input-group')}>
        <div className={cn(css.FileName, 'form-control')}>{filename}</div>
        <input
          {...register}
          onChange={handleChange}
          type="file"
          accept={accept}
          className={css.FileInput}
        />
        <div className="input-group-append">
          <span className="input-group-text">{btnLabel}</span>
        </div>
      </div>
      {errorText && <span className={cn(css.Error)}>{errorText}</span>}
    </label>
  );
};
