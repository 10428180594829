import { CreateConfigProfileScopeRequestDto, ScopeEntryOperationType } from '../../../types/api';
import { ScopeValues } from '../../components/scope-form/scope-form.component';

export function formScopeEntries(scope?: ScopeValues): {
  scope_entries: CreateConfigProfileScopeRequestDto[];
} {
  const scopeEntriesInclude: CreateConfigProfileScopeRequestDto[] =
    scope?.scopeEntriesInclude.map((entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Include
    })) || [];
  const scopeEntriesExclude: CreateConfigProfileScopeRequestDto[] =
    scope?.scopeEntriesExclude.map((entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Exclude
    })) || [];
  const scopeEntries = [...(scope?.scoped ? scopeEntriesInclude : []), ...scopeEntriesExclude];
  return {
    scope_entries: scopeEntries
  };
}
