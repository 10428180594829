import { FC } from 'react';
import css from './provisioning-profiles-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../../../../../utils/time.utils';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const ProvisioningProfilesTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  return (
    <div className={css.Root}>
      <table className={css.ProvisioningProfilesTable}>
        <thead>
          <tr>
            <th>
              {t(
                'inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.table.heading.name'
              )}
            </th>
            <th>
              {t(
                'inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.table.heading.uuid'
              )}
            </th>
            <th>
              {t(
                'inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.table.heading.expiry_date'
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {device?.provisioning_profiles?.map((i) => (
            <tr key={i.id}>
              <td title={i.name ? i.name : ''}>{i.name}</td>
              <td title={i.uuid ? i.uuid : ''}>{i.uuid}</td>
              <td title={i.expiry_date ? formatDate(i.expiry_date) : ''}>
                {i.expiry_date ? formatDate(i.expiry_date) : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
