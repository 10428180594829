import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import css from './extension-attribute.module.scss';
import useRequest from '../../../../hooks/useRequest';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { ExtensionAttributeFormValues } from './components/extension-attribute-form/extension-attribute-form.schema';
import { ExtensionAttributeFormComponent } from './components/extension-attribute-form/extension-attribute-form.component';
import { SettingsPaths } from '../settings.const';
import { Paths } from '../../../constants';
import {
  CreateExtensionAttributeDto,
  ExtensionAttributeFullDto,
  UpdateExtensionAttributeDto
} from '../../../../types/api';
import {
  createExtensionAttribute,
  deleteExtensionAttribute,
  getExtensionAttribute,
  updateExtensionAttribute
} from '../../../../api/extension-attributes';

export const ExtensionAttribute: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const crudRequest = useRequest<ExtensionAttributeFullDto>();
  const extensionAttributeRef = useRef<ExtensionAttributeFullDto>();

  const [isExtensionAttributeFormShown, setExtensionAttributeFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');
  const [extensionAttributeFormValues, setExtensionAttributeFormValues] =
    useState<ExtensionAttributeFormValues>();

  const extensionAttributeToForm = (
    input: ExtensionAttributeFullDto
  ): ExtensionAttributeFormValues => {
    const options = input.options ? input.options.map((option) => ({ value: option })) : null;

    return {
      display_name: input.display_name,
      description: input.description,
      data_type: input.data_type,
      inventory_display: input.inventory_display,
      input_type: input.input_type,
      options,
      script: input.script
    };
  };

  const handleExtensionAttributeCreate = async (values: ExtensionAttributeFormValues) => {
    if (crudRequest.loading) return;

    const request: CreateExtensionAttributeDto = {
      display_name: values.display_name,
      ...(values.description && { description: values.description }),
      data_type: values.data_type,
      inventory_display: values.inventory_display,
      input_type: values.input_type,
      ...(values.options && { options: values.options.map((option) => option.value) }),
      ...(values.script && { script: values.script })
    };

    try {
      const response = await crudRequest.send(createExtensionAttribute(request));
      setFormMode('view');
      navigate(
        `/${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTE.replace(':id', response.id)}`
      );
      setExtensionAttributeFormValues(extensionAttributeToForm(response));

      toast.success(
        t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.successfully_created',
          {
            display_name: values.display_name
          }
        ),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  const handleExtensionAttributeEdit = () => setFormMode('edit');
  const handleCancel = () => setFormMode('view');

  const handleExtensionAttributeSave = async (values: Partial<ExtensionAttributeFormValues>) => {
    if (crudRequest.loading || !extensionAttributeRef.current || !values) return;

    const request: UpdateExtensionAttributeDto = {
      ...(values.display_name && {
        display_name: values.display_name
      }),
      ...(values.description && { description: values.description }),
      ...(values.data_type && { data_type: values.data_type }),
      ...(values.inventory_display && { inventory_display: values.inventory_display }),
      ...(values.input_type && { input_type: values.input_type }),
      ...(values.options && { options: values.options.map((option) => option.value) }),
      ...(values.script && { script: values.script })
    };

    try {
      const response = await crudRequest.send(
        updateExtensionAttribute(extensionAttributeRef.current?.id, request)
      );
      extensionAttributeRef.current = response;
      setExtensionAttributeFormValues(extensionAttributeToForm(response));
      setFormMode('view');
      navigate(
        `/${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTE.replace(':id', response.id)}`
      );
      toast.success(
        t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.successfully_updated',
          {
            display_name: response.display_name
          }
        ),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  const handleExtensionAttributeDelete = async () => {
    if (crudRequest.loading || !extensionAttributeRef.current) return;
    try {
      const response = await crudRequest.send(
        deleteExtensionAttribute(extensionAttributeRef.current?.id)
      );
      navigate(`/${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTES_LIST}`);
      toast.success(
        t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.successfully_deleted',
          {
            display_name: response.display_name
          }
        ),
        {
          autoClose: 5000
        }
      );
    } catch (e) {
      toast.error(getLocalizedErrorString(e as Error), {
        autoClose: 5000
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      try {
        if (!isNew) {
          const response = await getExtensionAttribute(id);
          extensionAttributeRef.current = response;
          setExtensionAttributeFormValues(extensionAttributeToForm(response));
        }
        setExtensionAttributeFormShown(true);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toast.error(localizedErrorString, {
          autoClose: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Breadcrumbs title={extensionAttributeRef.current?.display_name} />
      {isExtensionAttributeFormShown && (
        <ExtensionAttributeFormComponent
          mode={formMode}
          values={extensionAttributeFormValues}
          onCreate={handleExtensionAttributeCreate}
          onEdit={handleExtensionAttributeEdit}
          onSave={handleExtensionAttributeSave}
          onDelete={handleExtensionAttributeDelete}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};
