import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import {
  AddDeviceApplicationDto,
  DeviceApplicationDto,
  DeviceOsType,
  SearchDeviceApplication
} from '../../../types/api';
import { MobileDeviceAppAssistant } from './components/device-application-assistant/device-application-assistant.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { toast } from 'react-toastify';
import { DeviceApplicationFormValues } from './components/device-application-assistant/device-application-form/device-application-form.schema';
import {
  createDeviceApplication,
  deleteDeviceApplication,
  getDeviceApplication,
  updateDeviceApplication
} from '../../../api/device-applications';
import { DeviceApplicationForm } from './components/device-application-assistant/device-application-form/device-application-form';
import useRequest from '../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { appToForm, formScopeEntries } from './device-application.utils';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export const DeviceApplication: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceType } = useDeviceSection();

  const crudRequest = useRequest<DeviceApplicationDto>();
  const appRef = useRef<DeviceApplicationDto>();

  const [isAppFormShown, setAppFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit' | 'add'>('view');
  const [appFormValues, setAppFormValues] = useState<DeviceApplicationFormValues>();
  const [isLoading, setIsLoading] = useState(false);

  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const handleEdit = () => setFormMode('edit');
  const handleCancel = () => setFormMode('view');
  const handleDelete = async () => {
    setIsLoading(true);
    if (!id) return;
    try {
      const response = await crudRequest.send(deleteDeviceApplication(id));
      navigate('./../');
      toast.success(
        t('device_apps.app_form.responses.successfully_deleted', {
          display_name: response.display_name
        }),
        {
          autoClose: 5000
        }
      );
    } catch (e) {
      toast.error(getLocalizedErrorString(e as Error), {
        autoClose: 5000
      });
    }
    setIsLoading(false);
  };
  const handleSave = async (values: Partial<DeviceApplicationFormValues>) => {
    if (crudRequest.loading || !appRef.current) return;
    setIsLoading(true);
    const request = {
      os_type: osType,
      ...(values.display_name && { display_name: values.display_name }),
      ...(values.bundle_id && { bundle_id: values.bundle_id }),
      ...(values.app_view_url && { app_view_url: values.app_view_url }),
      ...(values.version && { version: values.version }),
      scoped: values.scope?.scoped,
      ...formScopeEntries(values.scope)
    };
    try {
      const response = await crudRequest.send(updateDeviceApplication(appRef.current?.id, request));
      setAppFormValues(appToForm(response));
      setAppFormShown(true);
      setFormMode('view');
      navigate(`./../${response.id}`);
      toast.success(
        t('device_apps.app_form.responses.successfully_updated', {
          display_name: response.display_name
        }),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
    setIsLoading(false);
  };

  const handleAddApp = async (row: SearchDeviceApplication) => {
    setFormMode('create');
    const dto: DeviceApplicationFormValues = {
      display_name: row.trackName,
      bundle_id: row.bundleId,
      free: row.price === 0,
      app_view_url: row.trackViewUrl,
      version: row.version,
      source: 'App Store'
    };
    setAppFormValues(dto);
    setAppFormShown(true);
  };

  const handleEnterManually = () => {
    setFormMode('create');
    setAppFormShown(true);
  };

  const saveEnteredDataManually = async (values: DeviceApplicationFormValues) => {
    setIsLoading(true);
    if (crudRequest.loading) return;

    const request: AddDeviceApplicationDto = {
      os_type: osType,
      display_name: values.display_name,
      bundle_id: values.bundle_id,
      app_view_url: values.app_view_url,
      source: 'App Store',
      version: values.version,
      free: true,
      scoped: values.scope?.scoped,
      ...formScopeEntries(values.scope)
    };

    try {
      const response = await crudRequest.send(createDeviceApplication(request));
      setAppFormValues(response);
      setFormMode('view');
      appRef.current = response;
      navigate(`./../${response.id}`);
      toast.success(
        t('device_apps.app_form.responses.successfully_created', {
          display_name: response.display_name
        }),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (!isNew && id) {
          const response = await getDeviceApplication(id);
          appRef.current = response;
          setAppFormValues(appToForm(response));
          setAppFormShown(true);
        }
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toast.error(localizedErrorString, {
          autoClose: 5000
        });
      }
    };
    void init();
  }, [id, formMode]);

  return (
    <div>
      <Breadcrumbs
        title={isNew ? t('device_apps.app_list.new_app') : appRef.current?.display_name}
      />
      {isAppFormShown && (
        <DeviceApplicationForm
          mode={formMode}
          setMode={setFormMode}
          values={appFormValues}
          onCreate={saveEnteredDataManually}
          onEdit={handleEdit}
          onSave={handleSave}
          onDelete={handleDelete}
          onCancel={handleCancel}
          isLoading={isLoading}
          disabled={formMode === 'view'}
        />
      )}
      {id === 'new' && (
        <MobileDeviceAppAssistant
          handleAddApp={handleAddApp}
          setMode={setFormMode}
          setAppFormShown={setAppFormShown}
          onEnterManually={handleEnterManually}
        />
      )}
    </div>
  );
};
