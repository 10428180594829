import { FC, FormEvent, useEffect, useState } from 'react';
import css from './settings-package.module.scss';
import { TextInput } from '../../../components/text-input/text-input.component';
import { useNavigate, useParams } from 'react-router-dom';
import { CreatePackageDto, PackageSimpleDto, Permission } from '../../../../types/api';
import { createPackage, deletePackage, getPackage, updatePackage } from '../../../../api/packages';
import { Button } from '../../../components/button/button.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PackageForm, packageFormSchema } from './settings-package.schema';
import useRequest from '../../../../hooks/useRequest';
import { Modal } from '../../../components/modal/modal.component';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';
import { toast } from 'react-toastify';

export const SettingsPackage: FC = () => {
  const { id } = useParams();
  const crudRequest = useRequest<PackageSimpleDto>();
  const navigate = useNavigate();
  const { register, formState, setValue, trigger, getValues, reset } = useForm<PackageForm>({
    mode: 'onChange',
    resolver: yupResolver(packageFormSchema),
    defaultValues: packageFormSchema.getDefault()
  });
  const [pkg, setPkg] = useState<PackageSimpleDto>();
  const [isEdit, setIsEdit] = useState(false);
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState<PackageForm>();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const isNew = id === 'new';
  const isDisabled = !isNew && !isEdit;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = async (event: FormEvent) => {
    if (!id || crudRequest.loading) return;
    event.preventDefault();
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: CreatePackageDto = {
        display_name: values.display_name,
        filename: values.filename
      };
      if (isEdit) {
        const updated = await crudRequest.send(updatePackage(id, requestBody));
        setPkg(updated);
        setValue('display_name', updated.display_name);
        setValue('filename', updated.filename);
        setIsEdit(false);
      } else {
        const created = await crudRequest.send(createPackage(requestBody));
        setPkg(created);
        navigate(`/settings/packages/${created.id}`, { replace: true });
        setValue('display_name', created.display_name);
        setValue('filename', created.filename);
      }
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
  };

  const handleDeleteModalOpen = () => setIsDeleteModalShown(true);
  const handleDeleteModalClose = () => {
    if (crudRequest.loading) return;
    setIsDeleteModalShown(false);
  };
  const handleDeleteModalSubmit = async () => {
    if (!id || crudRequest.loading) return;
    try {
      await crudRequest.send(deletePackage(id));
      navigate(`/settings/packages`, { replace: true });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    handleDeleteModalClose();
  };

  const handleEditRequest = () => {
    setValuesBeforeEdit(getValues());
    setIsEdit(true);
  };
  const handleCancel = () => {
    if (isNew) {
      navigate(`/settings/packages`);
      return;
    }
    reset(valuesBeforeEdit);
    setIsEdit(false);
  };

  useEffect(() => {
    const init = async () => {
      if (!id || id === 'new') return;
      try {
        const result = await getPackage(id);
        setPkg(result);
        setValue('display_name', result.display_name);
        setValue('filename', result.filename);
      } catch {
        navigate(`/settings/packages`, { replace: true });
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs title={pkg?.display_name} />
      {(pkg || isNew) && (
        <form className={css.Form} onSubmit={handleFormSubmit}>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditPackages)}>
            <h5 className={css.Title}>
              {isNew ? t('settings.tiles.packages.page.package_page.new_title') : pkg?.display_name}
            </h5>
            <TextInput
              className={css.Input}
              label={t('settings.tiles.packages.page.package_page.title')}
              required
              register={register('display_name')}
              errorText={formState.errors.display_name?.message}
              disabled={isDisabled}
            />
            <TextInput
              className={css.Input}
              label={t('settings.tiles.packages.page.package_page.filename')}
              required
              register={register('filename')}
              errorText={formState.errors.filename?.message}
              disabled={isDisabled}
            />
            <div className={css.ActionBtnGroup}>
              {!isNew && !isEdit && (
                <Button className={css.ActionBtn} theme="primary" onClick={handleEditRequest}>
                  {t('settings.tiles.packages.page.package_page.edit_btn')}
                </Button>
              )}
              {(isNew || isEdit) && (
                <Button
                  className={css.ActionBtn}
                  type="submit"
                  theme="primary"
                  isLoading={crudRequest.loading}
                >
                  {isNew
                    ? t('settings.tiles.packages.page.package_page.create_btn')
                    : t('settings.tiles.packages.page.package_page.submit_btn')}
                </Button>
              )}
              {(isNew || isEdit) && (
                <Button theme="danger" className={css.ActionBtn} onClick={handleCancel}>
                  {t('settings.tiles.packages.page.package_page.cancel_btn')}
                </Button>
              )}
              {!isNew && !isEdit && (
                <Button
                  className={css.ActionBtn}
                  isLoading={crudRequest.loading}
                  theme="danger"
                  onClick={handleDeleteModalOpen}
                >
                  {t('settings.tiles.packages.page.package_page.delete_btn')}
                </Button>
              )}
            </div>
          </fieldset>
        </form>
      )}
      <Modal
        title={t('settings.tiles.packages.page.package_page.delete_modal.title')}
        submitButtonName={t('settings.tiles.packages.page.package_page.delete_modal.delete_btn')}
        isOpen={isDeleteModalShown}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
        isLoading={crudRequest.loading}
      >
        <p>
          {t('settings.tiles.packages.page.package_page.delete_modal.message', {
            package_name: pkg?.display_name
          })}
        </p>
      </Modal>
    </div>
  );
};
