import { FC } from 'react';
import css from './os-info-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../../../../../utils/time.utils';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const OsInfoTableMobile: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  const di = device?.device_information;
  return (
    <table className={css.Root}>
      <tbody>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.os_version')}
          </td>
          <td>{di?.os_version || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.rapid_security_response'
            )}
          </td>
          <td>{di?.os_rapid_security_response || '---'}</td>
        </tr>
        <tr>
          <td>
            {t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.os_build')}
          </td>
          <td>{di?.os_build_version || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.supplemental_build_version'
            )}
          </td>
          <td>{di?.supplemental_build_version || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.icloud_backup'
            )}
          </td>
          <td>
            {isBoolean(di?.is_cloud_backup_enabled)
              ? di?.is_cloud_backup_enabled
                ? t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.enabled_f')
                : t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.disabled_f')
              : '---'}
          </td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.last_icloud_backup'
            )}
          </td>
          <td>{(di?.last_cloud_backup_date && formatDate(di?.last_cloud_backup_date)) || '---'}</td>
        </tr>
        <tr>
          <td>
            {t(
              'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.is_do_not_disturb_in_effect'
            )}
          </td>
          <td>
            {' '}
            {isBoolean(di?.is_do_not_disturb_in_effect)
              ? di?.is_do_not_disturb_in_effect
                ? t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.enabled_m')
                : t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.disabled_m')
              : '---'}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
