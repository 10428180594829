import { FC, useEffect, useState } from 'react';
import css from './smart-group-device-users.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartGroupDevicesForm } from './components/smart-group-devices-form/smart-group-device-users-form.component';
import { toast } from 'react-toastify';
import {
  CreateSmartGroupDeviceUsersRequestDto,
  SmartGroupDeviceUsersFullDto,
  UpdateSmartGroupDeviceUsersRequestDto
} from '../../../types/api';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { SmartGroupDeviceUsersFormValues } from './components/smart-group-devices-form/smart-group-device-users-form.schema';
import {
  createSmartGroup,
  deleteSmartGroup,
  getSmartGroup,
  updateSmartGroup
} from '../../../api/smart-group-device-users';

export const SmartGroupDeviceUsers: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [smartGroup, setSmartGroup] = useState<SmartGroupDeviceUsersFullDto>();
  const [smartGroupValues, setSmartGroupValues] = useState<SmartGroupDeviceUsersFormValues>();
  const [isSmartGroupFormShown, setIsSmartGroupFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'update' | 'edit'>('view');

  const smartGroupToForm = (
    input: SmartGroupDeviceUsersFullDto
  ): SmartGroupDeviceUsersFormValues => {
    return {
      group: {
        display_name: input.title
      },
      criteria: input.conditions,
      reports: []
    };
  };

  const handleSmartGroupCreate = async (values: SmartGroupDeviceUsersFormValues) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const request: CreateSmartGroupDeviceUsersRequestDto = {
        title: values.group.display_name,
        conditions: values.criteria
      };
      const response = await createSmartGroup(request);
      setSmartGroup(response);
      setSmartGroupValues(smartGroupToForm(response));
      setFormMode('view');
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleSmartGroupDelete = async () => {
    if (isLoading || !smartGroup) return;
    setIsLoading(true);
    try {
      await deleteSmartGroup(smartGroup.id);
      navigate('./../');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const handleSmartGroupEdit = () => {
    setFormMode('edit');
  };

  const handleSmartGroupCancel = () => {
    setFormMode('view');
  };

  const handleSmartGroupUpdate = async (values: SmartGroupDeviceUsersFormValues) => {
    if (isLoading || !smartGroup) return;
    setIsLoading(true);
    try {
      const request: UpdateSmartGroupDeviceUsersRequestDto = {
        title: values.group.display_name,
        conditions: values.criteria
      };
      const response = await updateSmartGroup(smartGroup.id, request);
      setFormMode('view');
      setSmartGroupValues(smartGroupToForm(response));
      navigate(`./../${response.id}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    setIsLoading(false);
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const response = await getSmartGroup(id);
      setSmartGroup(response);
      setSmartGroupValues(smartGroupToForm(response));
    }
    setIsSmartGroupFormShown(true);
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Breadcrumbs title={smartGroup?.title} />
      {isSmartGroupFormShown && (
        <SmartGroupDevicesForm
          mode={id === 'new' ? 'create' : formMode}
          values={smartGroupValues}
          onCreate={handleSmartGroupCreate}
          onDelete={handleSmartGroupDelete}
          onEdit={handleSmartGroupEdit}
          onSave={handleSmartGroupUpdate}
          onCancel={handleSmartGroupCancel}
        />
      )}
    </div>
  );
};
