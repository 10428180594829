import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import css from './user.module.scss';
import {
  CreateUserRequestDto,
  UpdateUserRequestDto,
  UserOriginType,
  UserSimpleDto
} from '../../../../types/api';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { createUser, deleteUser, getUser, updateUser } from '../../../../api/users';
import { UserLocalFormComponent } from './components/user-local-form/user-local-form.component';
import { UserLocalFormValues } from './components/user-local-form/user-local-form.schema';
import { getRoles } from '../../../../api/roles';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { SettingsPaths } from '../settings.const';
import { Paths } from '../../../constants';
import { Button } from '../../../components/button/button.component';
import { UserLdapFormComponent } from './components/user-ldap-form/user-ldap-form.component';
import { UserLdapFormValues } from './components/user-ldap-form/user-ldap-form.schema';

export interface IRole {
  id: string;
  title: string;
}

export const UserComponent: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const crudRequest = useRequest<UserSimpleDto>();
  const userRef = useRef<UserSimpleDto>();

  const [isUserFormShown, setUserFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');
  const [userLocalFormValues, setUserLocalFormValues] = useState<UserLocalFormValues>();
  const [userLdapFormValues, setUserLdapFormValues] = useState<UserLdapFormValues>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [userType, setUserType] = useState<UserOriginType>();

  const userLocalToForm = (input: UserSimpleDto): UserLocalFormValues => {
    return {
      username: input.username,
      display_name: input.display_name,
      role: { id: input.role.id, title: input.role.title },
      password: '',
      confirmPassword: ''
    };
  };

  const userLdapToForm = (input: UserSimpleDto): UserLdapFormValues => {
    return {
      username: input.username,
      display_name: input.display_name,
      role_id: input.role.id
    };
  };

  const handleAddModeChange = (type: UserOriginType) => () => {
    setUserType(type);
  };
  const handleLocalUserCreate = async (values: UserLocalFormValues) => {
    if (crudRequest.loading) return;

    const request: CreateUserRequestDto = {
      username: values.username,
      password: values.password,
      role: values.role.id,
      origin_type: UserOriginType.Local,
      ...(values.display_name && { display_name: values.display_name })
    };
    try {
      const response = await crudRequest.send(createUser(request));
      setUserLocalFormValues(userLocalToForm(response));
      setFormMode('view');
      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toast.success(
        t('settings.tiles.users.page.user_page.successfully_created', {
          username: values.username
        }),
        { autoClose: 5000 }
      );
    } catch (error) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };
  const handleLdapUserCreate = async (values: UserLdapFormValues) => {
    if (crudRequest.loading) return;

    const request: CreateUserRequestDto = {
      username: values.username,
      role: values.role_id,
      origin_type: UserOriginType.Ldap,
      ...(values.display_name && { display_name: values.display_name })
    };
    try {
      const response = await crudRequest.send(createUser(request));
      setUserLdapFormValues(userLdapToForm(response));
      setFormMode('view');
      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toast.success(
        t('settings.tiles.users.page.user_page.successfully_created', {
          username: values.username
        })
      );
    } catch (error) {
      toast.error(String(error));
    }
  };

  const handleUserEdit = () => setFormMode('edit');
  const handleCancel = () => setFormMode('view');

  const handleLocalUserSave = async (values: Partial<UserLocalFormValues>) => {
    if (crudRequest.loading || !userRef.current) return;

    const request: UpdateUserRequestDto = {
      ...(values.username && { username: values.username }),
      ...(values.password && { password: values.password }),
      ...(values.role && { role: values.role.id }),
      ...(values.display_name && {
        display_name: values.display_name
      })
    };
    try {
      const response = await crudRequest.send(updateUser(userRef.current?.id, request));
      userRef.current = response;
      setUserLocalFormValues(userLocalToForm(response));
      setFormMode('view');
      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toast.success(
        t('settings.tiles.users.page.user_page.successfully_updated', {
          username: response.username
        }),
        { autoClose: 5000 }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  const handleLdapUserSave = async (values: Partial<UserLdapFormValues>) => {
    if (crudRequest.loading || !userRef.current) return;

    const request: UpdateUserRequestDto = {
      ...(values.role_id && { role: values.role_id })
    };
    try {
      const response = await crudRequest.send(updateUser(userRef.current?.id, request));
      setUserLdapFormValues(userLdapToForm(response));
      setFormMode('view');
      navigate(`${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toast.success(
        t('settings.tiles.users.page.user_page.successfully_updated', {
          username: response.username
        }),
        { autoClose: 5000 }
      );
    } catch (error) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  const handleUserDelete = async () => {
    if (crudRequest.loading || !userRef.current) return;
    try {
      const response = await crudRequest.send(deleteUser(userRef.current?.id));
      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER_LIST}`);
      toast.success(
        t('settings.tiles.users.page.user_page.successfully_deleted', {
          username: response.username
        }),
        { autoClose: 5000 }
      );
    } catch (error) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      try {
        if (!isNew) {
          const response = await getUser(id);
          userRef.current = response;
          setUserType(response.origin_type);
          if (response.origin_type === UserOriginType.Local) {
            setUserLocalFormValues(userLocalToForm(response));
          } else if (response.origin_type === UserOriginType.Ldap) {
            setUserLdapFormValues(userLdapToForm(response));
          }
        }
        const { roles: roleDtos } = await getRoles();
        setRoles(roleDtos.map((roleDto) => ({ id: roleDto.id, title: roleDto.title })));
        setUserFormShown(true);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toast.error(localizedErrorString, {
          autoClose: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Breadcrumbs
        title={
          isNew
            ? t('settings.tiles.users.page.user_page.new_title')
            : userRef.current?.display_name || userRef.current?.username
        }
      />
      {isNew && !userType && (
        <div className={css.SelectCreateTypeBlock}>
          <p>{t('settings.tiles.users.page.user_page.choose_create_type')}:</p>
          <Button
            className={css.SelectCreateTypeBtn}
            onClick={handleAddModeChange(UserOriginType.Local)}
          >
            {t('settings.tiles.users.page.user_page.choose_create_type_local')}
          </Button>
          <Button
            className={css.SelectCreateTypeBtn}
            onClick={handleAddModeChange(UserOriginType.Ldap)}
          >
            {t('settings.tiles.users.page.user_page.choose_create_type_ldap')}
          </Button>
        </div>
      )}
      {userType === UserOriginType.Local && isUserFormShown && (
        <UserLocalFormComponent
          mode={formMode}
          values={userLocalFormValues}
          roles={roles}
          onCreate={handleLocalUserCreate}
          onEdit={handleUserEdit}
          onSave={handleLocalUserSave}
          onDelete={handleUserDelete}
          onCancel={handleCancel}
        />
      )}
      {userType === UserOriginType.Ldap && isUserFormShown && (
        <UserLdapFormComponent
          mode={formMode}
          values={userLdapFormValues}
          roles={roles}
          onCreate={handleLdapUserCreate}
          onEdit={handleUserEdit}
          onSave={handleLdapUserSave}
          onDelete={handleUserDelete}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};
