import { FC, useEffect, useState } from 'react';
import { TextInput } from '../../../components/text-input/text-input.component';
import css from './settings-organization.module.scss';
import { getLicenses, getSettings } from '../../../../api/settings';
import { getTotalEnrolled } from '../../../../api/device';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { toast } from 'react-toastify';
import { DeviceOsType } from '../../../../types/api';

export const SettingsOrganization: FC = () => {
  const [organizationName, setOrganizationName] = useState<string>('');
  const [totalMacOSEnrolled, setTotalMacOSEnrolled] = useState<number>(0);
  const [totalIOSEnrolled, setTotalIOSEnrolled] = useState<number>(0);
  const [totalMacOSLicenses, setTotalMacOSLicenses] = useState<number>(0);
  const [totalIOSLicenses, setTotalIOSLicenses] = useState<number>(0);

  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      try {
        const settings = await getSettings(['organization_name']);
        setOrganizationName(settings.organization_name || '');
        const licenses = await getLicenses();
        setTotalMacOSLicenses(
          licenses.macos_license?.max_device ? licenses.macos_license.max_device : 0
        );
        setTotalIOSLicenses(licenses.ios_license?.max_device ? licenses.ios_license.max_device : 0);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        console.log(localizedErrorString);
        toast.error(localizedErrorString, { autoClose: 5000 });
      }
      try {
        const macOSEnrolled = await getTotalEnrolled({ os_type: DeviceOsType.MacOS });
        setTotalMacOSEnrolled(macOSEnrolled.count);
        const iOSEnrolled = await getTotalEnrolled({ os_type: DeviceOsType.IOS });
        setTotalIOSEnrolled(iOSEnrolled.count);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        console.log(localizedErrorString);
        toast.error(localizedErrorString, { autoClose: 5000 });
      }
    };
    void init();
  }, []);

  return (
    <div>
      <Breadcrumbs />
      <div className={css.Header}>
        <h5>{t('settings.tiles.organization_information.page.title')}</h5>
      </div>
      <div className={css.Content}>
        <TextInput
          label={t('settings.tiles.organization_information.page.organization_name')}
          value={organizationName}
          disabled={true}
          required
        />
        <TextInput
          label={t(
            'settings.tiles.organization_information.page.max_num_of_devices_in_macos_license'
          )}
          value={String(totalMacOSLicenses)}
          disabled={true}
          required
        />
        <TextInput
          label={t('settings.tiles.organization_information.page.macos_devices_enrolled')}
          value={String(totalMacOSEnrolled)}
          disabled
          required
        />
        <TextInput
          label={t(
            'settings.tiles.organization_information.page.max_num_of_devices_in_ios_license'
          )}
          value={String(totalIOSLicenses)}
          disabled={true}
          required
        />
        <TextInput
          label={t('settings.tiles.organization_information.page.ios_devices_enrolled')}
          value={String(totalIOSEnrolled)}
          disabled
          required
        />
      </div>
    </div>
  );
};
