import { FC, ReactNode, useState } from 'react';
import { WithChildren, WithClassname } from '../../../types/common';
import cn from 'classnames';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './collapsible.module.scss';

interface IProps {
  isOpenInit?: boolean;
  header?: ReactNode;
}

export const Collapsible: FC<IProps & WithClassname & WithChildren> = (props) => {
  const { className, isOpenInit, header = '', children } = props;
  const [isOpen, setIsOpen] = useState(isOpenInit === undefined ? true : isOpenInit);

  const handleHeaderClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cn(className, 'card')}>
      <div role="button" className={cn(css.Header, 'card-header')} onClick={handleHeaderClick}>
        <div className={css.HeaderContent}>{header}</div>
        <div className={cn(css.HeaderIcon, isOpen && css.HeaderIconFlipped)}>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
      </div>
      <div className={cn('card-body', css.Body, !isOpen && css.BodyCollapsed)}>{children}</div>
    </div>
  );
};
