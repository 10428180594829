import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';

export const filterChangedFormFields = <T extends FieldValues>(
  allFields: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>
): Partial<T> => {
  return Object.keys(dirtyFields).reduce((acc, currentField) => {
    return {
      ...acc,
      [currentField]: allFields[currentField]
    };
  }, {} as Partial<T>);
};
