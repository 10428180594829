import { FC, useEffect, useState } from 'react';
import css from './search-bar.module.scss';
import cn from 'classnames';
import { Button } from '../../../../components/button/button.component';
import {
  DeviceCriteriaDto,
  DeviceOsType,
  SearchDeviceByConditionDto
} from '../../../../../types/api';
import { useFieldArray, useForm } from 'react-hook-form';
import { searchDeviceFormSchema } from './search-bar-form.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown, DropdownOption } from '../../../../components/dropdown/dropdown.component';
import { TextInput, TextInputType } from '../../../../components/text-input/text-input.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { getCriteria } from '../../../../../api/device';
import useDeviceSection, { DeviceType } from '../../../../contexts/device-section.context';
import { deviceCriteriaTitles } from '../../../../../const/criteria-titles.const';

interface IProps {
  onSearch: (conditions: SearchDeviceByConditionDto[]) => void;
  onReset: () => void;
  isSearchPerformed: boolean;
}

interface ISearchDeviceFormData {
  criteriaNumber: number;
  conditions: SearchDeviceByConditionDto[];
}

export const SearchBar: FC<IProps> = (props: IProps) => {
  const { onSearch, onReset, isSearchPerformed } = props;
  const [isBarCollapsed, setIsBarCollapsed] = useState<boolean>(true);
  const [criteriaNumber, setCriteriaNumber] = useState<number>(1);
  const searchDeviceForm = {
    resolver: yupResolver(searchDeviceFormSchema),
    defaultValues: searchDeviceFormSchema.getDefault()
  };
  const { control, register, handleSubmit } = useForm<ISearchDeviceFormData>(searchDeviceForm);
  const { fields, append, remove } = useFieldArray({ name: 'conditions', control });
  const [criteria, setCriteria] = useState<DeviceCriteriaDto[]>();
  const [searchDeviceCriteria, setSearchDeviceCriteria] = useState<DropdownOption[]>([]);
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const andOrOptions: DropdownOption[] = [
    { text: t('inventory.filter.and_or.and'), value: 'and' },
    { text: t('inventory.filter.and_or.or'), value: 'or' }
  ];
  const startBracketOptions: DropdownOption[] = [
    { text: '', value: 'false' },
    { text: '(', value: 'true' }
  ];
  const endBracketOptions: DropdownOption[] = [
    { text: '', value: 'false' },
    { text: ')', value: 'true' }
  ];
  const operatorOptions: DropdownOption[] = [
    { text: t('inventory.filter.operator.is'), value: '=' },
    { text: t('inventory.filter.operator.is_not'), value: '!=' },
    { text: t('inventory.filter.operator.like'), value: 'like' },
    { text: t('inventory.filter.operator.not_like'), value: 'not like' },
    { text: '>', value: '>' },
    { text: '>=', value: '>=' },
    { text: '<', value: '<' },
    { text: '<=', value: '<=' }
  ];

  const handleRemoveCriterionClick = (index: number) => {
    setCriteriaNumber(criteriaNumber - 1);
    remove(index);
  };

  const handleAddCriterionClick = () => {
    setCriteriaNumber(criteriaNumber + 1);
    append({ criteria: deviceCriteriaTitles[0], operator: '=', value: '' });
  };

  const handleSearchCollapseClick = () => {
    setIsBarCollapsed(!isBarCollapsed);
  };

  const onSubmit = async (data: ISearchDeviceFormData) => {
    onSearch(data.conditions);
  };

  const fetchCriteria = async () => {
    const result = await getCriteria(osType, { forSearch: true });
    setCriteria(result.criteria);
  };

  useEffect(() => {
    if (searchDeviceCriteria.length === 0) {
      fetchCriteria().then(() => {
        const criteriaDropdownValues: DropdownOption[] = [];
        criteria?.forEach((criterion) => {
          criteriaDropdownValues.push({
            text: deviceCriteriaTitles[criterion.name]
              ? deviceCriteriaTitles[criterion.name]
              : criterion.name,
            value: criterion.name
          });
        });
        setSearchDeviceCriteria(criteriaDropdownValues);
      });
    }
  }, [criteriaNumber]);

  return (
    <form
      className={cn(css.Root, isBarCollapsed ? css.Collapsed : '')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={cn(css.FormFields, isBarCollapsed ? css.Hidden : '')}>
        <TextInput
          value={String(criteriaNumber)}
          register={register('criteriaNumber')}
          className={css.Hidden}
        />
        {fields.map((item, i) => {
          return i === 0 ? (
            <div className={css.CriterionContainer} key={`criterion${i}`}>
              <div className={css.ConjunctiveDropdown} />
              <Dropdown
                options={startBracketOptions}
                className={cn(css.Input, css.BracketDropdown)}
                register={register(`conditions.${i}.start_bracket`)}
              />
              <Dropdown
                options={searchDeviceCriteria}
                className={cn(css.Input, css.CriterionDropdown)}
                register={register(`conditions.${i}.criteria`)}
              />
              <Dropdown
                options={operatorOptions}
                className={cn(css.Input, css.OperatorDropdown)}
                register={register(`conditions.${i}.operator`)}
              />
              <TextInput
                className={cn(css.Input, css.ValueInput)}
                type={TextInputType.TEXT}
                register={register(`conditions.${i}.value`)}
              />
              <Dropdown
                options={endBracketOptions}
                className={cn(css.Input, css.BracketDropdown, css.End)}
                register={register(`conditions.${i}.end_bracket`)}
              />
              <Button isDisabled={i === 0} onClick={() => handleRemoveCriterionClick(i)}>
                {t('inventory.filter.delete_criterion_btn')}
              </Button>
            </div>
          ) : (
            <div className={css.CriterionContainer} key={`criterion${i}`}>
              <Dropdown
                options={andOrOptions}
                className={cn(css.Input, css.ConjunctiveDropdown)}
                register={register(`conditions.${i}.conjunctive`)}
              />
              <Dropdown
                options={startBracketOptions}
                className={cn(css.Input, css.BracketDropdown)}
                register={register(`conditions.${i}.start_bracket`)}
              />
              <Dropdown
                options={searchDeviceCriteria}
                className={cn(css.Input, css.CriterionDropdown)}
                register={register(`conditions.${i}.criteria`)}
              />
              <Dropdown
                options={operatorOptions}
                className={cn(css.Input, css.OperatorDropdown)}
                register={register(`conditions.${i}.operator`)}
              />
              <TextInput
                className={cn(css.Input, css.ValueInput)}
                type={TextInputType.TEXT}
                register={register(`conditions.${i}.value`)}
              />
              <Dropdown
                options={endBracketOptions}
                className={cn(css.Input, css.BracketDropdown, css.End)}
                register={register(`conditions.${i}.end_bracket`)}
              />
              <Button isDisabled={i === 0} onClick={() => handleRemoveCriterionClick(i)}>
                {t('inventory.filter.delete_criterion_btn')}
              </Button>
            </div>
          );
        })}
      </div>

      <div className={cn(css.ButtonContainer, isBarCollapsed ? css.Hidden : '')}>
        <Button onClick={handleAddCriterionClick}>{t('inventory.filter.add_criterion_btn')}</Button>
        <Button type="submit" isDisabled={fields.length === 0}>
          {t('inventory.filter.search_btn')}
        </Button>
        {isSearchPerformed && <Button onClick={onReset}>{t('inventory.filter.reset_btn')}</Button>}
      </div>
      <button type="button" onClick={handleSearchCollapseClick} className={css.CollapseButton}>
        <FontAwesomeIcon icon={isBarCollapsed ? faAngleDown : faAngleUp} />
      </button>
    </form>
  );
};
