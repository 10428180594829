import { FC } from 'react';
import css from './general-table.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../../../types/api';
import { formatDate } from '../../../../../../../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';
interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const GeneralTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  return (
    <div className={css.Root}>
      <table className={css.GeneralTable}>
        <tbody>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.general_tab.table.heading.device_name')}
            </td>
            <td>{device?.device_information?.device_name || '---'}</td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.general_tab.table.heading.last_update')}
            </td>
            <td>
              {device && device.device_information
                ? new Date(device.updated_at) > new Date(device.device_information.updated_at)
                  ? formatDate(device.updated_at)
                  : formatDate(device.device_information.updated_at)
                : ''}
            </td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.general_tab.table.heading.created_at')}</td>
            <td>{device ? formatDate(device.created_at) : ''}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.general_tab.table.heading.local_ip')}</td>
            <td>{device?.device_information?.local_ip || '---'}</td>
          </tr>
          <tr>
            <td>
              {t('inventory.device_page.inventory_tab.general_tab.table.heading.external_ip')}
            </td>
            <td>{device?.agent_instance?.last_remote_address || '---'}</td>
          </tr>
          <tr>
            <td>{t('inventory.device_page.inventory_tab.general_tab.table.heading.username')}</td>
            <td>---</td>
          </tr>
        </tbody>
      </table>
      <DeviceExtensionAttributes
        device={device}
        inventorySection={ExtensionAttributeInventorySection.General}
      ></DeviceExtensionAttributes>
    </div>
  );
};
