import cn from 'classnames';
import { FC, FormEvent, useEffect, useState } from 'react';
import css from './policy-form.module.scss';
import { PolicyOptionsGeneral } from '../policy-options-general/policy-options-general.component';
import {
  generalFormSchema,
  PolicyOptionsGeneralForm
} from '../policy-options-general/policy-options-general.schema';
import {
  PolicyOptionsScripts,
  PolicyOptionsScriptsForm
} from '../policy-options-scripts/policy-options-scripts.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../../components/button/button.component';
import {
  faGear,
  faFileCode,
  faTriangleExclamation,
  faCubes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabItem, TabNavbar } from '../../../../components/tab/tab-navbar/tab-navbar.component';
import { PolicyRunList } from '../policy-run-list/policy-run-list.component';
import {
  PolicyOptionsPackagesForm,
  PolicyOptionsPackages
} from '../policy-options-packages/policy-options-packages.component';
import { useTranslation } from 'react-i18next';
import i18next from '../../../../../i18n/i18n';
import { ScopeForm, ScopeValues } from '../../../../components/scope-form/scope-form.component';
import { PolicyOptionsMaintenance } from '../policy-options-maintenance/policy-options-maintenance.component';
import {
  maintenanceFormSchema,
  PolicyOptionsMaintenanceForm
} from '../policy-options-maintenance/policy-options-maintenance.schema';
import { usePermission } from '../../../../contexts/permission.context';
import { Permission } from '../../../../../types/api';

type PolicyFormOptions = 'general' | 'scripts' | 'packages' | 'maintenance';
enum PolicyFormTabs {
  OPTIONS = 'Options',
  SCOPE = 'Scope',
  POLICY_RUNS = 'Policy runs'
}

const getPolicyTabTranslated = (tab: PolicyFormTabs) =>
  ({
    [PolicyFormTabs.OPTIONS]: i18next.t('policies.policy_form.tab_options'),
    [PolicyFormTabs.SCOPE]: i18next.t('policies.policy_form.tab_scope'),
    [PolicyFormTabs.POLICY_RUNS]: i18next.t('policies.policy_form.tab_policy_runs')
  }[tab]);

export interface PolicyFormValues {
  general: PolicyOptionsGeneralForm;
  scope: ScopeValues;
  scripts?: PolicyOptionsScriptsForm;
  packages?: PolicyOptionsPackagesForm;
  maintenance?: PolicyOptionsMaintenanceForm;
}

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: PolicyFormValues;
  policyId?: string;
  onCreate?: (values: PolicyFormValues) => void;
  onCreateCancel?: () => void;
  onDelete?: () => void;
  onEditStart?: () => void;
  onEditCancel?: () => void;
  onEditRequest?: (values: PolicyFormValues) => void;
}

export const PolicyForm: FC<IProps> = (props) => {
  const {
    values,
    policyId,
    onCreate,
    onCreateCancel,
    onDelete,
    onEditStart,
    onEditCancel,
    onEditRequest,
    mode = 'view'
  } = props;
  const generalForm = useForm<PolicyOptionsGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(generalFormSchema),
    defaultValues: values?.general || generalFormSchema.getDefault()
  });
  const maintenanceForm = useForm<PolicyOptionsMaintenanceForm>({
    mode: 'onChange',
    resolver: yupResolver(maintenanceFormSchema),
    defaultValues: values?.maintenance || maintenanceFormSchema.getDefault()
  });

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [selectedTab, setSelectedTab] = useState<string>(PolicyFormTabs.OPTIONS);
  const [selectedOption, setSelectedOption] = useState<PolicyFormOptions>('general');
  const [scopeValues, setScopeValues] = useState<ScopeValues>(
    values?.scope || { scoped: true, scopeEntriesInclude: [], scopeEntriesExclude: [] }
  );
  const [scriptsValues, setScriptsValues] = useState<PolicyOptionsScriptsForm | undefined>(
    values?.scripts
  );
  const [packagesValues, setPackagesValues] = useState<PolicyOptionsPackagesForm | undefined>(
    values?.packages
  );

  const handleScriptsOptionsChange = (input: PolicyOptionsScriptsForm) => setScriptsValues(input);
  const handlePackagesOptionsChange = (input: PolicyOptionsPackagesForm) => {
    setPackagesValues(input);
  };
  const handleScopeChange = (input: Partial<ScopeValues>) => {
    setScopeValues({ ...scopeValues, ...input });
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };
  const handleOptionTabChange = (optionTab: PolicyFormOptions) => () => {
    setSelectedOption(optionTab);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleEditStart = () => onEditStart?.();
  const handleEditCancel = () => onEditCancel?.();
  const handleEditRequest = async () => {
    await generalForm.trigger();
    const errorKeys = Object.keys(generalForm.formState.errors);
    if (errorKeys.length > 0) return;

    onEditRequest?.({
      general: generalForm.getValues(),
      scope: scopeValues,
      ...(scriptsValues && { scripts: scriptsValues }),
      ...(packagesValues && { packages: packagesValues }),
      maintenance: maintenanceForm.getValues()
    });
  };

  const handleDelete = () => onDelete?.();
  const handleCreateCancel = () => onCreateCancel?.();
  const handleCreate = async () => {
    await generalForm.trigger();
    const errorKeys = Object.keys(generalForm.formState.errors);
    if (errorKeys.length > 0) return;

    onCreate?.({
      general: generalForm.getValues(),
      scope: scopeValues,
      ...(scriptsValues && { scripts: scriptsValues }),
      ...(packagesValues && { packages: packagesValues }),
      maintenance: maintenanceForm.getValues()
    });
  };

  useEffect(() => {
    if (mode === 'view') {
      setSelectedOption('general');
    }
    generalForm.reset(values?.general);
    maintenanceForm.reset(values?.maintenance);
    setPackagesValues(values?.packages);
    setScriptsValues(values?.scripts);
    setScopeValues(
      values?.scope || { scoped: true, scopeEntriesInclude: [], scopeEntriesExclude: [] }
    );
    console.log('new mode or values');
  }, [mode, values]);

  const navbarTabs = [
    { key: PolicyFormTabs.OPTIONS, title: getPolicyTabTranslated(PolicyFormTabs.OPTIONS) },
    { key: PolicyFormTabs.SCOPE, title: getPolicyTabTranslated(PolicyFormTabs.SCOPE) },
    mode !== 'create' && {
      key: PolicyFormTabs.POLICY_RUNS,
      title: getPolicyTabTranslated(PolicyFormTabs.POLICY_RUNS)
    }
  ].filter(Boolean) as TabItem[];
  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditPolicies)}>
        <TabNavbar tabs={navbarTabs} activeTab={selectedTab} onChangeTab={handleTabChange} />
        {selectedTab === PolicyFormTabs.OPTIONS && (
          <div className={css.Options}>
            <ul className={css.OptionTabs}>
              <li
                onClick={handleOptionTabChange('general')}
                className={cn(css.OptionTabItem, selectedOption === 'general' && css.Active)}
              >
                <FontAwesomeIcon className={css.OptionTabItemIcon} size="sm" icon={faGear} />
                <span>{t('policies.policy_form.menu_general')}</span>
                {Object.keys(generalForm.formState.errors).length !== 0 && (
                  <FontAwesomeIcon
                    className={css.OptionTabItemError}
                    size="sm"
                    fill="red"
                    icon={faTriangleExclamation}
                  />
                )}
              </li>
              {(mode !== 'view' || (scriptsValues && scriptsValues.length > 0)) && (
                <li
                  onClick={handleOptionTabChange('scripts')}
                  className={cn(css.OptionTabItem, selectedOption === 'scripts' && css.Active)}
                >
                  <p className={css.OptionTabText}>
                    <FontAwesomeIcon
                      className={css.OptionTabItemIcon}
                      size="sm"
                      icon={faFileCode}
                    />
                    <span>{t('policies.policy_form.menu_scripts')}</span>
                  </p>
                  <p className={css.OptionTabSubtext}>
                    {t('policies.policy_form.menu_scripts_n_configured', {
                      n: scriptsValues?.length || 0
                    })}
                  </p>
                </li>
              )}
              {(mode !== 'view' || (packagesValues && packagesValues.items.length > 0)) && (
                <li
                  onClick={handleOptionTabChange('packages')}
                  className={cn(css.OptionTabItem, selectedOption === 'packages' && css.Active)}
                >
                  <p className={css.OptionTabText}>
                    <FontAwesomeIcon className={css.OptionTabItemIcon} size="sm" icon={faCubes} />
                    <span>{t('policies.policy_form.menu_packages')}</span>
                  </p>
                  <p className={css.OptionTabSubtext}>
                    {t('policies.policy_form.menu_packages_n_configured', {
                      n: packagesValues?.items?.length || 0
                    })}
                  </p>
                </li>
              )}
              <li
                onClick={handleOptionTabChange('maintenance')}
                className={cn(css.OptionTabItem, selectedOption === 'maintenance' && css.Active)}
              >
                <p className={css.OptionTabText}>
                  <FontAwesomeIcon className={css.OptionTabItemIcon} size="sm" icon={faCubes} />
                  <span>{t('policies.policy_form.menu_maintenance')}</span>
                </p>
              </li>
            </ul>
            <div className={css.OptionForm}>
              {selectedOption === 'general' && (
                <PolicyOptionsGeneral disabled={mode === 'view'} form={generalForm} />
              )}
              {selectedOption === 'scripts' && (
                <PolicyOptionsScripts
                  disabled={mode === 'view'}
                  values={scriptsValues}
                  onChange={handleScriptsOptionsChange}
                />
              )}
              {selectedOption === 'packages' && (
                <PolicyOptionsPackages
                  disabled={mode === 'view'}
                  values={packagesValues}
                  onChange={handlePackagesOptionsChange}
                />
              )}
              {selectedOption === 'maintenance' && (
                <PolicyOptionsMaintenance disabled={mode === 'view'} form={maintenanceForm} />
              )}
            </div>
          </div>
        )}
        {selectedTab === PolicyFormTabs.SCOPE && (
          <ScopeForm
            className={css.PolicyScope}
            values={scopeValues}
            disabled={mode === 'view'}
            onChange={handleScopeChange}
          />
        )}
        {selectedTab === PolicyFormTabs.POLICY_RUNS && (
          <PolicyRunList className={css.PolicyRuns} policyId={policyId} />
        )}
        <div className={css.PolicyActions}>
          {mode === 'create' && (
            <>
              <Button className={css.PolicyActionBtn} theme="danger" onClick={handleCreateCancel}>
                {t('policies.policy_form.button_cancel')}
              </Button>
              <Button theme="primary" type="submit" onClick={handleCreate}>
                {t('policies.policy_form.button_create_policy')}
              </Button>
            </>
          )}
          {mode === 'view' && (
            <>
              <Button className={css.PolicyActionBtn} theme="primary" onClick={handleEditStart}>
                {t('policies.policy_form.button_edit_policy')}
              </Button>
              <Button className={css.PolicyActionBtn} theme="danger" onClick={handleDelete}>
                {t('policies.policy_form.button_delete_policy')}
              </Button>
            </>
          )}
          {mode === 'edit' && (
            <>
              <Button className={css.PolicyActionBtn} theme="primary" onClick={handleEditRequest}>
                {t('policies.policy_form.button_edit_policy_confirm')}
              </Button>
              <Button className={css.PolicyActionBtn} theme="danger" onClick={handleEditCancel}>
                {t('policies.policy_form.button_cancel')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
    </form>
  );
};
