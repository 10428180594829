import { FC, FormEvent, useEffect, useState } from 'react';
import { TabItem, TabNavbar } from '../../../../components/tab/tab-navbar/tab-navbar.component';
import { GroupTab } from '../group-tab/group-tab.component';
import css from './smart-group-devices-form.module.scss';
import {
  smartGroupDevicesFormSchema,
  SmartGroupFormValues
} from './smart-group-devices-form.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button } from '../../../../components/button/button.component';
import { CriteriaTab } from '../criteria-tab/criteria-tab.component';
import { BlankPage } from '../../../../components/blank-page/blank-page.component';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/modal/modal.component';
import { usePermission } from '../../../../contexts/permission.context';
import { Permission } from '../../../../../types/api';
import { DevicesListTab } from '../devices-list-tab/devices-list-tab.component';
import { getSmartGroupsDevicesCSV } from '../../../../../api/groups';

export enum SmartGroupDevicesPageTabs {
  DEVICES_LIST = 'Devices List',
  GROUP_INFORMATION = 'Computer Group',
  CRITERIA = 'Criteria',
  REPORTS = 'Reports'
}

interface IProps {
  mode: 'view' | 'create' | 'edit' | 'update';
  values?: SmartGroupFormValues;
  onCreate?: (values: SmartGroupFormValues) => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onSave?: (values: SmartGroupFormValues) => void;
  onCancel?: () => void;
}

export const SmartGroupDevicesForm: FC<IProps> = (props: IProps) => {
  const { values, onCreate, onDelete, onEdit, onSave, onCancel, mode = 'view' } = props;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(SmartGroupDevicesPageTabs.DEVICES_LIST);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const smartGroupForm = useForm<SmartGroupFormValues>({
    mode: 'onChange',
    resolver: yupResolver(smartGroupDevicesFormSchema),
    defaultValues: values || smartGroupDevicesFormSchema.getDefault()
  });

  useEffect(() => {
    if (values) {
      smartGroupForm.setValue('group.id', values.group.id);
      smartGroupForm.setValue('group.display_name', values.group.display_name);
    }
  }, [values]);

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    await smartGroupForm.trigger();
    const errorKeys = Object.keys(smartGroupForm.formState.errors);
    if (errorKeys.length > 0) return;
    onCreate?.(smartGroupForm.getValues());
  };

  const handleClickEdit = () => onEdit?.();

  const handleClickCancel = async () => {
    await smartGroupForm.reset();
    onCancel?.();
  };

  const handleClickSave = async () => {
    const isValid = await smartGroupForm.trigger();
    if (!isValid) return;

    onSave?.(smartGroupForm.getValues());
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const handleClickExportCSV = async () => {
    const data = await getSmartGroupsDevicesCSV({
      group_id: smartGroupForm.getValues().group.id
    });
    const blob = new Blob([data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'export.csv');
    link.click();
  };

  const tabs: TabItem[] = [
    {
      key: SmartGroupDevicesPageTabs.DEVICES_LIST,
      title: t('smart_groups.page.devices_list_group_tab.name')
    },
    {
      key: SmartGroupDevicesPageTabs.GROUP_INFORMATION,
      title: t('smart_groups.page.group_information_tab.name')
    },
    { key: SmartGroupDevicesPageTabs.CRITERIA, title: t('smart_groups.page.criteria_tab.name') },
    { key: SmartGroupDevicesPageTabs.REPORTS, title: t('smart_groups.page.reports_tab.name') }
  ];

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditGroups)}>
        <TabNavbar tabs={tabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
        {activeTab === SmartGroupDevicesPageTabs.DEVICES_LIST && (
          <DevicesListTab form={smartGroupForm} />
        )}
        {activeTab === SmartGroupDevicesPageTabs.GROUP_INFORMATION && (
          <GroupTab disabled={mode === 'view'} form={smartGroupForm} />
        )}
        {activeTab === SmartGroupDevicesPageTabs.CRITERIA && (
          <CriteriaTab key={mode} disabled={mode === 'view'} form={smartGroupForm} />
        )}
        {activeTab === SmartGroupDevicesPageTabs.REPORTS && (
          <BlankPage text={t('smart_groups.page.reports_tab.no_content')} />
        )}
        <Modal
          title={t('smart_groups.page.modal_delete.title')}
          submitButtonName={t('smart_groups.page.modal_delete.delete_btn')}
          isOpen={isDeleteModalOpen}
          onRequestClose={handleDeleteModalClose}
          onSubmit={handleDeleteModalSubmit}
        >
          <p>
            {t('static_groups.page.modal_delete.message', { title: values?.group.display_name })}
          </p>
        </Modal>
        <div className={css.SmartGroupActions}>
          <Button
            className={css.SmartGroupActionBtn}
            theme="primary"
            type="submit"
            onClick={handleClickExportCSV}
          >
            {t('smart_groups.page.export_csv_btn')}
          </Button>
          {mode === 'create' && (
            <Button
              className={css.SmartGroupActionBtn}
              theme="primary"
              type="submit"
              onClick={handleCreate}
            >
              {t('smart_groups.page.create_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <>
              <Button className={css.SmartGroupActionBtn} theme="primary" onClick={handleClickEdit}>
                {t('smart_groups.page.edit_btn')}
              </Button>
              <Button
                className={css.SmartGroupActionBtn}
                theme="danger"
                onClick={handleDeleteModalOpen}
              >
                {t('smart_groups.page.delete_btn')}
              </Button>
            </>
          )}
          {mode === 'edit' && (
            <>
              <Button className={css.SmartGroupActionBtn} theme="primary" onClick={handleClickSave}>
                {t('smart_groups.page.save_btn')}
              </Button>
              <Button
                className={css.SmartGroupActionBtn}
                theme="danger"
                onClick={handleClickCancel}
              >
                {t('smart_groups.page.cancel_btn')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
    </form>
  );
};
