import { FC } from 'react';
import cn from 'classnames';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import css from './app-store-application-list.module.scss';
import { SearchDeviceApplication } from '../../../../../../types/api';
import { Button } from '../../../../../components/button/button.component';

interface IProps {
  apps: SearchDeviceApplication[];
  deviceType: string;
  setDeviceType: (text: 'iPhone' | 'iPad') => void;
  setStep: (value: number | null) => void;
  handleAddApp: (app: SearchDeviceApplication) => void;
}

export const AppStoreApplicationList: FC<IProps> = (props: IProps) => {
  const { apps, deviceType, setDeviceType, setStep, handleAddApp } = props;
  const { t } = useTranslation();

  const handleRowAdd = async (row: SearchDeviceApplication) => {
    await handleAddApp(row);
    setStep(null);
  };

  const deviceAppsColumns: TableColumn<SearchDeviceApplication>[] = [
    {
      name: '',
      selector: (row: SearchDeviceApplication) => row.artworkUrl60,
      maxWidth: '100px',
      cell: (row) => {
        return (
          <img src={row.artworkUrl60} alt="logo" className={cn(css.Logo, 'brand-image logo-xl')} />
        );
      }
    },
    {
      name: t('device_apps.installation.search_step.app_list.column_name'),
      selector: (row: SearchDeviceApplication) => row.trackName,
      minWidth: '160px',
      maxWidth: '1000px',
      style: { padding: '2px' },
      wrap: true
    },
    {
      name: t('device_apps.installation.search_step.app_list.column_price'),
      selector: (row: SearchDeviceApplication) => row.formattedPrice || row.price,
      maxWidth: '120px'
    },
    {
      name: t('device_apps.installation.search_step.app_list.column_company'),
      selector: (row: SearchDeviceApplication) => row.sellerName,
      minWidth: '160px',
      maxWidth: '1000px',
      style: { padding: '2px' },
      wrap: true
    },
    {
      name: '',
      selector: (row: SearchDeviceApplication) => row.trackViewUrl,
      maxWidth: '140px',
      right: true,
      cell: (row) => {
        return (
          <Button className={css.Button} onClick={() => window.open(row.trackViewUrl)}>
            {t('device_apps.installation.search_step.app_list.column_track_view_url')}
          </Button>
        );
      }
    },
    {
      name: '',
      maxWidth: '140px',
      right: true,
      style: { padding: '0 30px' },
      cell: (row) => {
        return (
          <Button className={css.Button} onClick={() => handleRowAdd(row)}>
            {t('device_apps.installation.search_step.app_list.column_add')}
          </Button>
        );
      }
    }
  ];

  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      <div className={css.OperationTypeButtonGroup}>
        <Button
          className={cn(
            css.OperationTypeButton,
            deviceType === 'iPhone' && css.OperationTypeButtonActive
          )}
          onClick={() => setDeviceType('iPhone')}
        >
          {t('device_apps.installation.search_step.app_list.device_type.iphone')}
        </Button>
        <Button
          className={cn(
            css.OperationTypeButton,
            deviceType === 'iPad' && css.OperationTypeButtonActive
          )}
          onClick={() => setDeviceType('iPad')}
        >
          {t('device_apps.installation.search_step.app_list.device_type.ipad')}
        </Button>
      </div>
      {apps && (
        <DataTable
          noDataComponent={t('common.no_records_in_table')}
          columns={deviceAppsColumns}
          data={apps}
          sortServer
        />
      )}
    </div>
  );
};
