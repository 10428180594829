import { ChangeEventHandler, FC, FormEvent, useState } from 'react';
import css from './device-users-upload-modal.module.scss';
import { Modal } from '../../../../components/modal/modal.component';
import { useTranslation } from 'react-i18next';
import { uploadDeviceUsers } from '../../../../../api/device-users';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { FileInput } from '../../../../components/file-input/file-input.component';
import useRequest from '../../../../../hooks/useRequest';
import { downloadFile } from '../../../../../utils/file.utils';
import { getLocalizedErrorString } from '../../../../../utils/localize-error';
interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onCancel: () => void;
}

export const DeviceUsersUploadModal: FC<IProps> = (props: IProps) => {
  const { isOpen, onRequestClose } = props;
  const [isProfileUploaded, setIsProfileUploaded] = useState<boolean>(false);
  const [isMergeConflictPending, setIsMergeConflictPending] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<File | undefined>();
  const { t } = useTranslation();
  const uploadRequest = useRequest();

  const handleUploadCSV: ChangeEventHandler<HTMLInputElement> = async (event) => {
    setCsvData(event.target.files?.[0]);
  };

  const handleRequestClose = () => {
    setIsMergeConflictPending(false);
    onRequestClose();
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };
  const handleUpload = async () => {
    if (!csvData || uploadRequest.loading) return;
    if (isMergeConflictPending) {
      try {
        await uploadRequest.send(uploadDeviceUsers({ spreadsheet: csvData, merge: true }));
        setIsMergeConflictPending(false);
        onRequestClose();
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        console.log(localizedErrorString);
        toast.error(localizedErrorString, { autoClose: 5000 });
      }
    } else {
      try {
        await uploadRequest.send(uploadDeviceUsers({ spreadsheet: csvData }));
        setIsMergeConflictPending(false);
        onRequestClose();
      } catch (error) {
        if ((error as AxiosResponse).data.statusCode === 409) {
          setIsMergeConflictPending(true);
        } else {
          const localizedErrorString = getLocalizedErrorString(error as Error);
          console.log(localizedErrorString);
          toast.error(localizedErrorString, { autoClose: 5000 });
        }
      }
    }
  };
  const handleUploadFormChanged = () => {
    setIsProfileUploaded(true);
  };
  const handleDownloadExample = () => {
    const content = [
      'username',
      'username@mail.com',
      'Имя Фамилия',
      '88001234455',
      'Должность'
    ].join(',');
    downloadFile(`${t('device_users.import.import_example_filename')}.csv`, [content]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      onSubmit={handleUpload}
      isLoading={uploadRequest.loading}
      hideSubmit={!isProfileUploaded}
      title={t('device_users.import.import_user_list')}
    >
      <form className={css.Form} onSubmit={handleFormSubmit} onChange={handleUploadFormChanged}>
        <FileInput
          className={css.FileInput}
          label={t('device_users.import.import_user_file_input_label')}
          accept=".csv"
          onChange={handleUploadCSV}
        />
        {!isMergeConflictPending && !uploadRequest.loading && (
          <>
            <p className={css.InfoText}>{t('device_users.import.import_user_help_text1')}</p>
            <p className={css.InfoText}>{t('device_users.import.import_user_help_text2')}</p>
            <a className={css.InfoLink} onClick={handleDownloadExample}>
              {t('device_users.import.import_user_help_download')}
            </a>
          </>
        )}
        {isMergeConflictPending && <p>{t('device_users.import.description_of_merger')}</p>}
      </form>
    </Modal>
  );
};
