import { FC } from 'react';
import css from './profiles-table.module.scss';
import { DeviceFullDto } from '../../../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpened: boolean;
  device?: DeviceFullDto;
}

export const ProfilesTable: FC<IProps> = (props: IProps) => {
  const { isOpened, device } = props;
  const { t } = useTranslation();

  if (!isOpened) {
    return <div />;
  }

  return (
    <div className={css.Root}>
      <table className={css.ProfilesTable}>
        <thead>
          <tr>
            <th>{t('inventory.device_page.inventory_tab.profiles_tab.table.heading.name')}</th>
            <th>
              {t('inventory.device_page.inventory_tab.profiles_tab.table.heading.identifier')}
            </th>
            <th>{t('inventory.device_page.inventory_tab.profiles_tab.table.heading.uuid')}</th>
          </tr>
        </thead>
        <tbody>
          {device?.profiles?.map((i) => (
            <tr key={i.id}>
              <td title={i.name ? i.name : ''}>{i.name}</td>
              <td title={i.identifier ? i.identifier : ''}>{i.identifier}</td>
              <td title={i.uuid ? i.uuid : ''}>{i.uuid}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
