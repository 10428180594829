import { FC, useEffect } from 'react';
import cn from 'classnames';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import css from './device-user-list.module.scss';

import { Button } from '../../../../../../../../../../components/button/button.component';
import {
  DeviceUserSimpleDto,
  DeviceUserSortField,
  PaginationSortOrder
} from '../../../../../../../../../../../types/api';
import { PaginationRowsPerPageOptionsDeviceUsers } from '../../../../../../../../../../../const/pagination.const';

interface IProps {
  handleLinkUser: (row: DeviceUserSimpleDto) => () => Promise<void>;
  deviceUsers: DeviceUserSimpleDto[];
  deviceUsersCount: number;
  setCurrentPage: (value: number) => void;
  setRowsPerPage: (value: number) => void;
  currentPage: number;
  rowsPerPage: number;
  onSearch: () => void;
  setSortOrder: (value: PaginationSortOrder) => void;
  setSortField: (value: DeviceUserSortField) => void;
}

export const DeviceUserList: FC<IProps> = (props: IProps) => {
  const {
    deviceUsers,
    deviceUsersCount,
    handleLinkUser,
    setCurrentPage,
    currentPage,
    setRowsPerPage,
    rowsPerPage,
    onSearch,
    setSortOrder,
    setSortField
  } = props;
  const { t } = useTranslation();

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<DeviceUserSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as DeviceUserSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(1);
    }
    setRowsPerPage(value);
  };

  const deviceUsersColumns: TableColumn<DeviceUserSimpleDto>[] = [
    {
      name: t('inventory.device_page.inventory_tab.device_user_tab.form.column_username'),
      selector: (row: DeviceUserSimpleDto) => row.username,
      sortable: true,
      sortField: DeviceUserSortField.Username,
      maxWidth: '400px'
    },
    {
      name: t('inventory.device_page.inventory_tab.device_user_tab.form.column_employee_name'),
      selector: (row: DeviceUserSimpleDto) => row?.employee_name || '',
      sortable: true,
      sortField: DeviceUserSortField.EmployeeName,
      maxWidth: '400px'
    },
    {
      name: t('inventory.device_page.inventory_tab.device_user_tab.form.column_email'),
      selector: (row: DeviceUserSimpleDto) => row.email || '',
      sortable: true,
      sortField: DeviceUserSortField.Email,
      maxWidth: '400px'
    },
    {
      name: '',
      sortable: true,
      maxWidth: '400px',
      cell: (row: DeviceUserSimpleDto) => (
        <Button type="button" theme="success" onClick={handleLinkUser(row)}>
          {t('inventory.device_page.inventory_tab.device_user_tab.buttons.link')}
        </Button>
      )
    }
  ];

  useEffect(() => {
    onSearch?.();
  }, [currentPage, rowsPerPage]);

  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      {deviceUsers && (
        <DataTable
          noDataComponent={t('common.no_records_in_table')}
          columns={deviceUsersColumns}
          data={deviceUsers}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={deviceUsersCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptionsDeviceUsers}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
          sortServer
          onSort={handleSort}
        />
      )}
    </div>
  );
};
