import { FC, useEffect, useState } from 'react';
import css from './settings-package-list.module.scss';
import { Link } from 'react-router-dom';
import { PackageSimpleDto, Permission } from '../../../../types/api';
import { getPackages } from '../../../../api/packages';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { Button } from '../../../components/button/button.component';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../../contexts/permission.context';

export const SettingsPackageList: FC = () => {
  const [packages, setPackages] = useState<PackageSimpleDto[]>();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  useEffect(() => {
    const fetch = async () => {
      const result = await getPackages();
      setPackages(result.packages);
    };
    void fetch();
  }, []);

  const columns: TableColumn<PackageSimpleDto>[] = [
    {
      name: t('settings.tiles.packages.page.table.heading.title'),
      cell: (row) => <Link to={row.id}>{row.display_name}</Link>
    }
  ];
  return (
    <div>
      <Breadcrumbs />
      <div className={css.SettingsHeader}>
        <h5 className={css.SettingsTitle}>{t('settings.tiles.packages.page.title')}</h5>
        <Link to="new" className={css.LinkNew}>
          <Button isDisabled={!isAllowedTo(Permission.EditPackages)} theme="primary">
            {t('settings.tiles.packages.page.new_btn')}
          </Button>
        </Link>
      </div>

      {packages && (
        <DataTable
          columns={columns}
          data={packages}
          noDataComponent={t('common.no_records_in_table')}
        />
      )}
    </div>
  );
};
